var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/api/next/searchCoas.ts
import axios from "axios";
var strapi = axios.create({
  baseURL: process.env.TRANSPARENCY_STRAPI_BASEURL
});
var searchCoa = async (req, res) => {
  const batchNumber = req.query.batch;
  if (!batchNumber) {
    res.status(400).json({
      error: "Bad Request: batch is a required query parameter!"
    });
  }
  let batches = [];
  const specificBatch = await strapi.get(
    `/batches?batchNumber=${batchNumber ? batchNumber.toString() : ""}`
  );
  if (specificBatch.data && specificBatch.data.length === 1) {
    batches = specificBatch.data;
  } else {
    const batchSearch = await strapi.get(
      `/batches?batchNumber_contains=${batchNumber ? batchNumber.toString() : ""}`
    );
    if (batchSearch.data && batchSearch.data.length) {
      batches = batchSearch.data;
    } else {
      return res.status(200).json([]);
    }
  }
  const simplifiedBatch = batches.map((original) => {
    const url = original.pdf.url.split(".net");
    const updatedUrl = `${process.env.COAS_URL}${url[1]}`;
    return {
      id: original.id,
      state: original.state,
      stateName: original.state ? original.state.name : "",
      pdf: updatedUrl,
      brand: original.brand,
      batchNumber: original.batchNumber,
      category: original.category,
      pdfUrl: updatedUrl
    };
  });
  res.status(200).json(simplifiedBatch);
};

// src/api/next/submitForm.ts
import axios7 from "axios";
import moment2 from "moment";

// src/api/forms/zendesk.ts
import axios2 from "axios";

// src/util/slugify.ts
var slugify = (str) => str.trim().toLowerCase().replace("-", " ").replace("_", "-").replace(/  +/g, " ").replace(/ /g, "-");
var alphanumericSlugify = (slug) => slugify(slug.replace(/[^a-zA-Z0-9 ]/g, ""));
var capitalize = (s) => {
  if (!s)
    return "";
  const w = s.split(" ");
  return w.map((word) => {
    if (word.length) {
      word = word.toLowerCase();
      return word[0].toUpperCase() + word.substring(1);
    }
  }).join(" ");
};
var deslugify = (slug) => slug.replace("-", "_").toUpperCase();
var dutchieFriendlyName = (str) => {
  const tmp = str.replace("-", " ").replace("_", " ");
  return capitalize(tmp);
};
var lowerScore = (str) => str.toLowerCase().replace(" ", "_");
var getSlug = (slug, stateDispensarySlug) => {
  let s = "";
  if (typeof slug !== "string") {
    s = slug.slug;
  } else {
    s = slug;
  }
  if (s.startsWith("http")) {
    return s;
  }
  if (stateDispensarySlug) {
    s = s.replace("%s", stateDispensarySlug);
  }
  if (!s.startsWith("/")) {
    s = `/${slug}`;
  }
  return s;
};

// src/api/forms/zendesk.ts
var ZendeskService = class {
  constructor() {
    const base64Auth = process.env.ZENDESK_AUTHORIZATION ? Buffer.from(process.env.ZENDESK_AUTHORIZATION).toString("base64") : "";
    this.zendesk = axios2.create({
      baseURL: process.env.ZENDESK_URL,
      headers: {
        Authorization: `Basic ${base64Auth}`
      },
      validateStatus: () => true
    });
  }
  async postTicket(formData, formConfig) {
    const jsonData = {
      ticket: {
        requester: formData.email,
        subject: formConfig.contact_us.subject_line,
        description: formData.topicDescription,
        custom_fields: [
          {
            id: 12716824914715,
            value: formData.firstName
          },
          {
            id: 12716878498203,
            value: formData.lastName
          },
          {
            id: 12716885942939,
            value: formData.phone ? alphanumericSlugify(formData.phone) : ""
          },
          {
            id: 12614099396635,
            value: formData.state ? lowerScore(formData.state) : ""
          },
          {
            id: 13006294901787,
            value: formData.dispensaryPreference
          },
          {
            id: 12303428813467,
            value: formData.topic ? lowerScore(formData.topic) : ""
          },
          {
            id: 12303473665819,
            value: formData.optIn ? "on" : "off"
          }
        ]
      }
    };
    const result = await this.zendesk.post("/api/v2/tickets.json", jsonData);
    return {
      success: result.status === 200 || result.status === 201,
      message: result.status === 200 || result.status === 201 ? "" : "Failed to submit to Zendesk!"
    };
  }
};

// src/api/contentstack/contentstack.service.ts
import axios3 from "axios";
import request, { gql as gql4 } from "graphql-request";

// src/util/compressGraphql.ts
var compress = (query) => {
  return query.replace(/#.*\n/g, "").replace(/[\s|,]*\n+[\s|,]*/g, " ").replace(/:\s/g, ":").replace(/,\s/g, ",").replace(/\)\s\{/g, "){").replace(/\}\s/g, "}").replace(/\{\s/g, "{").replace(/\s\}/g, "}").replace(/\s\{/g, "{").replace(/\)\s/g, ")").replace(/\(\s/g, "(").replace(/\s\)/g, ")").replace(/\s\(/g, "(").replace(/=\s/g, "=").replace(/\s=/g, "=").replace(/@\s/g, "@").replace(/\s@/g, "@").replace(/\s\$/g, "$").replace(/\s\./g, ".").trim();
};

// src/util/decisionTreeMap.ts
function updateMatchingQuestion(obj, count, allQuestions) {
  for (const [key, value] of Object.entries(obj)) {
    if (count >= 10 || key !== "answers") {
      return obj;
    } else if (key === "answers" && value && typeof obj === "object" && obj.answers[0]) {
      for (let i = 0; i < obj.answers.length; i++) {
        if (obj.answers[i].node.questionConnection.edges[0].node.system) {
          const matchingQuestion = allQuestions ? allQuestions.find(
            (question) => question.system.uid === obj.answers[i].node.questionConnection.edges[0].node.system.uid
          ) : null;
          obj.answers[i] = {
            node: obj.answers[i].node,
            reference: {
              answers: matchingQuestion ? matchingQuestion.answersConnection.edges : null,
              question: matchingQuestion ? matchingQuestion.question : ""
            },
            text: obj.answers[i].node.text
          };
          updateMatchingQuestion(
            obj.answers[i].reference,
            count += 1,
            allQuestions
          );
        } else {
          return obj;
        }
      }
    }
  }
}
var calculateDecisionTree = async (decisionTree) => {
  const contentstack5 = new ContentStackService();
  const decisionTreeReq = await contentstack5.getDecisionTreeData(
    decisionTree.Decision_Tree,
    false
  );
  if (decisionTreeReq) {
    return {
      __typename: "LandingPagePageComponentsDecisionTree",
      Decision_Tree: {
        questions: decisionTreeReq,
        startingHeader: decisionTree.Decision_Tree.starting_headerConnection,
        mode: decisionTree.Decision_Tree.mode,
        ctaVariant: decisionTree.Decision_Tree.cta_variant
      }
    };
  } else {
    console.warn("Error: Failed to get decision tree data");
    return null;
  }
};

// src/model/query/landingPageQuery.ts
import { gql } from "graphql-request";

// src/model/query/stateComponents.ts
var stateComponents = {
  ImageBlock: `
    anchor
    contain
    full_width
    imageConnection {
      ...imageFragment
    }
    cta {
      aria_label
      url
    }
  `,
  Promobanner: `
    anchor
    auto_slide_in_seconds
    auto_slide_on_desktop
    auto_slide_on_mobile
    heading
    largebanner {
      ctabutton {
        ...ctabuttonFragment
      }
      heading
      imageConnection {
        ...imageFragment
      }
      layout
      text
      theme
      background_hex_color                  
    }
  `,
  RedirectSearch: `
    anchor
    background_imageConnection {
      ...imageFragment
    }
    base_url
    button_text
    heading
    input_placeholder
  `,
  TextBlock: `
    align
    anchor
    content
    ctabutton {
      ...ctabuttonFragment
    }
    header
  `,
  Textimageblock: `
    title
    heading
    align
    ctabutton {
      ...ctabuttonFragment
    }
    imageConnection {
      ...imageFragment
    }
    layout
    text
    anchor
  `,
  VideoBlock: `
    anchor
    type {
      ... on VideoBlockTypeUrl {
        __typename
        url {
          url
        }
      }
      ... on VideoBlockTypeUpload {
        __typename
        upload {
          fileConnection {
            edges {
              node {
                url
              }
            }
          }
        }
      }
    }
  `
};

// src/model/query/landingPageQuery.ts
var ctaFragment = gql`
  fragment ctabuttonFragment on CtaButton {
    text
    url
    variant
    background_hex_color
    border_hex_color
    text_hex_color
  }
`;
var imageFragment = gql`
  fragment imageFragment on SysAssetConnection {
    edges {
      node {
        description
        dimension {
          height
          width
        }
        url
      }
    }
  }
`;
var AllLandingPageSlugsQuery = gql`
  ${ctaFragment}
  ${imageFragment}
  query AllLandingPages($isHomePage: Boolean!) {
    all_landing_page(
      where: { ishomepage: $isHomePage, url_ne: "/404" }
      limit: 15
    ) {
      items {
        url
        title
      }
    }
  }
`;
var LandingPageTypeNameQuery = gql`
  query LandingPageComponentsTypename($url: String!) {
    all_landing_page(where: { url: $url }, limit: 15) {
      items {
        page_components {
          ... on LandingPagePageComponentsDecisionTree {
            __typename
          }
          ... on LandingPagePageComponentsVideoBlock {
            __typename
          }
          ... on LandingPagePageComponentsHeroStacked {
            __typename
          }
          ... on LandingPagePageComponentsHeroStackedCarousel {
            __typename
          }
          ... on LandingPagePageComponentsHerobanner {
            __typename
          }
          ... on LandingPagePageComponentsImageblock {
            __typename
          }
          ... on LandingPagePageComponentsImagedivider {
            __typename
          }
          ... on LandingPagePageComponentsProductcard {
            __typename
          }
          ... on LandingPagePageComponentsProductgrid {
            __typename
          }
          ... on LandingPagePageComponentsPromobanner {
            __typename
          }
          ... on LandingPagePageComponentsSmallpromobanner {
            __typename
          }
          ... on LandingPagePageComponentsStoreList {
            __typename
          }
          ... on LandingPagePageComponentsTextblock {
            __typename
          }
          ... on LandingPagePageComponentsTextimageblock {
            __typename
          }
          ... on LandingPagePageComponentsArticleGrid {
            __typename
          }
          ... on LandingPagePageComponentsFaqBlock {
            __typename
          }
          ... on LandingPagePageComponentsCustomComponent {
            __typename
          }
          ... on LandingPagePageComponentsEmbeddableForm {
            __typename
          }
          ... on LandingPagePageComponentsQuestionBlock {
            __typename
          }
          ... on LandingPagePageComponentsProductDetailsGrid {
            __typename
          }
          ... on LandingPagePageComponentsImageTransitionGrid {
            __typename
          }
          ... on LandingPagePageComponentsTritextimageblock {
            __typename
          }
          ... on LandingPagePageComponentsImageGrid {
            __typename
          }
          ... on LandingPagePageComponentsRedirectSearch {
            __typename
          }
          ... on LandingPagePageComponentsIframe {
            __typename
          }
          ... on LandingPagePageComponentsStateSwap {
            __typename
          }
          ... on LandingPagePageComponentsProductListing {
            __typename
          }
          ... on LandingPagePageComponentsCtaDivider {
            __typename
          }
          ... on LandingPagePageComponentsInstructionsBlock {
            __typename
          }
        }
      }
    }
  }
`;
var heroStackedBannerFragment = gql`
... on HeroStackedBanner {
  anchor
  cta_buttons {
    ...ctabuttonFragment
  }
  heading
  hide_logo
  imageConnection {
    ...imageFragment
  }
  mobile_assetConnection {
    ...imageFragment
  }
  mode
  subtitle
  title
}
`;
var buildDynamicLandingPageQuery = (pageComponents) => {
  let pageComponentsFragment = ``;
  const typeNames = pageComponents.map(
    (pc) => pc.__typename
  );
  const filtered = pageComponents.filter(
    ({ __typename }, index) => !typeNames.includes(__typename, index + 1)
  );
  for (let i = 0; i < filtered.length; i++) {
    if (PageComponentFragments[filtered[i].__typename]) {
      pageComponentsFragment += PageComponentFragments[filtered[i].__typename];
    }
  }
  return gql`
    ${ctaFragment}
    ${imageFragment}
    query DynamicLandingPage($url: String!) {
      all_landing_page(where: { url: $url }, limit: 15) {
        items {
          url
          title
          websites
          breadcrumbs
          seo {
            canonical_url
            imageConnection {
              ...imageFragment
            }
            no_follow
            no_index
            page_description
            page_title
          }
          page_components {
            ... on LandingPagePageComponentsHeroStacked {
              __typename
              hero_stacked {
                heroConnection {
                  edges {
                    node {
                      ${heroStackedBannerFragment}
                    }
                  }
                }
              }
            }
            ${pageComponentsFragment}
          }
        }
      }
    }
  `;
};
var PageComponentFragments = {
  LandingPagePageComponentsImagedivider: gql`
    ... on LandingPagePageComponentsImagedivider {
      __typename
      imagedivider {
        imagedividerConnection {
          edges {
            node {
              ... on ImageDivider {
                anchor
                text
                cta_button {
                  ...ctabuttonFragment
                }
                imageConnection {
                  ...imageFragment
                }
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsDecisionTree: gql`
    ... on LandingPagePageComponentsDecisionTree {
      __typename
      Decision_Tree {
        cta_variant
        mode
        decision_treeConnection {
          edges {
            node {
              ... on DecisionTreeQuestions {
                question
                system {
                  uid
                }
              }
            }
          }
        }
        tag
        starting_headerConnection {
          edges {
            node {
              ... on Textblock {
                align
                content
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsVideoBlock: gql`
    ... on LandingPagePageComponentsVideoBlock {
    __typename
      video_block {
        video_blockConnection {
          edges {
            node {
              ... on VideoBlock {
                ${stateComponents.VideoBlock}
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsIframe: gql`
    ... on LandingPagePageComponentsIframe {
      __typename
      Iframe {
        type
        url
      }
    }
  `,
  LandingPagePageComponentsHeroStackedCarousel: gql`
  ... on LandingPagePageComponentsHeroStackedCarousel {
    __typename
    hero_stacked_carousel {
      bannersConnection {
        edges {
          node {
            ${heroStackedBannerFragment}
          }
        }
      }
    }
    }
  `,
  LandingPagePageComponentsRedirectSearch: gql`
    ... on LandingPagePageComponentsRedirectSearch {
      __typename
      redirect_search {
        redirectsearchConnection {
          edges {
            node {
              ... on RedirectSearch {
                ${stateComponents.RedirectSearch}
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsHerobanner: gql`
    ... on LandingPagePageComponentsHerobanner {
      __typename
      herobanner {
        herobannerConnection {
          edges {
            node {
              ... on HeroBanner {
                anchor
                heading
                heading2
                mode
                hide_opacity
                cta_button_1 {
                  ...ctabuttonFragment
                }
                cta_button_2 {
                  ...ctabuttonFragment
                }
                hide_svg
                subtitle
                cta_variant
                imageConnection {
                  ...imageFragment
                }
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsImageblock: gql`
    ... on LandingPagePageComponentsImageblock {
      __typename
      imageblock {
        image_blockConnection {
          edges {
            node {
              ... on ImageBlock {
                ${stateComponents.ImageBlock}
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsProductcard: gql`
    ... on LandingPagePageComponentsProductcard {
      __typename
      productcard {
        productcardConnection {
          edges {
            node {
              ... on Productcard {
                name
                variant
                description
                features {
                  feature_header
                  feature_list
                }
                imageConnection {
                  ...imageFragment
                }
                anchor
                cta_button {
                  ...ctabuttonFragment
                }
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsProductgrid: gql`
    ... on LandingPagePageComponentsProductgrid {
      __typename
      productgrid {
        productgridConnection {
          edges {
            node {
              ... on Productgrid {
                header
                subtitle
                ctabutton {
                  ...ctabuttonFragment
                }
                products {
                  title
                  slug
                  thumbnailConnection {
                    ...imageFragment
                  }
                }
                anchor
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsPromobanner: gql`
    ... on LandingPagePageComponentsPromobanner {
      __typename
      promobanner {
        promobannerConnection {
          edges {
            node {
              ... on Promobanner {
                ${stateComponents.Promobanner}
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsSmallpromobanner: gql`
    ... on LandingPagePageComponentsSmallpromobanner {
      __typename
      smallpromobanner {
        smallpromobannerConnection {
          edges {
            node {
              ... on Smallpromobanner {
                layout
                auto_slide_in_seconds
                auto_slide_on_desktop
                auto_slide_on_mobile
                smallbanner {
                  ctabutton {
                    ...ctabuttonFragment
                  }
                  imageConnection {
                    ...imageFragment
                  }
                  heading
                  layout
                  subtitle
                  text
                }
                anchor
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsStoreList: gql`
    ... on LandingPagePageComponentsStoreList {
      __typename
      store_list {
        store_listConnection {
          edges {
            node {
              ... on StoreList {
                anchor
                config {
                  display_accordion
                  display_distance_to
                }
                labels {
                  coming_soon_link_text
                  closed_text
                  closes_at_text
                  open_text
                  opens_at_text
                  store_link_text
                }
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsTextblock: gql`
    ... on LandingPagePageComponentsTextblock {
      __typename
      textblock {
        textblockConnection {
          edges {
            node {
              ... on Textblock {
                ${stateComponents.TextBlock}
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsTextimageblock: gql`
    ... on LandingPagePageComponentsTextimageblock {
      __typename
      textimageblock {
        textimageblockConnection {
          edges {
            node {
              ... on Textimageblock {
                ${stateComponents.Textimageblock}
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsFaqBlock: gql`
    ... on LandingPagePageComponentsFaqBlock {
      __typename
      faq_block {
        faq_blockConnection {
          edges {
            node {
              ... on FaqBlock {
                anchor
                heading
                faqs {
                  answer
                  question
                }
                text
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsCustomComponent: gql`
    ... on LandingPagePageComponentsCustomComponent {
      __typename
      Custom_Component {
        type
      }
    }
  `,
  LandingPagePageComponentsEmbeddableForm: gql`
    ... on LandingPagePageComponentsEmbeddableForm {
      __typename
      Embeddable_Form {
        formConnection {
          edges {
            node {
              ... on EmbeddableForm {
                system {
                  uid
                }
                type
                dropdowntype
                confirmation {
                  title
                  content
                  imageConnection {
                    ...imageFragment
                  }
                }
                dispensariesConnection {
                  edges {
                    node {
                      ... on DispensaryConfig {
                        friendly_name
                        slug
                      }
                    }
                  }
                }
                form_title
                imageConnection {
                  ...imageFragment
                }
                statesConnection {
                  edges {
                    node {
                      ... on States {
                        title
                        abbreviation
                        slug
                      }
                    }
                  }
                }
                subtitle
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsQuestionBlock: gql`
    ... on LandingPagePageComponentsQuestionBlock {
      __typename
      Question_Block {
        questionConnection {
          edges {
            node {
              ... on QuestionBlock {
                display_title
                button_text
                placeholder
                subtitle
                success_button_text
                system {
                  uid
                }
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsImageGrid: gql`
    ... on LandingPagePageComponentsImageGrid {
      __typename
      image_grid {
        image_gridConnection {
          edges {
            node {
              ... on ImageGrid {
                heading
                images {
                  cta_link
                  fileConnection {
                    edges {
                      node {
                        description
                        dimension {
                          height
                          width
                        }
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
  LandingPagePageComponentsTritextimageblock: gql`
    ... on LandingPagePageComponentsTritextimageblock {
      __typename
      tritextimageblock {
        tri_text_image_blockConnection {
          edges {
            node {
              ... on TriTextImageBlock {
                anchor
                heading
                columns {
                  imageConnection {
                    ...imageFragment
                  }
                  header
                  text
                }
              }
            }
          }
        }
      }
    }`,
  LandingPagePageComponentsImageTransitionGrid: gql`
    ... on LandingPagePageComponentsImageTransitionGrid {
      __typename
      image_transition_grid {
        image_transition_gridConnection {
          edges {
            node {
              ... on ImageTransitionGrid {
                imagesConnection {
                  ...imageFragment
                }
                anchor
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsArticleGrid: gql`
    ... on LandingPagePageComponentsArticleGrid {
      __typename
      article_grid {
        article_gridConnection {
          edges {
            node {
            ... on ArticleGrid {
              cta {
                ...ctabuttonFragment
              }
              header
              article_pagesConnection {
                edges {
                  node {
                    ... on NewsItem {
                      system {
                        content_type_uid
                      }
                      title
                      publisher
                      published_date
                      website_url
                      imageConnection {
                        ...imageFragment
                      }
                    }
                    ... on Article {
                      system {
                        content_type_uid
                      }
                      title
                      author
                      published_date
                      category
                      article_card {
                        thumbnailConnection {
                          ...imageFragment
                        }
                      }
                      slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
  LandingPagePageComponentsProductDetailsGrid: gql`
    ... on LandingPagePageComponentsProductDetailsGrid {
      __typename
      product_details_grid {
        product_details_gridConnection {
          edges {
            node {
              ... on ProductDetailsGrid {
                header
                anchor
                cta_button {
                  ...ctabuttonFragment
                }
                products {
                  features {
                    text
                    title
                  }
                  title
                  slug
                  thumbnailConnection {
                    ...imageFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  LandingPagePageComponentsProductListing: gql`
    ...on LandingPagePageComponentsProductListing {
      __typename
      product_listing {
        category
        title
      }
    }
  `,
  LandingPagePageComponentsCtaDivider: gql`
    ... on LandingPagePageComponentsCtaDivider {
      __typename
      cta_divider {
        cta_text
        cta_url
        logged_in_cta_text
        mode
        text
      }
    }
  `,
  LandingPagePageComponentsInstructionsBlock: gql`
    ... on LandingPagePageComponentsInstructionsBlock {
      __typename
      instructions_block {
        disclaimer
        steps
        title
      }
    }
  `,
  LandingPagePageComponentsStateSwap: gql`
    ... on LandingPagePageComponentsStateSwap {
      __typename
      state_swap {
        state_swapConnection {
          edges {
            node {
              ... on Stateswap {
                default {
                  componentConnection {
                    edges {
                      node {
                        ... on ImageBlock {
                          __typename
                          ${stateComponents.ImageBlock}
                        }
                        ... on Promobanner {
                          __typename
                          ${stateComponents.Promobanner}
                        }
                        ... on RedirectSearch {
                          __typename
                          ${stateComponents.RedirectSearch}
                        }
                        ... on Textblock {
                          __typename
                          ${stateComponents.TextBlock}
                        }
                        ... on Textimageblock {
                          __typename
                          ${stateComponents.Textimageblock}
                        }
                        ... on VideoBlock {
                          __typename
                          ${stateComponents.VideoBlock}
                        }
                      }
                    }
                  }
                }
                state_component {
                  stateConnection {
                    edges {
                      node {
                        ... on States {
                          slug
                        }
                      }
                    }
                  }
                  componentConnection {
                    edges {
                      node {
                        ... on ImageBlock {
                          __typename
                          ${stateComponents.ImageBlock}
                        }
                        ... on Promobanner {
                          __typename
                          ${stateComponents.Promobanner}
                        }
                        ... on RedirectSearch {
                          __typename
                          ${stateComponents.RedirectSearch}
                        }
                        ... on Textblock {
                          __typename
                          ${stateComponents.TextBlock}
                        }
                        ... on Textimageblock {
                          __typename
                          ${stateComponents.Textimageblock}
                        }
                        ... on VideoBlock {
                          __typename
                          ${stateComponents.VideoBlock}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
};

// src/model/query/dispensaryQuery.ts
var dispensaryFragment = (forWeb = true) => `
  items {
    curaleaf_internal_id
    app_config {
        dutchie_retailer_id
        enabled
    }
    debit_fee_text
    dispensary_type
    extra_footer_text
    friendly_name
    dispensary_overview_override
    ${forWeb ? "" : "ll_api_key"}
    slug
    statesConnection {
      edges {
        node {
          ... on States {
            title
            lab_results_url
            abbreviation
            form_config {
            bypass_collecting_medical_card
            }
            hipaa_enabled
            nationally_suppress
            slug
            ${forWeb ? "" : "spring_big_auth_token"}
          }
        }
      }
    }
    store_offerings
    geolocated_kiosk_url {
      max_view_distance
      menu_url
    }
    storefront {
      ... on DispensaryConfigStorefrontPreRelease {
        __typename
        pre_release {
          description
          title
        }
      }
      ... on DispensaryConfigStorefrontDutchiePlus {
        __typename
        Dutchie_Plus {
          retailer_id
          storefrontConnection {
            edges {
              node {
                ... on Storefront {
                  cta_banner {
                    desktop_text
                    mobile_text
                    url
                  }
                  hide_flower_equivalent
                  hide_programmatic_categories
                  hide_staff_picks
                  product_listing {
                      ... on StorefrontProductListingPosTag {
                          __typename
                          pos_tag {
                              tag
                              title
                          }
                      }
                      ... on StorefrontProductListingDutchieProductList {
                          __typename
                          dutchie_product_list {
                              title
                          }
                      }
                      ... on StorefrontProductListingDynamicList {
                          __typename
                          dynamic_list {
                              brands
                              categories
                              name_includes
                              title
                          }
                      }
                  }
                }
              }
            }
          }
          menu_itemsConnection {
            edges {
              node {
                ... on ShopMenu {
                  title
                  menu_items {
                    category_imageConnection {
                      ... imageFragment
                    }
                    display_name
                    category_name_override
                  }
                }
              }
            }
          }
        }
      }
      ... on DispensaryConfigStorefrontExternal {
        __typename
        external {
          url
        }
      }
    }
    unique_id
    auto_syncConnection {
      edges {
        node {
          ... on Dispensarysync {
            unique_id
            address
            city
            country
            day_times
            dst
            email
            google_maps_uri
            latitude
            longitude
            overview
            phone
            zipcode
            timezone
            photo_url
          }
        }
      }
    }
    system {
      uid
    }
  }
  total
`;
var DispensariesQuery = (filter = "", forWeb = true) => `
  ${imageFragment}
  query Dispensary {
    all_dispensary_config ${filter} {
      ${dispensaryFragment(forWeb)}
    }
  }
`;
var DispensaryQuery = (forWeb = true) => `
  ${imageFragment}
  query Dispensary ($unique_id: String!) {
    all_dispensary_config(where: {unique_id: $unique_id}) {
      ${dispensaryFragment(forWeb)}
    }
  }
`;
var StoreTypeQuery = (filter = "") => `
  query Dispensary {
    all_dispensary_config ${filter} {
      total
      items {
        dispensary_type
        friendly_name
        slug
        statesConnection {
          edges {
            node {
              ... on States {
                abbreviation
                slug
                title
              }
            }
          }
        }
        store_offerings
        storefront {
          ... on DispensaryConfigStorefrontPreRelease {
            __typename
          }
          ... on DispensaryConfigStorefrontDutchiePlus {
            __typename
          }
          ... on DispensaryConfigStorefrontExternal {
            __typename
            external {
              url
            }
          }
        }
        unique_id
        auto_syncConnection {
          edges {
            node {
              ... on Dispensarysync {
                address
                city
                day_times
                dst
                latitude
                longitude
                photo_url
                timezone
                unique_id
                zipcode
              }
            }
          }
        }
      }
    }
  }
`;

// src/model/query/stateQuery.ts
var state_fragment = (forWeb = true) => `
  items {
    abbreviation
    hipaa_enabled
    lab_results_url
    slug
    show_transparency_link
    ${forWeb ? "" : "spring_big_auth_token"}
    title
    system {
      uid
    }
    curaleaf_com {
      copy
    }
    form_config {
      bypass_collecting_medical_card
      has_out_of_state_med
      has_out_of_state_rec
    }
    state_location_page {
      header_imageConnection {
        ... imageFragment
      }
      seo_friendly_copy
    }
  }
`;
var AllStatesQuery = (forWeb = true) => `
  ${imageFragment}
  query MyQuery  {
    all_states (limit: 100, skip: 0) {
      ${state_fragment(forWeb)}
    }
  }
`;
var StateQuery = (forWeb = true) => `
  ${imageFragment}
  query States(uid: String!) {
    all_states (where: {uid: $uid}) {
      ${state_fragment(forWeb)}
    }
  }
`;
var LocationsPageStateQuery = `
  ${imageFragment}
  query MyQuery {
    all_states(limit: 100, skip: 0) {
      items {
        curaleaf_com {
          copy
        }
        slug
        state_location_page {
          header_imageConnection {
            ...imageFragment
          }
          seo_friendly_copy
        }
        title
      }
    }
  }
`;

// src/model/query/websiteQuery.ts
var notFoundQuery = `not_found_pageConnection {
edges {
node {
... on LandingPage {
url
}
}
}
}`;
var optInModalQuery = `opt_in_modal {
delay
embeddable_formConnection {
edges {
node {
... on EmbeddableForm {
title
subtitle
type
dropdowntype
system {
uid
}
braze_custom_event_name
confirmation {
content
imageConnection {
...imageFragment
}
title
}
contact_us {
contact_us_email_destination
subject_line
}
dispensariesConnection {
edges {
node {
... on DispensaryConfig {
friendly_name
title
slug
}
}
}
}
form_title
imageConnection {
...imageFragment
}
statesConnection {
edges {
node {
... on States {
title
abbreviation
slug
}
}
}
}
}
}
}
}
}
`;
var wideBannerQuery = `wide_banners {
auto_slide_in_seconds
auto_slide_on_desktop
auto_slide_on_mobile
banners {
desktop_text
mobile_text
url
}
}
`;
var friendlyReminderQuery = `friendly_reminder {
button {
button_text
button_variation
}
detail
title
}
`;
var ageVerificationQuery = `age_verification {
background_imageConnection {
...imageFragment
}
buttons {
button_variation
exit_redirect_link
exit_text
submit_button_text
}
legal_subtext
legal_text
title
}
`;
var footerQuery = `footer {
footer_disclaimer
copyright_info
footer_links {
url
heading
links {
link_name
pageConnection {
edges {
node {
... on LandingPage {
title
slug
}
}
}
}
url
}
}
legal_links {
link_text
url
pageConnection {
edges {
node {
... on LandingPage {
title
slug
}
}
}
}
}
social_media_links {
corporate_type
url
}
}
`;
var headerQuery = `header {
bottom_links {
link_text
pageConnection {
edges {
node {
... on LandingPage {
title
slug
}
}
}
}
url
}
homepageConnection {
edges {
node {
... on LandingPage {
title
slug
url
}
}
}
}
select_state {
title
url
}
shop_section {
title
}
sections {
contentgrid {
description
heading
url
imageConnection {
...imageFragment
}
pageConnection {
edges {
node {
... on LandingPage {
    title
    slug
}
}
}
}
}
links {
pageConnection {
edges {
node {
... on LandingPage {
title
slug
}
}
}
}
title
url
}
pageConnection {
edges {
node {
... on LandingPage {
title
slug
}
}
}
}
title
url
}
}
`;
var websiteFragment = `
items {
${notFoundQuery}
${optInModalQuery}
${wideBannerQuery}
${friendlyReminderQuery}
redirects {
destination
permanent
source
}
rewrites {
destination
source
}
home_pageConnection {
edges {
node {
... on LandingPage {
slug
}
}
}
}
${ageVerificationQuery}
${footerQuery}
${headerQuery}
}
`;
var WebsiteQuery = `
${imageFragment}
query Website {
all_website {
${websiteFragment}
}
}
`;

// src/model/query/formQuery.ts
var forms_query = () => `
    items {
        braze_app_group
        collect_interest {
            collect_interest
        }
        copy {
            confirmation_content
            confirmation_title
            opt_in
            terms_and_conditions
        }
        event_name
        slug
        title
        statesConnection {
            edges {
            node {
                ... on States {
                title
                abbreviation
                }
            }
            }
        }
        system {
            uid
            content_type_uid
        }
    }
`;
var contact_forms_query = () => `
    items {
        braze_app_group
        copy {
            confirmation_content
            confirmation_title
            contact_topic_description_title
            contact_topic_title
            opt_in
            opt_in_disclaimer
            terms_and_conditions
        }
        dispensariesConnection {
                edges {
                    node {
                    ... on Dispensary {
                        title
                        unique_id
                        friendly_name
                    }
                }
            }
        }
        event_name
        formstyle
        slug
        statesConnection {
            edges {
            node {
                ... on States {
                title
                abbreviation
                }
            }
            }
        }
        system {
            uid
            content_type_uid
        }
        title
    }
`;
var CombinedFormsQuery = () => `
    query Forms {
        all_forms (limit: 20) {
            ${forms_query()}
        }
        all_contact_forms (limit: 20) {
            ${contact_forms_query()}
        }
    }
`;

// src/util/randomize.ts
var randomizeHdyhau = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

// src/util/careersMap.ts
var sortJobs = (a, b) => {
  if (a.department > b.department) {
    return 1;
  } else if (a.department < b.department) {
    return -1;
  } else {
    if (a.title > b.title) {
      return 1;
    } else if (a.title < b.title) {
      return -1;
    } else {
      if (a.location > b.location) {
        return 1;
      } else if (a.location < b.location) {
        return -1;
      } else {
        return 0;
      }
    }
  }
};
var sortStates = (a, b) => {
  if (a.title > b.title) {
    return 1;
  } else if (a.title < b.title) {
    return -1;
  }
  return 0;
};
var getJobsByStates = (states, department) => {
  let jobs = [];
  states.push({
    title: "All"
  });
  const data = states.sort((a, b) => sortStates(a, b)).map((state) => {
    let label = "";
    jobs = department.filter((job) => {
      if (job.state === state.abbreviation) {
        label = state.title;
      }
      if (state.title === "All") {
        label = state.title;
      }
      return job.state === state.abbreviation || label === "All";
    });
    jobs = jobs.sort((a, b) => sortJobs(a, b));
    const children = [];
    jobs.map((job) => {
      const match = children.find(
        (child) => child.department === job.department
      );
      if (match) {
        match.listing.push({
          title: job.title,
          url: job.url,
          location: job.location
        });
      } else {
        children.push({
          department: job.department,
          listing: [
            {
              title: job.title,
              url: job.url,
              location: job.location
            }
          ]
        });
      }
      return match;
    });
    return {
      label,
      children
    };
  });
  return data.filter((career) => career.label && career.label.length);
};
var getDepartmentJobs = (departments, states) => {
  const deptJobs = [];
  departments.map((department) => {
    department.jobs.map((job) => {
      const location = job.location.name;
      let state = null, city = null;
      const splitLocation = location.split(",");
      if ((splitLocation == null ? void 0 : splitLocation.length) > 1) {
        state = splitLocation[1].trim().replace(/[^a-z]/gi, "");
        city = splitLocation[0].trim();
      } else if ((splitLocation == null ? void 0 : splitLocation.length) === 1) {
        state = splitLocation[0].trim().replace(/[^a-z]/gi, "");
      }
      const stateMatch = states.find(
        (s) => s.abbreviation.toLowerCase() === (state == null ? void 0 : state.toLowerCase()) || s.title.toLowerCase() === (state == null ? void 0 : state.toLowerCase())
      );
      return deptJobs.push({
        department: department.name,
        title: job.title,
        url: job.absolute_url,
        location: city && stateMatch ? `${city}, ${stateMatch.abbreviation}` : !city && stateMatch ? `${stateMatch.title}` : location,
        state: stateMatch ? `${stateMatch.abbreviation}` : `${state}`
      });
    });
  });
  return deptJobs;
};

// src/model/query/selectPageQuery.ts
import { gql as gql2 } from "graphql-request";
var imageFragment2 = gql2`
  fragment imageFragment on SysAssetConnection {
    edges {
      node {
        description
        dimension {
          height
          width
        }
        url
      }
    }
  }
`;
var AllSelectPageSlugsQuery = gql2`
  query AllSelectPages {
    all_select_page(limit: 15, where: { url_nin: ["/", "/404"] }) {
      items {
        title
        url
      }
    }
  }
`;
var SelectPageComponentsTypeNameQuery = gql2`
  query SelectPageComponentsTypeName($url: String!) {
    all_select_page(where: { url: $url }, limit: 15) {
      items {
        modular_blocks {
          ... on SelectPageModularBlocksFeatureCarousel {
            __typename
          }
          ... on SelectPageModularBlocksHeroBanner {
            __typename
          }
          ... on SelectPageModularBlocksIframe {
            __typename
          }
          ... on SelectPageModularBlocksImageBlock {
            __typename
          }
          ... on SelectPageModularBlocksParallax {
            __typename
          }
          ... on SelectPageModularBlocksPromoCarousel {
            __typename
          }
          ... on SelectPageModularBlocksTextBlock {
            __typename
          }
          ... on SelectPageModularBlocksTextImageBlock {
            __typename
          }
          ... on SelectPageModularBlocksVideoBlock {
            __typename
          }
          ... on SelectPageModularBlocksXbites {
            __typename
          }
        }
      }
    }
  }
`;
var buildDynamicSelectPageQuery = (pageComponents) => {
  let pageComponentsFragment = ``;
  const typeNames = pageComponents.map(
    (pc) => pc.__typename
  );
  const filtered = pageComponents.filter(
    ({ __typename }, index) => !typeNames.includes(__typename, index + 1)
  );
  for (let i = 0; i < filtered.length; i++) {
    if (ModularBlockFragments[filtered[i].__typename]) {
      pageComponentsFragment += ModularBlockFragments[filtered[i].__typename];
    }
  }
  return gql2`
    ${imageFragment2}
    query SelectPage($url: String!) {
      all_select_page(where: { url: $url }, limit: 15) {
        items {
          url
          seo {
            imageConnection {
              ...imageFragment
            }
            no_follow
            no_index
            page_description
            page_title
          }
          modular_blocks {
            ${pageComponentsFragment}
          }
        }
      }
    }
  `;
};
var ModularBlockFragments = {
  SelectPageModularBlocksHeroBanner: gql2`
    ... on SelectPageModularBlocksHeroBanner {
      __typename
      hero_banner {
        hero_banner {
          background_color
          content {
            align
            text
          }
          cta_button {
            link
            text
          }
          image {
            desktopConnection {
              ...imageFragment
            }
            mobileConnection {
              ...imageFragment
            }
          }
          mode
        }
      }
    }
  `,
  SelectPageModularBlocksFeatureCarousel: gql2`
    ... on SelectPageModularBlocksFeatureCarousel {
      __typename
      feature_carousel {
        anchor
        feature {
          background_color
          body {
            color
            desktop_content
            mobile_content
          }
          heading {
            align
            color
            shadow
            text
          }
          image {
            desktopConnection {
              ...imageFragment
            }
            mobileConnection {
              ...imageFragment
            }
          }
          top_subheading {
            color
            text
          }
        }
      }
    }
  `,
  SelectPageModularBlocksIframe: gql2`
    ... on SelectPageModularBlocksIframe {
      __typename
      iframe {
       src
      }
    }
  `,
  SelectPageModularBlocksImageBlock: gql2`
    ... on SelectPageModularBlocksImageBlock {
      __typename
      image_block {
        desktop {
          imageConnection {
            ...imageFragment
          }
          layout
        }
        mobile {
          imageConnection {
            ...imageFragment
          }
          layout
        }
        object_fit
      }
    }
  `,
  SelectPageModularBlocksTextBlock: gql2`
    ... on SelectPageModularBlocksTextBlock {
      __typename
      text_block {
        background_color
        variant
        desktop {
          align
          content
        }
        mobile {
          align
          content
        }
  
      }
    }
  `,
  SelectPageModularBlocksParallax: gql2`
    ... on SelectPageModularBlocksParallax {
      __typename
      parallax {
        background_image {
          desktopConnection {
            ...imageFragment
          }
          mobileConnection {
            ...imageFragment
          }
        }
        static_image {
          desktopConnection {
            ...imageFragment
          }
          mobileConnection {
            ...imageFragment
          }
        }
      }
    }
  `,
  SelectPageModularBlocksPromoCarousel: gql2`
    ... on SelectPageModularBlocksPromoCarousel {
      __typename
      promo_carousel {
        anchor
        promo_banner {
          background_color
          body {
            align
            color
            content
          }
          cta_button {
            link
            text
            variant
          }
          heading {
            align
            color
            content
          }
          image {
            desktopConnection {
              ...imageFragment
            }
            mobileConnection {
              ...imageFragment
            }
          }
        }
      }
    }
  `,
  SelectPageModularBlocksTextImageBlock: gql2`
    ... on SelectPageModularBlocksTextImageBlock {
      __typename
      text_image_block {
        body {
          align
          desktop_content
          mobile_content
        }
        desktop_layout
        image {
          desktopConnection {
            ...imageFragment
          }
          mobileConnection {
            ...imageFragment
          }
        }
        mode
      }
    }
  `,
  SelectPageModularBlocksVideoBlock: gql2`
    ... on SelectPageModularBlocksVideoBlock {
      __typename
      video_block {
        background_color
        content
        link
      }
    }
  `,
  SelectPageModularBlocksXbites: gql2`
    ... on SelectPageModularBlocksXbites {
      __typename
      xbites {
        background_color
        body {
          align
          color
          content
        }
        heading {
          align
          content
        }
        subheading {
          align
          content
        }
        table {
          graph {
            heading
            image2Connection {
              ...imageFragment
            }
          }
        }
      }
    }
  `
};

// src/model/query/grassrootsPageQuery.ts
import { gql as gql3 } from "graphql-request";
var imageFragment3 = gql3`
  fragment imageFragment on SysAssetConnection {
    edges {
      node {
        description
        dimension {
          height
          width
        }
        url
      }
    }
  }
`;
var AllGrassrootsPageSlugsQuery = gql3`
  query AllGrassrootPages {
    all_grassroots_page(limit: 15, where: { url_nin: ["/", "/404"] }) {
      items {
        title
        url
      }
    }
  }
`;
var GrassrootsSeoQuery = gql3`
  ${imageFragment3}
  query GrassrootsPageSeo($url: String!) {
    all_grassroots_page(where: { url: $url }) {
      items {
        seo {
          imageConnection {
            ...imageFragment
          }
          no_follow
          no_index
          page_description
          page_title
        }
      }
    }
  }
`;
var GrassrootsPageComponentsTypeNameQuery = gql3`
  query GrassrootsPageComponentsTypeName($url: String!) {
    all_grassroots_page(where: { url: $url }, limit: 15) {
      items {
        modular_blocks {
          ... on GrassrootsPageModularBlocksHeroStackedCarousel {
            __typename
          }
          ... on GrassrootsPageModularBlocksIframe {
            __typename
          }
          ... on GrassrootsPageModularBlocksImageBlock {
            __typename
          }
          ... on GrassrootsPageModularBlocksImageTransitionGrid {
            __typename
          }
          ... on GrassrootsPageModularBlocksInstagramGrid {
            __typename
          }
          ... on GrassrootsPageModularBlocksProductCardGrid {
            __typename
          }
          ... on GrassrootsPageModularBlocksPromoCarousel {
            __typename
          }
          ... on GrassrootsPageModularBlocksTextblock {
            __typename
          }
          ... on GrassrootsPageModularBlocksTextImageBlock {
            __typename
          }
          ... on GrassrootsPageModularBlocksTriTextImageBlock {
            __typename
          }
        }
      }
    }
  }
`;
var buildDynamicGrassrootsPageQuery = (pageComponents) => {
  let pageComponentsFragment = ``;
  const typeNames = pageComponents.map(
    (pc) => pc.__typename
  );
  const filtered = pageComponents.filter(
    ({ __typename }, index) => !typeNames.includes(__typename, index + 1)
  );
  for (let i = 0; i < filtered.length; i++) {
    if (ModularBlockFragments2[filtered[i].__typename]) {
      pageComponentsFragment += ModularBlockFragments2[filtered[i].__typename];
    }
  }
  return gql3`
    ${imageFragment3}
    query GrassrootsPage($url: String!) {
      all_grassroots_page(where: { url: $url }, limit: 15) {
        items {
          url
          seo {
            imageConnection {
              ...imageFragment
            }
            no_follow
            no_index
            page_description
            page_title
          }
          modular_blocks {
            ${pageComponentsFragment}
          }
        }
      }
    }
  `;
};
var ModularBlockFragments2 = {
  GrassrootsPageModularBlocksHeroStackedCarousel: gql3`
    ... on GrassrootsPageModularBlocksHeroStackedCarousel {
      __typename
      hero_stacked_carousel {
        banner {
          content
          cta_button {
            link
            text
            variant
          }
          heading
          image {
            desktopConnection {
              ...imageFragment
            }
            mobileConnection {
              ...imageFragment
            }
          }
        }
      }
    }
  `,
  GrassrootsPageModularBlocksIframe: gql3`
    ... on GrassrootsPageModularBlocksIframe {
      __typename
      iframe {
        src
      }
    }
  `,
  GrassrootsPageModularBlocksImageBlock: gql3`
    ... on GrassrootsPageModularBlocksImageBlock {
      __typename
      image_block {
        desktopConnection {
          ...imageFragment
        }
        mobileConnection {
          ...imageFragment
        }
      }
    }
  `,
  GrassrootsPageModularBlocksImageTransitionGrid: gql3`
    ... on GrassrootsPageModularBlocksImageTransitionGrid {
      __typename
      image_transition_grid {
        imageConnection {
          ...imageFragment
        }
      }
    }
  `,
  GrassrootsPageModularBlocksInstagramGrid: gql3`
    ... on GrassrootsPageModularBlocksInstagramGrid {
      __typename
      instagram_grid {
        image {
          link
          imageConnection {
            ...imageFragment
          }
        }
      }
    }
  `,
  GrassrootsPageModularBlocksProductCardGrid: gql3`
    ... on GrassrootsPageModularBlocksProductCardGrid {
      __typename
      product_card_grid {
        anchor
        card {
          feature {
            content
            heading
          }
          heading
          imageConnection {
            ...imageFragment
          }
        }
        heading
      }
    }
  `,
  GrassrootsPageModularBlocksPromoCarousel: gql3`
    ... on GrassrootsPageModularBlocksPromoCarousel {
      __typename
      promo_carousel {
        anchor
        promo_banner {
          content
          cta_button {
            link
            text
            variant
          }
          heading
          image {
            desktopConnection {
              ...imageFragment
            }
            mobileConnection {
              ...imageFragment
            }
          }
          layout
        }
      }
    }
  `,
  GrassrootsPageModularBlocksTextblock: gql3`
    ... on GrassrootsPageModularBlocksTextblock {
      __typename
      textblock {
        content
      }
    }
  `,
  GrassrootsPageModularBlocksTextImageBlock: gql3`
    ... on GrassrootsPageModularBlocksTextImageBlock {
      __typename
      text_image_block {
        anchor
        content
        heading
        imageConnection {
          ...imageFragment
        }
        layout
      }
    }
  `,
  GrassrootsPageModularBlocksTriTextImageBlock: gql3`
    ... on GrassrootsPageModularBlocksTriTextImageBlock {
      __typename
      tri_text_image_block {
        column {
          content
          imageConnection {
            ...imageFragment
          }
        }
        heading
      }
    }
  `
};

// src/util/buildContentPageQuery.ts
var buildContentPageQuery = (modular_blocks, brand) => {
  switch (brand) {
    case "select": {
      return buildDynamicSelectPageQuery(modular_blocks);
    }
    case "grassroots": {
      return buildDynamicGrassrootsPageQuery(modular_blocks);
    }
    default: {
      return "";
    }
  }
};
var buildContentPageTypeNameQuery = (brand) => {
  switch (brand) {
    case "select": {
      return SelectPageComponentsTypeNameQuery;
    }
    case "grassroots": {
      return GrassrootsPageComponentsTypeNameQuery;
    }
    default: {
      return "";
    }
  }
};
var buildContentPageSlugsQuery = (brand) => {
  switch (brand) {
    case "select": {
      return AllSelectPageSlugsQuery;
    }
    case "grassroots": {
      return AllGrassrootsPageSlugsQuery;
    }
    default: {
      return "";
    }
  }
};

// src/api/contentstack/contentstack.service.ts
var ContentStackService = class {
  constructor() {
    this.baseUrl = "https://graphql.contentstack.com/stacks";
    this.apiKey = process.env.CONTENTSTACK_API_KEY;
    this.token = process.env.CONTENTSTACK_ACCESS_TOKEN;
    this.environment = process.env.CONTENTSTACK_ENVIRONMENT;
    this.previewEnvironment = process.env.CONTENTSTACK_PREVIEW_ENVIRONMENT;
    this.previewToken = process.env.CONTENTSTACK_PREVIEW_ACCESS_TOKEN;
    this.getDecisionTreeData = async (decision_tree_start, isPreview) => {
      const allQuestions = await this.getDecisionTreesQuestionByTag(
        decision_tree_start.tag,
        isPreview
      );
      if (allQuestions.success && allQuestions.data) {
        const startingQuestion = allQuestions.data.find(
          (question) => question.system.uid === decision_tree_start.decision_treeConnection.edges[0].node.system.uid
        );
        if (startingQuestion) {
          const startingQuestionMapped = {
            answers: startingQuestion.answersConnection.edges,
            question: startingQuestion.question
          };
          return updateMatchingQuestion(
            startingQuestionMapped,
            0,
            allQuestions.data
          );
        }
      }
      return null;
    };
  }
  getLandingPageSlugs(isHomePage, isPreview) {
    const params = {
      isHomePage
    };
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      compress(AllLandingPageSlugsQuery),
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({
        all_landing_page
      }) => {
        all_landing_page.items.filter((item) => item.url).map((item) => {
          item.url = item.url && item.url[0] === "/" ? item.url.slice(1) : item.url;
          return item;
        });
        return {
          success: true,
          data: all_landing_page
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getLandingPageByUrl(url, isPreview) {
    const params = {
      url
    };
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      compress(LandingPageTypeNameQuery),
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({
        all_landing_page
      }) => {
        return {
          success: true,
          data: all_landing_page
        };
      }
    ).then(async ({ data }) => {
      return request(
        `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
        compress(
          buildDynamicLandingPageQuery(
            data.items[0].page_components
          )
        ),
        params,
        { access_token: isPreview ? this.previewToken : this.token }
      ).then(
        ({
          all_landing_page
        }) => {
          all_landing_page.items.map((item) => {
            item.url = item.url[0] === "/" ? item.url.slice(1) : item.url;
            return item;
          });
          return {
            success: true,
            data: all_landing_page
          };
        }
      ).catch((err) => {
        return {
          success: false,
          errorMessage: err
        };
      });
    }).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  async getDispensaryData(forWeb = true, isPreview) {
    const pageSize = 100;
    const origDispensaries = [];
    const firstRequest = await request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      DispensariesQuery(`(limit: ${pageSize}, skip: 0)`, forWeb),
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    );
    const total = firstRequest.all_dispensary_config.total;
    origDispensaries.push(...firstRequest.all_dispensary_config.items);
    const callCount = Math.ceil(total / pageSize);
    for (let i = 1; i < callCount; i++) {
      const newReq = await request(
        `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
        DispensariesQuery(
          `(limit: ${pageSize}, skip: ${i * pageSize})`,
          forWeb
        ),
        {},
        { access_token: isPreview ? this.previewToken : this.token }
      );
      origDispensaries.push(...newReq.all_dispensary_config.items);
    }
    return {
      success: true,
      data: origDispensaries.filter((oDispo) => {
        var _a, _b;
        return (_b = (_a = oDispo.auto_syncConnection) == null ? void 0 : _a.edges) == null ? void 0 : _b.length;
      }).map((oDispo) => {
        try {
          const { statesConnection, auto_syncConnection, ...tmpDisp } = oDispo;
          return {
            ...tmpDisp,
            ...auto_syncConnection.edges[0].node,
            day_times: auto_syncConnection.edges[0].node.day_times ? JSON.parse(auto_syncConnection.edges[0].node.day_times) : [],
            distance: "",
            state: statesConnection.edges[0] ? statesConnection.edges[0].node : void 0,
            unique_id: oDispo.unique_id
          };
        } catch (err) {
          console.error(err);
        }
      }).filter(
        (dispo) => dispo && dispo.state && (process.env.NEXT_PUBLIC_NATIONAL_WEBSITE !== "true" || !dispo.state.nationally_suppress)
      )
    };
  }
  async getLocationsPageDispensaryData(isPreview) {
    const pageSize = 100;
    const origDispensaries = [];
    const firstRequest = await request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      StoreTypeQuery(`(limit: ${pageSize}, skip: 0)`),
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    );
    const total = firstRequest.all_dispensary_config.total;
    origDispensaries.push(...firstRequest.all_dispensary_config.items);
    const callCount = Math.ceil(total / pageSize);
    for (let i = 1; i < callCount; i++) {
      const newReq = await request(
        `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
        StoreTypeQuery(`(limit: ${pageSize}, skip: ${i * pageSize})`),
        {},
        { access_token: isPreview ? this.previewToken : this.token }
      );
      origDispensaries.push(...newReq.all_dispensary_config.items);
    }
    return {
      success: true,
      data: origDispensaries.filter((oDispo) => {
        var _a, _b;
        return (_b = (_a = oDispo.auto_syncConnection) == null ? void 0 : _a.edges) == null ? void 0 : _b.length;
      }).map((oDispo) => {
        try {
          const { statesConnection, auto_syncConnection, ...tmpDisp } = oDispo;
          return {
            ...tmpDisp,
            ...auto_syncConnection.edges[0].node,
            day_times: auto_syncConnection.edges[0].node.day_times ? JSON.parse(auto_syncConnection.edges[0].node.day_times) : [],
            distance: "",
            state: statesConnection.edges[0] ? statesConnection.edges[0].node : void 0,
            unique_id: oDispo.unique_id
          };
        } catch (err) {
          console.error(err);
        }
      }).filter((dispo) => dispo)
    };
  }
  async getADispensary(uid, forWeb = true, isPreview) {
    const params = {
      unique_id: uid
    };
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      DispensaryQuery(forWeb),
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({ all_dispensary_config }) => {
        var _a;
        const dispensary_config = all_dispensary_config.items[0];
        const { statesConnection, auto_syncConnection, ...restDisp } = dispensary_config;
        if (!auto_syncConnection.edges[0])
          throw new Error(
            `Dispensary (uid: ${uid}) is missing auto sync connection in contentstack`
          );
        const { day_times, ...restAutoSync } = auto_syncConnection.edges[0].node;
        const dayTimes = JSON.parse(day_times);
        const distance = "";
        const state = (_a = statesConnection.edges[0]) == null ? void 0 : _a.node;
        if (!state || process.env.NEXT_PUBLIC_NATIONAL_WEBSITE === "true" && state.nationally_suppress) {
          throw new Error(
            `Dispensary (uid: ${uid}) is missing state connection`
          );
        }
        const data = {
          ...restDisp,
          ...restAutoSync,
          day_times: dayTimes,
          state,
          distance,
          uid: restDisp.unique_id
        };
        return {
          success: true,
          data
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getDispensarySync(uid) {
    const params = {
      uid
    };
    const query = `
      query DispensarySync($uid: String!) {
        all_dispensarysync(where: { unique_id: $uid }) {
          items {
            city
          }
        }
      }
    `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${this.environment}`,
      query,
      params,
      { access_token: this.token }
    ).then(
      ({
        all_dispensarysync
      }) => ({
        success: true,
        data: all_dispensarysync.items[0]
      })
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getNewsItems(isPreview) {
    const query = `
        query NewsItems (
            $skip: Int!
        ) {
            all_news_item(where: {published_date_gte: "2021-01-01"}, skip: $skip) {
                items {
                    imageConnection {
                        edges {
                            node {
                                description
                                dimension {
                                    height
                                    width
                                }
                                url
                            }
                        }
                    }
                    published_date
                    title
                    website_url
                }
            }
        }`;
    const newsReq1 = await request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      {
        skip: 0
      },
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({ all_news_item }) => {
        return {
          success: true,
          data: all_news_item.items
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
    const newsReq2 = await request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      {
        skip: 100
      },
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({ all_news_item }) => {
        return {
          success: true,
          data: all_news_item.items
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
    return Promise.all([newsReq1, newsReq2]).then((res) => {
      if (res[0].success && res[1].success) {
        const newsItems = res[0].data.concat(res[1].data);
        const sorted = newsItems.sort((a, b) => {
          if (a.published_date > b.published_date) {
            return -1;
          } else if (a.published_date < b.published_date) {
            return 1;
          } else
            return 0;
        });
        return {
          success: true,
          data: sorted
        };
      } else {
        return {
          success: false,
          data: [],
          errorMessage: "One of the requests failed"
        };
      }
    });
  }
  async getArticles(category, isPreview) {
    const params = {
      category
    };
    const pageSize = 100;
    const origArticles = [];
    const articleQuery = (str) => {
      return `
        ${imageFragment}

        query AllArticles  (
          $category: String!
        ) {
          all_article(${str} where: {category: $category}) {
            items {
              article_card {
                thumbnailConnection {
                  ...imageFragment
                }
              }
              author
              published_date
              slug
              title
            }
          }
        }
      `;
    };
    const firstRequest = await request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      articleQuery(`limit: ${pageSize}, skip: 0,`),
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    );
    const total = firstRequest.all_article.total;
    origArticles.push(...firstRequest.all_article.items);
    const callCount = Math.ceil(total / pageSize);
    for (let i = 1; i < callCount; i++) {
      const newReq = await request(
        `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
        articleQuery(`limit: ${pageSize}, skip: ${i * pageSize},`),
        params,
        { access_token: isPreview ? this.previewToken : this.token }
      );
      origArticles.push(...newReq.all_article.items);
    }
    const sorted = origArticles.sort((a, b) => {
      if (a.published_date > b.published_date) {
        return -1;
      } else if (a.published_date < b.published_date) {
        return 1;
      } else
        return 0;
    });
    return {
      success: true,
      data: sorted
    };
  }
  getArticlesCdn(limit, category) {
    return axios3.get(
      `https://cdn.contentstack.io/v3/content_types/article/entries?environment=develop&locale=en-us&include_fallback=true&desc=published_date&limit=${limit}&query={"category":"${category}"}`,
      {
        headers: {
          access_token: this.token,
          api_key: this.apiKey
        }
      }
    ).then(
      ({
        data
      }) => {
        return {
          success: true,
          data: data.entries
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getArticleBySlug(slug, isPreview) {
    const params = {
      slug
    };
    const query = `
      ${imageFragment}

      query ArticleBySlug($slug: String!) {
        all_article(where: { slug: $slug }) {
          items {
            seo {
              description
            }
            article_card {
              thumbnailConnection {
                ...imageFragment
              }
            }
            author
            category
            page {
              ... on ArticlePageImageBlock {
                __typename
                image_block {
                  full_width
                  imageConnection {
                    ...imageFragment
                  }
                }
              }
              ... on ArticlePageArticleHeader {
                __typename
                article_header {
                  reading_length
                }
              }
              ... on ArticlePageTextBlock {
                __typename
                text_block {
                  content
                }
              }
              ... on ArticlePageRelatedArticles {
                __typename
                related_articles {
                  articlesConnection(limit: 3) {
                    edges {
                      node {
                        ... on Article {
                          article_card {
                            thumbnailConnection {
                              ...imageFragment
                            }
                          }
                          title
                          category
                          author
                          published_date
                          slug
                        }
                      }
                    }
                  }
                  background
                  heading
                }
              }
            }
            published_date
            slug
            title
          }
        }
      }
    `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(({ all_article }) => {
      return {
        success: true,
        data: all_article.items[0]
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getCommunityNewsItems(isPreview) {
    const params = {};
    const pageSize = 100;
    const origCommunity = [];
    const communityQuery = (str) => {
      return `
      ${imageFragment}

      query CommunityNews {
          all_community_news_item ${str} {
              items {
                  article_card {
                      thumbnailConnection {
                          ...imageFragment
                      }
                  }
                  published_date
                  seo {
                      description
                  }
                  slug
                  stateConnection {
                      edges {
                          node {
                              ... on States {
                                  slug
                              }
                          }
                      }
                  }
                  title
              }
          }
      }`;
    };
    const firstRequest = await request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      communityQuery(`(limit: ${pageSize}, skip: 0)`),
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    );
    const total = firstRequest.all_community_news_item.total;
    origCommunity.push(...firstRequest.all_community_news_item.items);
    const callCount = Math.ceil(total / pageSize);
    for (let i = 1; i < callCount; i++) {
      const newReq = await request(
        `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
        communityQuery(`limit: ${pageSize}, skip: ${i * pageSize},`),
        params,
        { access_token: isPreview ? this.previewToken : this.token }
      );
      origCommunity.push(...newReq.all_community_news_item.items);
    }
    const sorted = origCommunity.sort(
      (a, b) => {
        if (a.published_date > b.published_date) {
          return -1;
        } else if (a.published_date < b.published_date) {
          return 1;
        } else
          return 0;
      }
    );
    return {
      success: true,
      data: sorted
    };
  }
  getCommunityNewsItemCdn(limit) {
    return axios3.get(
      `https://cdn.contentstack.io/v3/content_types/community_news_item/entries?environment=develop&locale=en-us&include_fallback=true&desc=published_date&limit=${limit}`,
      {
        headers: {
          access_token: this.token,
          api_key: this.apiKey
        }
      }
    ).then(
      ({
        data
      }) => {
        return {
          success: true,
          data: data.entries
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getCommunityNewsItemBySlug(slug, isPreview) {
    const params = {
      slug
    };
    const query = `
      ${imageFragment}

      query CommunityNewsBySlug (
          $slug: String!
      ) {
          all_community_news_item (where: {slug: $slug}) {
              items {
                  article_card {
                      thumbnailConnection {
                          ...imageFragment
                      }
                  }
                  author
                  brand {
                      brand
                  }
                  page {
                      ... on CommunityNewsItemPageImageBlock {
                          __typename
                          image_block {
                              full_width
                              imageConnection {
                                  ...imageFragment
                              }
                          }
                      }
                      ... on CommunityNewsItemPageArticleHeader {
                          __typename
                          article_header {
                              reading_length
                          }
                      }
                      ... on CommunityNewsItemPageTextBlock {
                          __typename
                          text_block {
                              content
                          }
                      }
                  }
                  published_date
                  seo {
                      description
                  }
                  slug
                  stateConnection {
                      edges {
                          node {
                              ... on States {
                                  title
                                  slug
                              }
                          }
                      }
                  }
                  title
              }
          }
      }`;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({
        all_community_news_item
      }) => {
        return {
          success: true,
          data: all_community_news_item.items[0]
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getStatesData(isPreview = false, forWeb = true) {
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      AllStatesQuery(forWeb),
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(({ all_states }) => {
      return {
        success: true,
        data: all_states.items.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          } else if (a.title > b.title) {
            return 1;
          } else
            return 0;
        })
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getAStates(uid, isPreview, forWeb = true) {
    const params = {
      uid
    };
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      StateQuery(forWeb),
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(({ all_states }) => {
      return {
        success: true,
        data: all_states.items.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          } else if (a.title > b.title) {
            return 1;
          } else
            return 0;
        })
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getFormConfig(isPreview) {
    const query = `
      query FormConfiguration {
        all_Form_Configuration {
          items {
            contact_us {
              topics {
                display_value
              }
            }
            in_store_intake {
              disclaimer_copy
              form_title
              marketing_intake_checkbox_copy
              submit_button_text
              success_message
              success_title
              success_close_button_text
              timeouts {
                modal_button_text
                modal_title
                popup_time
                time_to_dismiss
              }
            }
            general {
              disclaimer
              how_did_you_hear_about_us {
                display_text
                value
              }
              interests {
                display_text
                value
              }
              submit_button_text
            }
          }
        }
      }
    `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      null,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({
        all_Form_Configuration
      }) => {
        if (all_Form_Configuration.items[0]) {
          all_Form_Configuration.items[0].general.how_did_you_hear_about_us = randomizeHdyhau(
            all_Form_Configuration.items[0].general.how_did_you_hear_about_us
          );
        }
        return {
          success: true,
          data: all_Form_Configuration.items[0]
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getDecisionTreesQuestionByTag(tag, isPreview) {
    const params = {
      tag
    };
    const query = `
      query DecisionTreeQuestions ( $tag: String! ){
        all_decision_tree_questions(where: {tags_in: $tag}) {
          items {
            answersConnection {
              edges {
                node {
                  ... on DecisionTreeAnswer {
                    text
                    questionConnection {
                      edges {
                        node {
                          ... on DecisionTreeQuestions {
                            system {
                              uid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            question
            system {
              uid
            }
          }
        }
      }`;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({
        all_decision_tree_questions
      }) => {
        return {
          success: true,
          data: all_decision_tree_questions.items
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getPdfs(isPreview) {
    const query = `
      query Pdfs {
        all_Pdf {
          items {
            title
            url
            fileConnection {
              edges {
                node {
                  title
                  url
                }
              }
            }
          }
        }
      }
      `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(({ all_Pdf }) => {
      return {
        success: true,
        data: all_Pdf.items
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getEmbeddableForm(uid, isPreview) {
    const params = {
      uid
    };
    const query = `
    ${imageFragment}

    query EmbeddedForms ( $uid: String! ) {
      Embeddable_Form(uid: $uid) {
        braze_custom_event_name
        confirmation {
          content
          imageConnection {
            ...imageFragment
          }
        }
        contact_us {
          submit_to_zendesk
          contact_us_email_destination
          subject_line
        }
        dispensariesConnection {
          edges {
            node {
              ... on DispensaryConfig {
                title
                slug
              }
            }
          }
        }
        dropdowntype
        form_title
        imageConnection {
          ...imageFragment
        }
        statesConnection {
          edges {
            node {
              ... on States {
                slug
                title
              }
            }
          }
        }
        subtitle
        system {
          uid
        }
        target_crm_cdpConnection {
          edges {
            node {
              ... on CrmCdp {
                title
                braze {
                  api_key
                  app_key
                  instance_url
                }
              }
            }
          }
        }
        title
        type
      }
    }`;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({
        Embeddable_Form
      }) => {
        return {
          success: true,
          data: Embeddable_Form
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getWebsiteData(isPreview = false) {
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      WebsiteQuery,
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(({ all_website }) => {
      return {
        success: true,
        data: all_website.items
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getAccountPreferenceForm(isPreview = false) {
    const query = `
    query AccountLoyaltyPrefs {
      all_account_loyalty_preferences {
        items {
          crm_preferences_page {
            brand_preferences {
              name
            }
            category_preferences {
              name
            }
            copy {
              brands
              product_categories
            }
            statesConnection {
              edges {
                node {
                  ... on States {
                    title
                    abbreviation
                  }
                }
              }
            }
            top_hero_bannerConnection {
              edges {
                node {
                  ... on HeroBanner {
                    title
                    anchor
                  }
                }
              }
            }
          }
          title
          subtext
          refer_a_friend {
            heading
            instructions {
              disclaimer
              steps
              title
            }
            tagline
          }
          subtext
          title
          disclaimer
        }
      }
    }
  `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      null,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({
        all_account_loyalty_preferences
      }) => {
        return {
          success: true,
          data: all_account_loyalty_preferences.items[0]
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getNews(isPreview) {
    const pageSize = 100;
    const origNews = [];
    const newsQuery = (str) => `
      ${imageFragment}

      query News {
        all_news_item ${str} {
          items {
            imageConnection {
              ...imageFragment
            }
            published_date
            publisher
            title
            website_url
          }
          total
        }
      }
`;
    const firstRequest = await request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      newsQuery(`(limit: ${pageSize}, skip: 0)`),
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    );
    const total = firstRequest.all_news_item.total;
    origNews.push(...firstRequest.all_news_item.items);
    const callCount = Math.ceil(total / pageSize);
    for (let i = 1; i < callCount; i++) {
      const newReq = await request(
        `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
        newsQuery(`(limit: ${pageSize}, skip: ${i * pageSize})`),
        {},
        { access_token: isPreview ? this.previewToken : this.token }
      );
      origNews.push(...newReq.all_news_item.items);
    }
    const sorted = origNews.sort((a, b) => {
      if (a.published_date > b.published_date) {
        return -1;
      } else if (a.published_date < b.published_date) {
        return 1;
      } else
        return 0;
    });
    return {
      success: true,
      data: sorted
    };
  }
  async getQuestionBlockEmailInfo(uid, isPreview) {
    const params = {
      uid
    };
    const query = `
    query QuestionBlock {
      all_question_block {
        items {
          email_destination
          email_subject
        }
      }
    }
    `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({
        all_question_block
      }) => {
        return {
          success: true,
          data: all_question_block.items
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getAllForms(isPreview) {
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      CombinedFormsQuery(),
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    ).then((data) => {
      return {
        success: true,
        data: data.all_forms.items.concat(data.all_contact_forms.items)
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getAppNationalContent(isPreview) {
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      `
      query AppNationalContent {
        all_app_content {
          items {
            home_logged_out {
              title
              description
              bannerConnection {
                edges {
                  node {
                    url
                    content_type
                  }
                }
              }
            }
            home_logged_in {
              bannerConnection {
                edges {
                  node {
                    url
                    content_type
                  }
                }
              }
              description
              title
            }
            dispensary_default_imageConnection {
                edges {
                  node {
                    content_type
                    url
                  }
                }
              }
            category_default_imageConnection {
                edges {
                    node {
                        url
                    }
                }
            }
              marketing_dialogs {
                title
                description
                imageConnection {
                  edges {
                    node {
                      url
                      content_type
                    }
                  }
                }
              }
          }
        }
      }
      
      `,
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    ).then((data) => {
      return {
        success: true,
        data: data.all_app_content.items ? data.all_app_content.items[0] : null
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getCrmCdp(brand, isPreview) {
    const params = {
      brand
    };
    const query = `
    query CrmCdp ($brand: String!) {
      all_crm_cdp(where: {title: $brand}) {
        items {
          braze {
            api_key
            app_key
            instance_url
          }
        }
      }
    }
    `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({ all_crm_cdp }) => {
        return {
          success: true,
          data: all_crm_cdp.items[0]
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getStateAgeVerification(isPreview) {
    const query = `
    query StateAgeVerification {
      all_state_age_verification {
        items {
          background_imageConnection {
            edges {
              node {
                dimension {
                  height
                  width
                }
                url
              }
            }
          }
          exit_link_text
          header
          state_config {
            button_text
            display_name
            exit_url
            header
            legal_subtext
          }
          title
          titles
        }
      }
    }`;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({
        all_state_age_verification
      }) => {
        return {
          success: true,
          data: all_state_age_verification.items.length ? all_state_age_verification.items[0] : {}
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async favoredBrandOrder(isPreview) {
    const query = `
      query FavoredBrands {
        all_ecommerce_config {
          items {
            favored_brands {
              brand_name
            }
          }
        }
      }
    `;
    return request(
      this.generateRequestUrl(isPreview),
      query,
      {},
      this.generateRequestHeaders(isPreview)
    ).then(
      ({
        all_ecommerce_config
      }) => {
        return {
          success: true,
          data: all_ecommerce_config.items[0]
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  generateRequestUrl(isPreview) {
    return `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`;
  }
  generateRequestHeaders(isPreview) {
    return { access_token: isPreview ? this.previewToken : this.token };
  }
  async getDefaultOrderConfirmation(isPreview = false) {
    const query = `
      query OrderConfirmation {
        all_ecommerce_config {
          items {
            default_order_confirmation_text
            statetypeoverride {
              order_confirmation_text
              order_type
              med_rec
              stateConnection {
                edges {
                  node {
                    ... on States {
                      abbreviation
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      query,
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(
      ({
        all_ecommerce_config
      }) => {
        return {
          success: true,
          data: all_ecommerce_config.items[0]
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getLocationsPageStatesData(isPreview = false) {
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      LocationsPageStateQuery,
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    ).then(({ all_states }) => {
      return {
        success: true,
        data: all_states.items.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          } else if (a.title > b.title) {
            return 1;
          } else
            return 0;
        })
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getStorefrontBanners2(stateAbbr, dispensarySlug) {
    const query = gql4`
      ${imageFragment}
      query StorefrontBanners2($stateAbbr: String, $dispensarySlug: String) {
        all_storefront_banner_2(
          where: {
            OR: [
              { state: { states: { abbreviation: $stateAbbr } } }
              { dispensary: { dispensary_config: { slug: $dispensarySlug } } }
            ]
          }
        ) {
          items {
            banners {
              ... on StorefrontBanner2BannersTextImageBanner {
                __typename
                text_image_banner {
                  cta_action {
                    ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToAccount {
                      __typename
                      go_to_account {
                        location
                      }
                    }
                    ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToCategory {
                      __typename
                      go_to_category {
                        category_name
                      }
                    }
                    ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToBrandCategory {
                      __typename
                      go_to_brand_category {
                        brand_name
                        category_name
                      }
                    }
                    ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToSearch {
                      __typename
                      go_to_search {
                        search_text
                      }
                    }
                    ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToUrl {
                      __typename
                      go_to_url {
                        url
                      }
                    }
                    ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToBrand {
                      __typename
                      go_to_brand {
                        brand_name
                      }
                    }
                  }
                  desktop {
                    body
                    cta_button {
                      text
                    }
                    heading
                    imageConnection {
                      ...imageFragment
                    }
                  }
                  end_time
                  layout
                  mobile {
                    body
                    heading
                    imageConnection {
                      ...imageFragment
                    }
                  }
                  mode
                  rank
                  start_time
                  where_to_show
                }
              }
              ... on StorefrontBanner2BannersImageBlock {
                __typename
                image_block {
                  cta_action {
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToAccount {
                      __typename
                      go_to_account {
                        location
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToCategory {
                      __typename
                      go_to_category {
                        category_name
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToBrandCategory {
                      __typename
                      go_to_brand_category {
                        brand_name
                        category_name
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToSearch {
                      __typename
                      go_to_search {
                        search_text
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToBrand {
                      __typename
                      go_to_brand {
                        brand_name
                      }
                    }
                    ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToUrl {
                      __typename
                      go_to_url {
                        url
                      }
                    }
                  }
                  desktop_imageConnection {
                    ...imageFragment
                  }
                  end_time
                  mobile_imageConnection {
                    ...imageFragment
                  }
                  rank
                  start_time
                  where_to_show
                }
              }
            }
          }
        }
      }
    `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${this.environment}`,
      query,
      {
        stateAbbr,
        dispensarySlug
      },
      { access_token: this.token }
    ).then(
      ({
        all_storefront_banner_2
      }) => {
        return {
          success: true,
          data: all_storefront_banner_2.items
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  async getSecurityRules() {
    const query = `
    query SecurityRules {
      all_security_rules {
        items {
          blocked_numbers
          blocked_prefixes
          blocked_suffixes
        }
      }
    }
  `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${this.environment}`,
      query,
      {},
      { access_token: this.token }
    ).then((response) => {
      return {
        success: true,
        data: response.all_security_rules.items
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getCareerData(isPreview) {
    const jobsReq = await axios3(
      "https://boards-api.greenhouse.io/v1/boards/curaleaf/departments"
    );
    const statesReq = await this.getStatesData(isPreview, true);
    const departments = jobsReq.data.departments.map((department) => ({
      name: department.name,
      jobs: department.jobs
    })).filter((department) => department.jobs.length);
    if (statesReq.success && departments && Object.keys(departments.length)) {
      const departmentJobs = getDepartmentJobs(departments, statesReq.data);
      const career = getJobsByStates(statesReq.data, departmentJobs);
      return {
        success: true,
        data: career
      };
    }
    return {
      success: false,
      errorMessage: ""
    };
  }
  async getEcommerceTextBanner(stateAbbr, dispensarySlug) {
    const query = `
      query EcommerceTextBanner($dispensarySlug: String, $stateAbbr: String) {
        all_ecommerce_text_banner(
          where: {
            OR: [
              { state: { states: { abbreviation: $stateAbbr } } }
              { dispensary: { dispensary_config: { slug: $dispensarySlug } } }
            ]
          }
        ) {
          items {
            dispensaryConnection {
              edges {
                node {
                  ... on DispensaryConfig {
                    slug
                  }
                }
              }
            }
            scheduled_text_banner {
              banner_text
              scheduled_end
              scheduled_start
            }
          }
        }
      }
    `;
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${this.environment}`,
      query,
      {
        stateAbbr,
        dispensarySlug
      },
      { access_token: this.token }
    ).then(
      ({
        all_ecommerce_text_banner
      }) => {
        return {
          success: true,
          data: all_ecommerce_text_banner.items
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getContentPageByUrl(url, isPreview) {
    const params = {
      url
    };
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      compress(
        buildContentPageTypeNameQuery(process.env.BRAND)
      ),
      params,
      { access_token: isPreview ? this.previewToken : this.token }
    ).then((data) => {
      if (process.env.BRAND === "select" && data.all_select_page) {
        return {
          success: true,
          data: data.all_select_page.items[0]
        };
      } else if (process.env.BRAND === "grassroots" && data.all_grassroots_page) {
        return {
          success: true,
          data: data.all_grassroots_page.items[0]
        };
      } else {
        throw new Error("Missing brand for buildContentPageTypeNameQuery");
      }
    }).then(
      async ({
        data
      }) => {
        return request(
          `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
          compress(
            buildContentPageQuery(
              data.modular_blocks,
              process.env.BRAND
            )
          ),
          params,
          { access_token: isPreview ? this.previewToken : this.token }
        ).then((data2) => {
          if (process.env.BRAND === "select" && data2.all_select_page) {
            data2.all_select_page.items.map((item) => {
              item.url = item.url[0] === "/" ? item.url.slice(1) : item.url;
              return item;
            });
            return {
              success: true,
              data: data2.all_select_page.items[0]
            };
          } else if (process.env.BRAND === "grassroots" && data2.all_grassroots_page) {
            data2.all_grassroots_page.items.map((item) => {
              item.url = item.url[0] === "/" ? item.url.slice(1) : item.url;
              return item;
            });
            return {
              success: true,
              data: data2.all_grassroots_page.items[0]
            };
          } else {
            throw new Error("Missing data for buildContentPageQuery");
          }
        }).catch(
          (err) => {
            return {
              success: false,
              errorMessage: err
            };
          }
        );
      }
    ).catch(
      (err) => {
        return {
          success: false,
          errorMessage: err
        };
      }
    );
  }
  getContentPageSlugs(isPreview) {
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      compress(
        buildContentPageSlugsQuery(process.env.BRAND)
      ),
      {},
      { access_token: isPreview ? this.previewToken : this.token }
    ).then((data) => {
      if (process.env.BRAND === "select") {
        data.all_select_page.items.filter((item) => item.url).map((item) => {
          item.url = item.url && item.url[0] === "/" ? item.url.slice(1) : item.url;
          return item;
        });
        return {
          success: true,
          data: data.all_select_page.items
        };
      } else if (process.env.BRAND === "grassroots") {
        data.all_grassroots_page.items.filter((item) => item.url).map((item) => {
          item.url = item.url && item.url[0] === "/" ? item.url.slice(1) : item.url;
          return item;
        });
        return {
          success: true,
          data: data.all_grassroots_page.items
        };
      } else {
        throw new Error("Missing brand for buildContentPageSlugsQuery");
      }
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getAbandonedCartConfigs() {
    return request(
      `${this.baseUrl}/${this.apiKey}?environment=${this.environment}`,
      `query AbandonedCartsConfigQuery {
          all_abandoned_cart_configs {
            items {
              title
              carts_per_query
              max_abandonment_duration
              min_abandonment_duration
            }
          }
        }
      `,
      {},
      { access_token: this.token }
    ).then(
      ({
        all_abandoned_cart_configs
      }) => {
        if (all_abandoned_cart_configs.items.length) {
          return {
            success: true,
            data: all_abandoned_cart_configs.items[0]
          };
        } else {
          throw new Error("Missing configs in contentstack");
        }
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getContentPageComponents(slug, isPreview) {
    return fetch(
      `https://graphql.contentstack.com/stacks/${process.env.CONTENTSTACK_API_KEY}?environment=${process.env.CONTENTSTACK_ENVIRONMENT}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          access_token: process.env.CONTENTSTACK_ACCESS_TOKEN
        },
        body: JSON.stringify({
          query: GrassrootsPageComponentsTypeNameQuery,
          variables: {
            url: slug
          }
        }),
        next: { revalidate: 60 * 60 * 1e3 }
      }
    ).then(async (res) => {
      if (!res.ok) {
        throw new Error("Failed to fetch metadata");
      }
      return res.json();
    }).then(({ data }) => {
      if (!data.all_grassroots_page.items.length) {
        throw new Error("This page does not exist.");
      }
      const modularBlocks = data.all_grassroots_page.items[0].modular_blocks;
      const pageQuery = buildDynamicGrassrootsPageQuery(modularBlocks);
      return fetch(
        `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            access_token: isPreview ? this.previewToken : this.token
          },
          body: JSON.stringify({
            query: pageQuery,
            variables: {
              url: slug
            }
          }),
          next: { revalidate: 60 * 60 * 1e3 }
        }
      );
    }).then((res) => {
      if (!res.ok) {
        throw new Error("Failed to fetch metadata");
      }
      return res.json();
    }).catch((err) => console.error(err));
  }
  getContentPageMetadata(slug, isPreview) {
    return fetch(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          access_token: isPreview ? this.previewToken : this.token
        },
        body: JSON.stringify({
          query: GrassrootsSeoQuery,
          variables: {
            url: slug
          }
        }),
        next: { revalidate: 60 * 60 * 1e3 }
      }
    ).then(async (res) => {
      if (!res.ok) {
        throw new Error("Failed to fetch metadata");
      }
      return res.json();
    }).catch((err) => console.error(err));
  }
  getContentPageWebsiteData(isPreview) {
    return fetch(
      `${this.baseUrl}/${this.apiKey}?environment=${isPreview ? this.previewEnvironment : this.environment}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          access_token: isPreview ? this.previewToken : this.token
        },
        body: JSON.stringify({
          query: WebsiteQuery
        }),
        next: { revalidate: 60 * 60 * 1e3 }
      }
    ).then(async (res) => {
      if (!res.ok) {
        throw new Error("Failed to fetch website data");
      }
      return res.json();
    }).catch((err) => {
      console.error(err);
      return null;
    });
  }
};

// src/api/forms/braze.ts
import axios4 from "axios";
import axiosRetry from "axios-retry";
import moment from "moment";
var BrazeApi = class {
  constructor(instanceUrl, apiKey, appKey) {
    this.instanceUrl = instanceUrl;
    this.apiKey = apiKey;
    this.appKey = appKey;
    if (!instanceUrl || !instanceUrl.length || !apiKey || !apiKey.length || !appKey || !appKey.length)
      throw "Braze .env variables are missing!";
    const tempAxios = axios4.create({
      baseURL: this.instanceUrl,
      headers: { Authorization: `Bearer ${this.apiKey}` },
      validateStatus: () => true
    });
    axiosRetry(tempAxios, {
      retries: 3,
      retryCondition: () => true,
      retryDelay: (retryCount) => {
        return retryCount * 2e3;
      }
    });
    this.braze = tempAxios;
  }
  async submitIntakeForm(formData, customerIsCbd, eventName) {
    if (formData.optIn) {
      const userAlias = {
        alias_name: formData.email.toLowerCase().trim(),
        alias_label: "email"
      };
      const optInMethod = [];
      if (formData.preferredMethodOfCommunication) {
        if (formData.preferredMethodOfCommunication.email)
          optInMethod.push("Email");
        if (formData.preferredMethodOfCommunication.phone)
          optInMethod.push("Phone");
      }
      const attributes = {
        user_alias: userAlias,
        _update_existing_only: false,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        state: formData.state,
        phone: formData.phone,
        zipCode: formData.zipCode,
        optInMethod: void 0,
        prefCommMethod: optInMethod.sort().join(","),
        email_subscribe: formData.preferredMethodOfCommunication && formData.preferredMethodOfCommunication.email ? "opted_in" : void 0,
        medOrRec: formData.customerType === "Medical" ? "Med" : formData.customerType === "Recreational" ? "Rec" : formData.cardStatus,
        interested: formData.cardStatus,
        cbd_customer: customerIsCbd,
        howDidYouHearAboutUs: formData.hdyhau,
        dispensary: formData.dispensaryPreference,
        dob: formData.dob ? moment(formData.dob).format("YYYY-MM-DD") : void 0,
        birthMonth: formData.birthMonth
      };
      const events = [];
      const nowMoment = moment();
      const date = nowMoment.utc().format();
      events.push({
        user_alias: userAlias,
        app_id: this.appKey,
        name: eventName || "online-customer-intake",
        time: date
      });
      const trackReq = await this.userTrack([attributes], events, []);
      return {
        success: trackReq,
        message: trackReq ? "" : "Failed to create customer"
      };
    } else {
      return {
        success: false,
        message: "Please make sure you are opt-ing in."
      };
    }
  }
  async queryAliases(aliases) {
    const reqBody = {
      user_aliases: aliases
    };
    const axiosRequest = await this.braze.post("/users/export/ids", reqBody);
    return {
      success: axiosRequest.status === 201,
      data: axiosRequest.data
    };
  }
  async userTrack(attributes, events, purchases) {
    const reqBody = {
      attributes,
      events,
      purchases
    };
    const axiosRequest = await this.braze.post("/users/track", reqBody);
    return axiosRequest.status === 201;
  }
  async lookupCustomer(email) {
    const alias = {
      alias_label: "email",
      alias_name: email.toLowerCase()
    };
    const queriedUser = await this.queryAliases([alias]);
    const userMatch = queriedUser.data.users.find(
      (user) => user.email && user.email.toLowerCase() === email.toLowerCase()
    );
    return {
      success: !!userMatch,
      userData: userMatch
    };
  }
  async lookupCustomerByExternalId(id, email) {
    const reqBody = {
      external_ids: [id]
    };
    const axiosRequest = await this.braze.post("/users/export/ids", reqBody);
    const userMatch = axiosRequest.data.users.find(
      (user) => user.email && user.email.toLowerCase() === email.toLowerCase()
    );
    return {
      success: !!userMatch,
      userData: userMatch
    };
  }
  async editUser(formData) {
    const mappedForm = {
      external_id: formData.curaleaf_account_rid,
      _update_existing_only: false,
      first_name: formData.firstName,
      last_name: formData.lastName,
      ...formData
    };
    delete mappedForm.firstName;
    delete mappedForm.lastName;
    delete mappedForm.curaleaf_account_rid;
    Object.keys(mappedForm).map((key) => {
      const value = mappedForm[key];
      if (!value || typeof value === "string" && value === "") {
        delete mappedForm[key];
      }
    });
    const trackUser = await this.userTrack(
      [
        {
          external_id: formData.curaleaf_account_rid,
          _update_existing_only: false,
          ...mappedForm,
          prefCommMethod: mappedForm.prefCommMethod ? mappedForm.prefCommMethod.join(",") : void 0
        }
      ],
      [
        {
          external_id: formData.curaleaf_account_rid,
          app_id: this.appKey,
          name: "profileEdit",
          time: moment().toISOString()
        }
      ],
      []
    );
    return {
      success: trackUser,
      message: trackUser ? "" : "Failed To Track User"
    };
  }
  async checkHipaaStatus(email) {
    const exisitngRecords = await this.queryAliases([
      { alias_label: "email", alias_name: email.toLowerCase().trim() }
    ]);
    if (!exisitngRecords.success) {
      return { success: false, data: void 0 };
    }
    const match = exisitngRecords.data.users.find(
      (u) => u.email.toLowerCase().trim() === email.toLowerCase().trim()
    );
    if (!match) {
      return {
        success: false,
        data: void 0
      };
    }
    const hipaaSignedEvent = match.custom_events && match.custom_events.length && match.custom_events.find((event) => event.name === "hipaaFormSubmitted");
    return {
      success: true,
      data: {
        hipaaAcknowledged: match.custom_attributes.hipaaAcknowledged,
        hipaaSubmitted: !!hipaaSignedEvent,
        hipaaSubmittedDate: hipaaSignedEvent ? hipaaSignedEvent.last : void 0
      }
    };
  }
  async submitHipaaForm(formData) {
    const userAlias = {
      alias_name: formData.patient.email.toLowerCase().trim(),
      alias_label: "email"
    };
    const authorizedAgent = `${formData.agent.firstName} ${formData.agent.lastName}`.trim();
    const attributes = {
      user_alias: userAlias,
      _update_existing_only: false,
      hipaaFormData: JSON.stringify({
        acknowledge: formData.acknowledge,
        patient_email: `${formData.patient.email}`,
        patient_name: `${formData.patient.firstName} ${formData.patient.lastName}`,
        patient_relationship: formData.patientRelationship,
        agent_name: authorizedAgent,
        gaveSignature: formData.signature,
        date: formData.date,
        didNotAcknowledgeReason: formData.didNotAcknowledgeReason
      }),
      hipaaAcknowledged: formData.signature ? true : false,
      authorizedAgent: authorizedAgent.length ? authorizedAgent : void 0,
      first_name: formData.patient.firstName,
      last_name: formData.patient.lastName,
      email: formData.patient.email
    };
    const events = [];
    events.push({
      user_alias: userAlias,
      app_id: this.appKey,
      name: "hipaaFormSubmitted",
      time: formData.date
    });
    const existingRecords = await this.queryAliases([userAlias]);
    if (!existingRecords.success)
      return {
        success: false,
        message: "Error querying existing records."
      };
    const matchingRecord = existingRecords.data.users.find(
      (rec) => rec.email === formData.patient.email
    );
    if (!matchingRecord)
      attributes.email_subscribe = "unsubscribed";
    const trackReq = await this.userTrack([attributes], events, []);
    return {
      success: trackReq,
      message: trackReq ? "" : "Failed to create customer"
    };
  }
  async sendCampaignEmail(email, externalId, campaignId, extraProperties) {
    const sendReq = await this.braze.post("/campaigns/trigger/send", {
      campaign_id: campaignId,
      recipients: [
        {
          external_user_id: externalId,
          trigger_properties: extraProperties,
          send_to_existing_only: false,
          attributes: {
            email
          }
        }
      ]
    });
    return sendReq.status === 201;
  }
  async sendCanvasMessage(email, externalId, canvasId, extraProperties) {
    const sendReq = await this.braze.post("/canvas/trigger/send", {
      canvas_id: canvasId,
      recipients: [
        {
          external_user_id: externalId,
          canvas_entry_properties: extraProperties,
          send_to_existing_only: false,
          attributes: {
            email
          }
        }
      ]
    });
    return sendReq.status === 201;
  }
  async changeSubscriptionStatus(email, subscribe, emailVerificationStep) {
    let reqBody = {};
    if (emailVerificationStep) {
      reqBody = {
        email,
        subscription_state: subscribe ? "opted_in" : "subscribed"
      };
    } else {
      reqBody = {
        email,
        subscription_state: subscribe == "opted_in" ? "opted_in" : "unsubscribed"
      };
    }
    const axiosRequest = await this.braze.post("/email/status", reqBody).catch((err) => console.error(err));
    return axiosRequest.status === 201;
  }
};

// src/api/forms/leaflogix.ts
import axios5 from "axios";
var LeaflogixApi = class {
  constructor(apiKey) {
    this.baseUrl = process.env.LEAFLOGIX_BASEURL || "https://publicapi.leaflogix.net";
    const buf = Buffer.from(apiKey);
    this.encodedApiKey = "Basic " + buf.toString("base64");
  }
  async mergeOrCreate(firstName, lastName, email, phone, city, customerType, dob, medicalCard, zipCode) {
    const searchRes = await this.searchCustomer(email, phone);
    if (!searchRes.success)
      return { success: false, posCustomerId: 0 };
    const emailMatch = searchRes.data.filter(
      (match) => match.matchType === "Email"
    );
    const phoneMatch = searchRes.data.filter(
      (match) => match.matchType === "Phone"
    );
    const bothMatch = emailMatch.find(
      (match) => phoneMatch.find((pMatch) => match.customerId == pMatch.customerId)
    );
    const create = await this.createOrUpdateCustomer(
      email,
      firstName,
      lastName,
      city,
      customerType,
      dob,
      medicalCard,
      bothMatch ? bothMatch.customerId : void 0,
      zipCode,
      phone
    );
    return {
      success: true,
      posCustomerId: create.data.customerId
    };
  }
  async searchCustomer(email, phone) {
    const req = await axios5({
      method: "POST",
      baseURL: this.baseUrl,
      url: "customer/search",
      data: {
        emailAddress: email,
        phone
      },
      headers: {
        Authorization: this.encodedApiKey
      },
      validateStatus: () => true
    });
    return {
      success: req.status === 200,
      data: req.data ? req.data : []
    };
  }
  async createOrUpdateCustomer(email, firstName, lastName, city, customerType, dob, medicalCard, customerId, zipCode, phone) {
    const req = await axios5({
      method: "POST",
      baseURL: this.baseUrl,
      url: `customer/customer?bypassDeduplication=${customerId ? "true" : "false"}`,
      data: {
        customerId,
        firstName,
        lastName,
        emailAddress: email,
        status: "Active",
        city,
        customerType,
        dateOfBirth: dob,
        mmjidNumber: medicalCard,
        postalCode: zipCode,
        phone
      },
      headers: {
        Authorization: this.encodedApiKey
      },
      validateStatus: () => true
    });
    return {
      success: req.status === 200,
      data: req.data
    };
  }
};

// src/api/forms/springbig.ts
import axios6 from "axios";
var SPRINGBIG_API_KEY = "8nF3THTDZc2o74YkkTRFy5welQOUdmpx7D130LFL";
var SpringbigApi = class {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.springbig = axios6.create({
      baseURL: this.baseUrl,
      headers: {
        ["x-api-key"]: SPRINGBIG_API_KEY
      },
      validateStatus: () => true
    });
  }
  async createMember(member, authToken) {
    const result = await this.springbig.post(
      "/pos/v1/members",
      { member },
      {
        headers: {
          ["AUTH-TOKEN"]: authToken
        }
      }
    );
    return {
      success: result.status === 200,
      message: result.status === 200 ? "" : "Failed to create member in springbig."
    };
  }
};

// src/api/next/submitForm.ts
var contentstack = new ContentStackService();
var zendesk = new ZendeskService();
var badRequest = (res, message) => res.status(400).json({ success: false, message });
var internalServer = (res, message) => res.status(500).json({ success: false, message });
var emailBlast = axios7.create({
  baseURL: process.env.EMAIL_API_URL,
  headers: {
    "Content-Type": "application/json"
  },
  validateStatus: () => true
});
var submitForm = async (req, res) => {
  const {
    submitToPos,
    submitToCDP,
    customerIsCbd,
    dispensaryUid,
    formData,
    formUid = "",
    brazeInstance
  } = req.body;
  let eventName = "";
  let formBrazeInstance;
  if (formUid === "prerelease") {
    eventName = "DispensaryComingSoon";
  }
  if (brazeInstance) {
    const brazeInfo = await contentstack.getCrmCdp(brazeInstance, false);
    if (brazeInfo.success && Object.keys(brazeInfo).length) {
      formBrazeInstance = brazeInfo.data.braze;
    }
  }
  const additionalFormData = await contentstack.getEmbeddableForm(
    formUid,
    false
  );
  if (!additionalFormData && formUid !== "prerelease")
    return badRequest(res, "Could not retrieve form configuration.");
  const formConfig = additionalFormData.success && additionalFormData.data ? additionalFormData.data : null;
  if (formConfig) {
    eventName = formConfig.braze_custom_event_name;
    if (formConfig.type === "Contact Us") {
      try {
        if (formConfig.contact_us.submit_to_zendesk) {
          const zenmission = await zendesk.postTicket(formData, formConfig);
          if (!zenmission.success)
            console.error(zenmission.message);
        }
      } catch (err) {
      }
      try {
        const emailRes = await emailBlast.post("", {
          emailAddress: formConfig.contact_us.contact_us_email_destination && formConfig.contact_us.contact_us_email_destination.length ? formConfig.contact_us.contact_us_email_destination : process.env.CONTACT_EMAIL_DESTINATION,
          emailSubject: formConfig.contact_us.subject_line && formConfig.contact_us.subject_line.length ? formConfig.contact_us.subject_line : "[Contact Us Form Submission] from Curaleaf.com",
          emailBody: `
                    <p>
                        First Name: ${formData.firstName} <br>
                        Last Name: ${formData.lastName} <br>
                        Email Address: ${formData.email} <br>
                        ${formData.phone ? `Phone Number: ${formData.phone} <br>` : ""}
                        State: ${formData.state} <br>
                        Dispensary: ${formData.dispensaryPreference} <br>
                        Topic: ${formData.topic} <br>
                        Description: ${formData.topicDescription} <br>
                    </p>
                `
        });
        if (emailRes.status !== 202)
          return "Failed to send email";
      } catch (err) {
      }
    }
  }
  if (submitToPos && !(formData.customerType === "Medical" || formData.customerType === "Recreational" || formData.customerType === "Recreational - Out of State" || formData.customerType === "Medical - Out of State")) {
    return badRequest(
      res,
      'Invalid customerType. Acceptable values are "Medical" or "Recreational"'
    );
  }
  if (submitToPos) {
    if (!dispensaryUid) {
      return badRequest(
        res,
        "dispensaryUid is required when attempting to submit to the POS"
      );
    }
    const dispensaryReq = await contentstack.getADispensary(
      dispensaryUid,
      false,
      false
    );
    const matchingDispensaryReq = dispensaryReq.success ? dispensaryReq.data : null;
    if (!matchingDispensaryReq)
      return badRequest(res, "dispensaryUid not valid");
    const matchingDispensary = matchingDispensaryReq;
    if (!matchingDispensary.ll_api_key.length) {
      return internalServer(res, "POS API key not found");
    }
    const dispensarySync = await contentstack.getDispensarySync(
      matchingDispensary.unique_id
    );
    const autoDispensary = dispensarySync.success ? dispensarySync.data : null;
    const leaflogix = new LeaflogixApi(matchingDispensary.ll_api_key);
    await leaflogix.mergeOrCreate(
      formData.firstName,
      formData.lastName,
      formData.email,
      formData.phone,
      autoDispensary && autoDispensary.length ? autoDispensary[0].city : "unknown",
      formData.customerType,
      formData.dob,
      formData.medicalCard
    );
  }
  if (submitToCDP) {
    if ((additionalFormData == null ? void 0 : additionalFormData.data) && !formBrazeInstance) {
      formBrazeInstance = additionalFormData.data.target_crm_cdpConnection.edges[0].node.braze;
    }
    if (!formBrazeInstance || !formBrazeInstance.instance_url || !formBrazeInstance.api_key || !formBrazeInstance.app_key) {
      return internalServer(res, "CDP Config Missing");
    }
    const braze = new BrazeApi(
      formBrazeInstance.instance_url,
      formBrazeInstance.api_key,
      formBrazeInstance.app_key
    );
    if (formData.optIn) {
      await braze.submitIntakeForm(formData, customerIsCbd, eventName);
      if (formData.phone && formData.preferredMethodOfCommunication && formData.preferredMethodOfCommunication.phone) {
        if (!process.env.SPRINGBIG_CBD_AUTHTOKEN || !process.env.SPRINGBIG_DEFAULT_AUTHTOKEN || !process.env.SPRINGBIG_BASE_URL) {
          return internalServer(res, "CDP SMS key not configured");
        }
        const brazecustomer = await braze.lookupCustomer(formData.email);
        const stateReq = await contentstack.getStatesData(false, false);
        const states = stateReq.success ? stateReq.data : null;
        if (!states)
          return internalServer(res, "Could not query state data");
        const matchingState = states.find(
          (state) => state.title === formData.state
        );
        const authToken = customerIsCbd ? process.env.SPRINGBIG_CBD_AUTHTOKEN : matchingState && matchingState.spring_big_auth_token ? matchingState.spring_big_auth_token : process.env.SPRINGBIG_DEFAULT_AUTHTOKEN;
        const springbig = new SpringbigApi(process.env.SPRINGBIG_BASE_URL);
        await springbig.createMember(
          {
            pos_user: (brazecustomer == null ? void 0 : brazecustomer.userData) ? brazecustomer.userData.braze_id : "",
            pos_type: "Braze",
            phone_number: formData.phone,
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            birthday: formData.dob ? moment2(formData.dob).format("YYYY-MM-DD") : void 0,
            zip: formData.zipCode,
            purpose: formData.customerType ? formData.customerType === "Medical" || "Medical - Out of State" ? "medical" : "recreational" : void 0,
            interest_list: [],
            location_list: []
          },
          authToken
        );
      }
    }
  }
  return res.status(200).json({ success: true, message: "" });
};

// src/api/next/hipaa.ts
var hipaaStatusCheck = async (req, res) => {
  const brazeApi = new BrazeApi(
    process.env.BRAZE_INSTANCE_URL || "",
    process.env.BRAZE_API_KEY || "",
    process.env.BRAZE_APP_KEY || ""
  );
  const requestData = req.body;
  if (requestData.password !== process.env.PORTAL_PASSWORD) {
    return res.status(401).json({
      success: false,
      data: void 0
    });
  }
  const brazeResponse = await brazeApi.checkHipaaStatus(
    requestData.patient.email
  );
  return res.status(200).json(brazeResponse);
};
var hipaaPasswordCheck = async (req, res) => {
  const requestData = req.body;
  if (requestData.password === process.env.PORTAL_PASSWORD) {
    res.status(200).json({
      success: true
    });
  } else {
    res.status(401).json({
      success: false
    });
  }
};
var hipaaFormSubmit = async (req, res) => {
  const brazeApi = new BrazeApi(
    process.env.BRAZE_INSTANCE_URL || "",
    process.env.BRAZE_API_KEY || "",
    process.env.BRAZE_APP_KEY || ""
  );
  const requestData = req.body;
  const brazeResponse = await brazeApi.submitHipaaForm(requestData);
  return res.status(200).json({
    success: brazeResponse.success,
    message: brazeResponse.message
  });
};

// src/api/next/submitQuestion.ts
import axios8 from "axios";
var emailBlast2 = axios8.create({
  baseURL: process.env.EMAIL_API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});
var contentstack2 = new ContentStackService();
var submitQuestion = async (req, res) => {
  const { question, formUid } = req.body;
  const emailData = await contentstack2.getQuestionBlockEmailInfo(
    formUid,
    false
  );
  const emailInfo = emailData.success && emailData.data && emailData.data.length ? emailData.data[0] : null;
  if (emailInfo) {
    const emailRes = await emailBlast2.post("", {
      emailAddress: emailInfo.email_destination,
      emailSubject: emailInfo.email_subject,
      emailBody: `
              <h2>${emailInfo.email_subject}</h2>
              <p>
                  ${question}
              </p>
              `
    });
    if (emailRes.status > 200 && emailRes.status < 300) {
      res.status(200).json({ success: true });
    } else {
      res.status(500).json({
        success: false,
        error: "An unexpected error occured!"
      });
    }
  }
};

// src/api/customer-integration/customerIntegration.service.ts
import axios9 from "axios";
var _CustomerIntegration = class {
  static getLoyaltyUrl() {
    return `${_CustomerIntegration.loyaltyBaseUrl}`;
  }
  static async fetchMemberTransactions(user) {
    return axios9.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/FetchMemberTransactions`,
      user
    );
  }
  static async addCustomer(user) {
    return axios9.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/AddCustomer`,
      user
    );
  }
  static async addCustomerToLoyalty(user) {
    return axios9.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/AddCustomerToLoyalty`,
      user
    );
  }
  static async fetchMemberRankingStatus(user) {
    return axios9.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/FetchMemberRankingSetStatus`,
      user
    );
  }
  static async fetchMemberPointBalances(user) {
    return axios9.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/FetchMemberPointBalances`,
      user
    );
  }
  static async deleteFromCustomerIntegration({
    customerId
  }) {
    return axios9.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/DeleteCustomer`,
      { id: customerId }
    );
  }
  static async getCustomerIntegrationId(user) {
    return axios9.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/GetCuraleafId`,
      user
    );
  }
  static async processReferredBy(referrerMemberAccountId, referreeMemberAccountId) {
    return axios9.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/ProcessReferredBy`,
      {
        referrerMemberAccountId,
        referreeMemberAccountId
      }
    );
  }
  static async issueInteractionUserDefined(memberAccountId, interactionTypeExternalReference, locationExternalReference = "CuraleafOnline", interactionNote = "Added from the curaleaf graph programmatically.") {
    return axios9.post(
      `${_CustomerIntegration.getLoyaltyUrl()}/IssueInteractionUserDefined`,
      {
        memberAccountId,
        interactionTypeExternalReference,
        locationExternalReference,
        interactionNote
      }
    );
  }
};
var CustomerIntegration = _CustomerIntegration;
CustomerIntegration.loyaltyBaseUrl = process.env.LOYALTY_BASE_URL;

// src/api/contentstack/genericContentstack.ts
import axios10 from "axios";
var contentstack3 = axios10.create({
  baseURL: "https://api.contentstack.io/v3/content_types/",
  headers: {
    api_key: process.env.CONTENTSTACK_API_KEY,
    authorization: process.env.CONTENTSTACK_MANAGEMENT_TOKEN,
    "Content-Type": "application/json"
  }
});
var getCSEntries = async (entryKey) => {
  const pageSize = 100;
  const originalRequest = await contentstack3(
    `/${entryKey}/entries?locale=en-us&include_workflow=true&include_publish_details=false&include_count=true&skip=${0}&limit=${pageSize}`
  );
  const entries = [...originalRequest.data.entries];
  const count = originalRequest.data.count;
  const callCount = Math.ceil(count / pageSize);
  for (let i = 1; i < callCount; i++) {
    const pageRequest = await contentstack3(
      `${entryKey}/entries?locale=en-us&include_workflow=true&include_publish_details=false&skip=${i * pageSize}&limit=${pageSize}`
    );
    entries.push(...pageRequest.data.entries);
  }
  return entries;
};
var getCSEntry = async (entryKey, query) => {
  const csRequest = await contentstack3(
    `/${entryKey}/entries?locale=en-us&query=${JSON.stringify(query)}`
  );
  const entry = csRequest.data.entries;
  return entry;
};

// src/api/strapi/b2c-strapi.service.ts
import axios11 from "axios";
import qs from "qs";
var B2cStrapiService = class {
  constructor() {
    this.strapi = axios11.create({
      baseURL: process.env.B2C_STRAPI_URL,
      headers: {
        Authorization: process.env.B2C_STRAPI_AUTHORIZATION
      }
    });
  }
  filteredData(slug, state) {
    return qs.stringify(
      {
        populate: {
          Dispensaries: {
            filters: {
              Slug: {
                $eq: slug
              }
            }
          },
          States: {
            filters: {
              Title: {
                $eq: state
              }
            }
          },
          Banners: {
            populate: "*"
          }
        },
        filters: {
          $or: [
            {
              Dispensaries: {
                Slug: {
                  $eq: slug
                }
              }
            },
            {
              States: {
                Title: {
                  $eq: state
                }
              }
            }
          ]
        }
      },
      {
        encodeValuesOnly: true
      }
    );
  }
  getAlerts(slug, state) {
    return this.strapi.get(`/api/alerts?${this.filteredData(slug, state)}`).then(
      ({
        data
      }) => ({
        success: true,
        data
      })
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getStorefrontBanners(slug, state) {
    return this.strapi.get(`/api/storefront-banners?${this.filteredData(slug, state)}`).then(
      ({
        data
      }) => {
        const banners = [];
        for (const banner of data.data) {
          banners.push(...banner.attributes.Banners);
        }
        return {
          success: true,
          data: banners
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
};

// src/api/braze/braze.service.ts
import axios12 from "axios";
var _Braze = class {
  static async sendTestPushNotification(externalUserIds) {
    return axios12({
      method: "post",
      url: `${_Braze.APP_URL}/messages/send`,
      headers: {
        Authorization: `Bearer ${_Braze.API_KEY}`,
        "Content-Type": "application/json"
      },
      data: {
        messages: {
          apple_push: {
            alert: `Test push notification from ${process.env.NODE_ENV} Curaleaf Graph API.`
          }
        },
        external_user_ids: externalUserIds
      }
    });
  }
};
var Braze = _Braze;
Braze.API_KEY = process.env.BRAZE_API_KEY;
Braze.APP_URL = process.env.BRAZE_INSTANCE_URL;

// src/api/forms/submit.ts
import axios13 from "axios";
var SubmitFormToAPI = async (formData, type, dispensary) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  if (!type)
    console.warn("Error: Missing arguments for this forms!");
  let data = {
    formData: {
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      email: formData.email.trim(),
      optIn: !!formData.optIn
    },
    submitToPos: false,
    submitToCDP: !!formData.optIn,
    customerIsCbd: false
  };
  if (type === "Contact Us") {
    const form = formData;
    data = {
      ...data,
      formData: {
        ...data.formData,
        dispensaryPreference: ((_a = form.dispensary) == null ? void 0 : _a.length) ? form.dispensary : void 0,
        state: ((_b = form.state) == null ? void 0 : _b.length) ? form.state : void 0,
        topic: form.topic,
        topicDescription: form.topicDescription,
        phone: ((_c = form.phone) == null ? void 0 : _c.length) ? form.phone.trim() : void 0
      },
      formUid: form.formUid,
      brazeInstance: form.brazeInstance
    };
  } else if (type === "Customer Intake") {
    const form = formData;
    data = {
      ...data,
      formData: {
        ...data.formData,
        birthday: form.birthday && form.birthday instanceof Date ? form.birthday.toUTCString() : void 0,
        bypassMedicalCard: !!form.bypassMedicalCard,
        customerType: form.customerType ? form.customerType : "",
        dispensaryPreference: form.dispensary,
        hdyhau: form.hearAboutUs,
        medicalCard: form.customerType === "Medical" && form.medicalCard ? form.medicalCard : void 0,
        phone: ((_d = form.phone) == null ? void 0 : _d.length) ? form.phone.trim() : void 0,
        preferredMethodOfCommunication: {
          phone: form.optInMethod.includes("Text"),
          email: form.optInMethod.includes("Email")
        },
        state: form.state,
        zipCode: ((_e = form.zipcode) == null ? void 0 : _e.length) ? form.zipcode.trim() : void 0
      },
      brazeInstance: "Curaleaf",
      submitToPos: true,
      dispensaryUid: dispensary == null ? void 0 : dispensary.uid
    };
  } else if (type === "Opt In") {
    const form = formData;
    data = {
      ...data,
      formData: {
        ...data.formData,
        birthMonth: form.birthMonth,
        cardStatus: ((_f = form.cardStatus) == null ? void 0 : _f.length) ? form.cardStatus : void 0,
        dispensaryPreference: ((_g = form.dispensary) == null ? void 0 : _g.length) ? form.dispensary : void 0,
        hdyhau: form.hearAboutUs,
        phone: ((_h = form.phone) == null ? void 0 : _h.length) ? form.phone.trim() : void 0,
        preferredMethodOfCommunication: {
          phone: form.optInMethod.includes("Text"),
          email: form.optInMethod.includes("Email")
        },
        state: form.state
      },
      formUid: form.formUid,
      brazeInstance: form.brazeInstance
    };
  } else {
    const form = formData;
    data = {
      ...data,
      formData: {
        ...data.formData,
        cardStatus: form.cardStatus,
        phone: ((_i = form.phone) == null ? void 0 : _i.length) ? form.phone.trim() : void 0,
        preferredMethodOfCommunication: {
          phone: form.optInMethod.includes("Text"),
          email: form.optInMethod.includes("Email")
        }
      },
      formUid: "prerelease",
      brazeInstance: "Curaleaf"
    };
  }
  await axios13.post("/api/form/submit", data);
};

// src/api/forms/question.ts
import axios14 from "axios";
var SubmitQuestionToAPI = async (text, uid) => {
  return await axios14.post("/api/submitQuestion", {
    question: text,
    formUid: uid
  });
};

// src/api/forms/submitHipaa.ts
import axios15 from "axios";
import moment3 from "moment";
var SubmitHipaaFormToAPI = async (formData) => {
  const now = moment3();
  const date = now.utc().format();
  const data = {
    acknowledge: formData.acknowledge === "Acknowledge",
    didNotAcknowledgeReason: formData.acknowledge === "Do Not Acknowledge" ? formData.didNotAcknowledgeReason : void 0,
    patient: {
      email: formData.patientEmail,
      firstName: formData.patientFirstName,
      lastName: formData.patientLastName
    },
    patientRelationship: formData.acknowledge === "Acknowledge" && formData.patientRelationship ? formData.patientRelationship : "Self",
    agent: {
      firstName: formData.agentFirstName,
      lastName: formData.agentLastName
    },
    signature: formData.acknowledge === "Acknowledge" ? formData.signature : void 0,
    date
  };
  await axios15.post("/api/form/submitHipaa", data);
};

// src/api/dutchiePlus/dutchiePlus.service.ts
import request2 from "graphql-request";

// src/model/query/dutchiePlus.fragments.ts
var productFields = `
brand {
  id
  name
  description
  imageUrl
}
category
description
descriptionHtml
effects
id
image
images {
  id
  url
  label
  description
}
name
posId
productBatchId
potencyCbd {
  formatted
  range
  unit
}
potencyThc {
  formatted
  range
  unit
}
staffPick
strainType
subcategory
variants {
  id
  option
  priceMed
  priceRec
  specialPriceMed
  specialPriceRec
  quantity
  flowerEquivalent {
    unit
    value
  }
}
terpenes {
  id
  terpene {
      aliasList
      aromas
      description
      effects
      id
      name
      potentialHealthBenefits
      unitSymbol
  }
  name
  terpeneId
  unit
  unitSymbol
  value
}
cannabinoids {
  cannabinoidId
  unit
  value
  cannabinoid {
      description
      id
      name
  }
}
`;
var productFragment = `
    fragment productFragment on Product {
      ${productFields}
    }`;
var checkoutFragment = `
    address {
        street1
        street2
        city
        state
        zip
        formatted
        geometry {
            coordinates
            type
        }
        deliverable
        valid
    }
    id
    orderType
    pricingType
    items {
        id
        option
        isDiscounted
        basePrice
        taxes {
            total
            cannabis
            sales
        }
        discounts {
          total  
        }
        productId
        product {
            brand {
                id
                name
            }
            category
            description
            descriptionHtml
            effects
            id
            image
            name
            posId
            potencyCbd {
                formatted
                range
                unit
            }
            potencyThc {
                formatted
                range
                unit
            }
            staffPick
            strainType
            subcategory
            variants {
                id
                option
                priceMed
                priceRec
                specialPriceMed
                specialPriceRec
                quantity
                flowerEquivalent {
                  unit
                  value
                }
            }
            terpenes {
                id
                terpene {
                    aliasList
                    aromas
                    description
                    effects
                    id
                    name
                    potentialHealthBenefits
                    unitSymbol
                }
                name
                terpeneId
                unit
                unitSymbol
                value
            }
            cannabinoids {
                cannabinoidId
                unit
                value
                cannabinoid {
                    description
                    id
                    name
                }
            }
        }
        quantity
        valid
    }
    priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
    }
    redirectUrl
    `;
var orderFragment = `
delivery
customer {
  email
}
createdAt
discounts {
  total
}
dispensaryName
foreignId
id
items {
  product {
    ${productFields}
  }
  option
  price
  productId
  quantity
  subtotal
}
medical
orderNumber
pickup
recreational
reservationDate {
  endTime
  startTime
}
status
subtotal
tax
total
`;
var CART_PRODUCT = `
  fragment cart on Product {
    brand {
      name
    }
    category {
      displayName
    }
    id
    images {
      url
    }
    name
    strain {
      key
    }
    subcategory {
      displayName
    }
    variants {
      id
      isSpecial
      option
      price
      quantity
      specialPrice
    }
  }
`;
var ORDER_PRODUCT = `
  fragment order on Product {
    brand {
      name
    }
    category {
      displayName
    }
    id
    name
    images {
      id
      url
    }
    cardDescription
    strain {
      key
    }
    variants {
      id
      isSpecial
      option
      price
      specialPrice
    }
  }
`;
var TILE_PRODUCT = `
  fragment tile on Product {
    brand {
      name
    }
    cardDescription
    id
    images {
      url
    }
    name
    strain {
      key
    }
    variants {
      id
      isSpecial
      option
      price
      quantity
      specialPrice
    }
  }
`;
var FULL_PRODUCT = `
  fragment full on Product {
    brand {
      description
      id
      image {
        url
      }
      name
      slug
    }
    category {
      displayName
      key
    }
    descriptionHtml
    effects {
      displayName
    }
    id
    images {
      id
      url
    }
    labResults {
      cannabinoids {
        cannabinoid {
          description
          name
        }
        unit
        value
      }
      terpenes {
        terpene {
          description
          name
        }
        unitSymbol
        value
      }
      thc {
        formatted
        range
      }
    }
    name
    offers {
      description
      id
      title
    }
    strain {
      key
      displayName
    }
    subcategory {
      key
      displayName
    }
    variants {
      flowerEquivalent {
        unit
        value
      }
      id
      isSpecial
      option
      price
      quantity
      specialPrice
    }
  }
`;
var GRID_PRODUCT = `
  fragment grid on Product {
    brand {
      description
      id
      image {
        url
      }
      name
      slug
    }
    cardDescription
    category {
      displayName
      key
    }
    id
    images {
      url
    }
    labResults {
      thc {
        formatted
        range
      }
    }
    name
    offers {
      id
      title
    }
    strain {
      key
      displayName
    }
    subcategory {
      key
      displayName
    }
    variants {
      id
      isSpecial
      option
      price
      quantity
      specialPrice
    }
  }
`;

// src/model/query/graph/categoryProductsQuery.ts
var CustomQuery = () => `
  ${productFragment}
  query fetchCustomProducts (
      $retailerId: ID!
      $menuType: MenuType!
      $category: MenuSectionFilterType!
      $listName: String
    ) {
        menu(
          retailerId: $retailerId,
          menuType: $menuType,
          pagination: { offset: 0, limit: 50},
          filter: { menuSection: { type: $category, name: $listName }},
          sort: { direction: DESC, key: POPULAR }
          ) {
        products {
          ...productFragment
        }
      }
    }
`;
var TagQuery = () => `
  ${productFragment}
  query fetchTagProducts (
      $retailerId: ID!
      $menuType: MenuType!
      $category: String
    ) {
        menu(
          retailerId: $retailerId,
          menuType: $menuType,
          pagination: { offset: 0, limit: 50},
          filter: {  tags: [$category] },
          sort: { direction: DESC, key: POPULAR }
          ) {
        products {
          ...productFragment
        }
      }
    }
`;

// src/api/dutchiePlus/dutchiePlus.service.ts
var DutchiePlusService = class {
  constructor() {
    this.baseUrl = process.env.DUTCHIE_PLUS_URL;
    this.requestHeaders = {
      authorization: `Bearer public-${process.env.DUTCHIE_PLUS_PUBLIC_TOKEN}`
    };
    this.getRetailer = (retailerId) => {
      const params = {
        retailerId
      };
      const query = `
      fragment hoursDayFragment on HoursDay {
        active
        start
        end
      }
      
      fragment hoursFragment on Hours {
        Sunday {
          ...hoursDayFragment
        }
        Monday {
          ...hoursDayFragment
        }
        Tuesday {
          ...hoursDayFragment
        }
        Wednesday {
          ...hoursDayFragment
        }
        Thursday {
          ...hoursDayFragment
        }
        Friday {
          ...hoursDayFragment
        }
        Saturday {
          ...hoursDayFragment
        }
      }
      
      fragment retailerFragment on Retailer {
        address
        banner {
          html
        }
      #   categoryLimits {
      #       name
      #       value
      #   }
        coordinates {
            latitude
            longitude
        }
        deliverySettings {
          afterHoursOrderingForDelivery
          afterHoursOrderingForPickup
          deliveryArea
          deliveryFee
          deliveryMinimum
          disablePurchaseLimits
          limitPerCustomer
          pickupMinimum
          scheduledOrderingForDelivery
          scheduledOrderingForPickup
        }
      #   description
        fulfillmentOptions {
        curbsidePickup
          delivery
          driveThruPickup
          pickup
        }
        hours {
          delivery {
            ...hoursFragment
          }
          pickup {
            ...hoursFragment
          }
          regular {
              ...hoursFragment
          }
          special {
            startDate
            endDate
            hoursPerDay {
              date
              deliveryHours {
                ...hoursDayFragment
              }
              pickupHours {
                ...hoursDayFragment
              }
            }
            name
          }
        }
        id
        menuTypes
        name
        paymentOptions {
          aeropay
          alt36
          canPay
          cashless
          cashOnly
          check
          creditCard
          creditCardAtDoor
          creditCardByPhone
          debitOnly
          hypur
          linx
          merrco
          payInStore
          paytender
        }
        settings {
            menuWeights
        }
      }
      
      query Retailer ($retailerId: ID!) {
        retailer(id: $retailerId) {
          ...retailerFragment
        }
      }`;
      return request2(this.baseUrl, query, params, this.requestHeaders).then(
        ({
          retailer
        }) => {
          return {
            success: true,
            data: retailer
          };
        }
      ).catch((err) => {
        return {
          success: false,
          errorMessage: err
        };
      });
    };
    this.getRetailers = () => {
      const query = `
      fragment hoursDayFragment on HoursDay {
        active
        start
        end
      }
      
      fragment hoursFragment on Hours {
        Sunday {
          ...hoursDayFragment
        }
        Monday {
          ...hoursDayFragment
        }
        Tuesday {
          ...hoursDayFragment
        }
        Wednesday {
          ...hoursDayFragment
        }
        Thursday {
          ...hoursDayFragment
        }
        Friday {
          ...hoursDayFragment
        }
        Saturday {
          ...hoursDayFragment
        }
      }
      
      fragment retailerFragment on Retailer {
        address
        banner {
          html
        }
        coordinates {
            latitude
            longitude
        }
        deliverySettings {
          afterHoursOrderingForDelivery
          afterHoursOrderingForPickup
          deliveryArea
          deliveryFee
          deliveryMinimum
          disablePurchaseLimits
          limitPerCustomer
          pickupMinimum
          scheduledOrderingForDelivery
          scheduledOrderingForPickup
        }
        fulfillmentOptions {
        curbsidePickup
          delivery
          driveThruPickup
          pickup
        }
        hours {
          delivery {
            ...hoursFragment
          }
          pickup {
            ...hoursFragment
          }
          regular {
              ...hoursFragment
          }
          special {
            startDate
            endDate
            hoursPerDay {
              date
              deliveryHours {
                ...hoursDayFragment
              }
              pickupHours {
                ...hoursDayFragment
              }
            }
            name
          }
        }
        id
        menuTypes
        name
        paymentOptions {
          aeropay
          alt36
          canPay
          cashless
          cashOnly
          check
          creditCard
          creditCardAtDoor
          creditCardByPhone
          debitOnly
          hypur
          linx
          merrco
          payInStore
          paytender
        }
        settings {
            menuWeights
        }
      }
      
      query Retailer {
        retailers {
          ...retailerFragment
        }
      }`;
      return request2(this.baseUrl, query, {}, this.requestHeaders).then(
        ({
          retailers
        }) => {
          return {
            success: true,
            data: retailers
          };
        }
      ).catch((err) => {
        return {
          success: false,
          errorMessage: err
        };
      });
    };
  }
  async getProductsForRetailerMenu(retailerId, menuType, options = {}) {
    const { excludeCategory } = options;
    const defaultFilters = [
      "ACCESSORIES" /* Accessories */,
      "APPAREL" /* Apparel */,
      "CBD" /* Cbd */,
      "CLONES" /* Clones */,
      "CONCENTRATES" /* Concentrates */,
      "EDIBLES" /* Edibles */,
      "FLOWER" /* Flower */,
      "NOT_APPLICABLE" /* NotApplicable */,
      "ORALS" /* Orals */,
      "PRE_ROLLS" /* PreRolls */,
      "SEEDS" /* Seeds */,
      "TINCTURES" /* Tinctures */,
      "TOPICALS" /* Topicals */,
      "VAPORIZERS" /* Vaporizers */
    ];
    const isExclusionQuery = (excludeCategory || []).length > 0;
    const generateMenuQuery = (categories = null) => {
      if (isExclusionQuery && categories) {
        return categories.map((category) => {
          const filter = `filter: { category: ${category} }`;
          return `
            ${category}: menu(
              retailerId: $retailerId,
              menuType: $menuType,
              pagination: { offset: 0, limit: 2000 },
              sort: { direction: DESC, key: POPULAR },
              ${filter}
            ){
              products {
                ...productFragment
              }
            }
          `;
        }).join("\n");
      } else {
        const filter = "";
        return `
          menu(
            retailerId: $retailerId,
            menuType: $menuType,
            pagination: { offset: 0, limit: 2000 },
            sort: { direction: DESC, key: POPULAR }
            ${filter}
          ){
            products {
              ...productFragment
            }
          }
        `;
      }
    };
    const generateQuery = (menuQuery) => `
    ${productFragment}

    query fetchProductsForRetailer (
        $retailerId: ID!
        $menuType: MenuType!
    ) {
        ${menuQuery}
    }`;
    let query = "";
    let categoryFilter = [...defaultFilters];
    if (isExclusionQuery) {
      categoryFilter = defaultFilters.filter(
        (category) => !(excludeCategory == null ? void 0 : excludeCategory.includes(category))
      );
      query = generateQuery(generateMenuQuery(categoryFilter));
    } else {
      query = generateQuery(generateMenuQuery());
    }
    const params = {
      retailerId,
      menuType
    };
    try {
      const response = await request2(this.baseUrl, query, params, this.requestHeaders);
      const products = Object.keys(response).reduce(
        (acc, key) => [...acc, ...response[key].products],
        []
      );
      return {
        success: true,
        data: products
      };
    } catch (err) {
      return {
        success: false,
        errorMessage: err
      };
    }
  }
  getProductsForSpecial(retailerId, specialId) {
    const params = {
      retailerId,
      specialId
    };
    const query = `
    ${productFragment}
    
    query SpecialProducts(
        $retailerId: ID!
        $specialId: [String]!
    ) {
      menu(
          retailerId: $retailerId,
          pagination: { offset: 0, limit: 2000 },
          filter: { menuSection: { type: SPECIALS, specialId: $specialId }}
      ){
        products {
          ...productFragment
        }
      }
    }`;
    return request2(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        menu
      }) => {
        return {
          success: true,
          data: menu.products
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  getSpecialsForRetailer(retailerId) {
    const params = {
      retailerId
    };
    const query = `
    query SpecialsQuery ($retailerId: ID!) {
      specials (retailerId: $retailerId) {
        id
        name
        type
        redemptionLimit
        menuType
        emailConfiguration {
          description
          descriptionHtml
          subject
          heading
          enabled
        }
        scheduleConfiguration {
          startStamp
          endStamp
          days
          startTime
          endTime
          setEndDate
          endDate
        }
        menuDisplayConfiguration {
          name
          description
          image
        }
      }
    }`;
    return request2(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        specials
      }) => {
        return {
          success: true,
          data: specials
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  createCheckout(retailerId, orderType, pricingType) {
    const params = {
      retailerId,
      orderType,
      pricingType
    };
    const query = `
      mutation CreateCheckout(
        $retailerId: ID!
        $address: CheckoutAddressInput
        $orderType: OrderType!
        $pricingType: PricingType!
      ) {
        createCheckout(
          retailerId: $retailerId
          address: $address
          orderType: $orderType
          pricingType: $pricingType
        ) {
          ${checkoutFragment}
        }
      }`;
    return request2(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        createCheckout
      }) => {
        return {
          success: true,
          data: createCheckout
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  getCheckout(retailerId, checkoutId) {
    const params = {
      retailerId,
      checkoutId
    };
    const query = `
      query Checkout(
        $retailerId: ID!
        $checkoutId: ID!
      ) {
        checkout(
            retailerId: $retailerId
            id: $checkoutId
          ) {
          ${checkoutFragment}
        }
      }`;
    return request2(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        checkout
      }) => {
        return {
          success: true,
          data: checkout
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  addItemToCheckout(retailerId, checkoutId, productId, quantity, option) {
    const params = {
      retailerId,
      checkoutId,
      productId,
      quantity,
      option
    };
    const query = `
      mutation AddItemToCheckout(
        $retailerId: ID!
        $checkoutId: ID!
        $productId: ID!
        $quantity: Int!
        $option: String!
      ) {
        addItem(
          retailerId: $retailerId
          checkoutId: $checkoutId
          productId: $productId
          quantity: $quantity
          option: $option
        ) {
          ${checkoutFragment}
        }
      }`;
    return request2(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        addItem
      }) => {
        return {
          success: true,
          data: addItem
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  removeItemFromCheckout(retailerId, checkoutId, itemId) {
    const params = {
      retailerId,
      checkoutId,
      itemId
    };
    const query = `
      mutation RemoveItemFromCheckout( 
        $retailerId: ID!
        $checkoutId: ID!
        $itemId: ID!
      ) {
        removeItem(
          retailerId: $retailerId
          checkoutId: $checkoutId
          itemId: $itemId
        ) {
          ${checkoutFragment}
        }
      }`;
    return request2(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        removeItem
      }) => {
        return {
          success: true,
          data: removeItem
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  async addToCart(retailerId, orderType, pricingType, productId, quantity, option, checkoutId) {
    if (checkoutId) {
      return this.addItemToCheckout(
        retailerId,
        checkoutId,
        productId,
        quantity,
        option
      );
    } else {
      const checkout = await this.createCheckout(
        retailerId,
        orderType,
        pricingType
      );
      if (checkout.success) {
        window.localStorage.setItem(
          "checkoutId",
          JSON.stringify(checkout.data.id)
        );
        return this.addItemToCheckout(
          retailerId,
          checkout.data.id,
          productId,
          quantity,
          option
        );
      } else {
        return checkout;
      }
    }
  }
  async addToCartGraph(retailerId, orderType, pricingType, productId, quantity, option, checkoutId) {
    if (checkoutId) {
      return this.addItemToCheckout(
        retailerId,
        checkoutId,
        productId,
        quantity,
        option
      );
    } else {
      const checkout = await this.createCheckout(
        retailerId,
        orderType,
        pricingType
      );
      if (checkout.success) {
        return this.addItemToCheckout(
          retailerId,
          checkout.data.id,
          productId,
          quantity,
          option
        );
      } else {
        return checkout;
      }
    }
  }
  updateCheckout(retailerId, checkoutId, orderType, pricingType) {
    const params = {
      retailerId,
      checkoutId,
      orderType,
      pricingType
    };
    const query = `
    mutation UpdateCheckout(
      $retailerId: ID!
      $checkoutId: ID!
      $orderType: OrderType!
      $pricingType: PricingType!
    ) {
      updateCheckout(checkoutId: $checkoutId, retailerId: $retailerId, orderType: $orderType, pricingType: $pricingType) {
        ${checkoutFragment}
      }
    }`;
    return request2(this.baseUrl, query, params, this.requestHeaders).then(
      ({
        updateCheckout
      }) => {
        return {
          success: true,
          data: updateCheckout
        };
      }
    ).catch((err) => {
      return {
        success: false,
        errorMessage: err
      };
    });
  }
  async getOrderByOrderNumber(retailerId, orderNumber) {
    const params = {
      retailerId,
      orderNumber
    };
    const query = `
      query Orders(
        $retailerId: ID!
        $orderNumber: String!
      ) {
        orders(
            retailerId: $retailerId
            filter: { orderNumber: $orderNumber }
          ) {
          ${orderFragment}
        }
      }`;
    try {
      const response = await request2(this.baseUrl, query, params, {
        ...this.requestHeaders,
        authorization: `Bearer ${process.env.DUTCHIE_PLUS_PRIVATE_TOKEN}`
      });
      return {
        success: true,
        data: response.orders[0]
      };
    } catch (err) {
      return {
        success: false,
        errorMessage: err
      };
    }
  }
  getCustomListProducts(retailerId, menuType, category, type, listName = "") {
    const params = {
      retailerId,
      menuType,
      category,
      listName
    };
    let query = CustomQuery();
    if (type === "tag")
      query = TagQuery();
    return request2(this.baseUrl, query, params, this.requestHeaders).then(({ menu }) => {
      return {
        success: true,
        data: menu.products
      };
    }).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
  async getSpecialsWithProducts(retailerId, menuType) {
    const specialsReq = await this.getSpecialsForRetailer(retailerId);
    if (!specialsReq.success || !specialsReq.data.length)
      return specialsReq;
    const specials = specialsReq.data;
    const params = {
      retailerId,
      menuType
    };
    const query = `
        query SpecialProducts(
            $retailerId: ID!
            $menuType: MenuType 
          ) {
          ${specials.map(
      (special) => `_${special.id}:menu(
                  retailerId: $retailerId,
                  menuType: $menuType,
                  pagination: { offset: 0, limit: 2000 },
                  filter: { menuSection: { type: SPECIALS, specialId: ["${special.id}"] }},
                  sort: { direction: DESC, key: POPULAR }
                ){
                  products {
                    id
                  }
                }
              `
    )}
        }
        `;
    return request2(this.baseUrl, query, params, this.requestHeaders).then(
      (data) => {
        const validSpecials = [];
        for (const key in data) {
          const match = specials.find((s) => `_${s.id}` === key);
          if (match) {
            validSpecials.push({
              ...match,
              products: data[key].products.map(
                (p) => p.id
              )
            });
          }
        }
        return {
          success: true,
          data: validSpecials.filter((special) => special.products.length)
        };
      }
    ).catch(
      (err) => ({
        success: false,
        errorMessage: err
      })
    );
  }
};

// src/api/ecommerce/cosmo.ts
import { CosmosClient } from "@azure/cosmos";
var CosmosDB = class {
  constructor(connectionString, databaseId, containerId) {
    this.databaseId = databaseId;
    this.containerId = containerId;
    this.db = new CosmosClient(connectionString);
  }
  async query(query) {
    const cosmoRes = await this.db.database(this.databaseId).container(this.containerId).items.query(query).fetchAll();
    return [!!cosmoRes.resources, cosmoRes.resources];
  }
  async updateItem(item) {
    return this.db.database(this.databaseId).container(this.containerId).item(item.id).replace(item).then((succ) => [true, succ]).catch((err) => [false, err]);
  }
  async insertItem(item) {
    return this.db.database(this.databaseId).container(this.containerId).items.upsert(item).then((succ) => [true, succ]).catch((err) => [false, err]);
  }
};

// src/api/physicians/physicians.ts
import { CosmosClient as CosmosClient2 } from "@azure/cosmos";
var getFloridaPhysicians = async () => {
  let custom = null;
  const client = new CosmosClient2(process.env.FLORIDA_PHYSICIANS_DB);
  const physiciansDbCall = await client.database("PhysicianDB").container("Florida").items.query({
    query: "SELECT * FROM c",
    parameters: []
  }).fetchAll();
  const physicians = physiciansDbCall.resources;
  custom = physicians.map((p) => {
    const search = p.displayName + p.firstName + p.lastName + p.website + p.addressLine1 + p.addressLine2 + p.zipCode + p.phone1;
    return {
      ...p,
      search: search.toLowerCase()
    };
  });
  return custom;
};

// src/hook/analytics/analytics.ts
var Analytics = class {
  constructor() {
    this.metaData = {};
  }
  calculateDiscount(productVariant) {
    return productVariant && productVariant.isSpecial && productVariant.price && productVariant.specialPrice ? productVariant.price - productVariant.specialPrice : 0;
  }
  pushEvent(event) {
    if (typeof window === "undefined")
      return;
    const windowRef = window;
    windowRef.dataLayer = window.dataLayer || [];
    windowRef.dataLayer.push(event);
  }
  datalayerPush(obj) {
    if (typeof window === "undefined")
      return;
    const windowRef = window;
    windowRef.dataLayer = window.dataLayer || [];
    windowRef.dataLayer.push(obj);
  }
  onCheckout(dispensaryName, cart) {
    try {
      this.pushEvent({
        event: "checkout",
        m: "v1",
        actionField: { step: 1, option: "start_checkout" },
        ecommerce: {
          affiliation: dispensaryName,
          checkout: {
            actionField: {},
            items: cart.items.map((item) => {
              var _a, _b, _c;
              const selectedVariant = item.product.variants.find(
                (v) => v.option === item.option
              );
              return {
                item_id: selectedVariant ? selectedVariant.id : "",
                item_name: item.product.name,
                item_category: (_a = item.product.category) == null ? void 0 : _a.displayName,
                item_category_2: (_b = item.product.subcategory) == null ? void 0 : _b.displayName,
                item_variant: item.option,
                item_brand: (_c = item.product.brand) == null ? void 0 : _c.name,
                quantity: item.quantity,
                price: selectedVariant ? selectedVariant.isSpecial ? `${selectedVariant.specialPrice}` : `${selectedVariant.price}` : ""
              };
            })
          }
        }
      });
    } catch (e) {
    }
  }
  onProductView(product) {
    var _a, _b;
    try {
      this.pushEvent({
        event: "view_item",
        m: "v1",
        ecommerce: {
          items: [
            {
              item_id: product.id,
              item_name: product.name,
              item_category: (_a = product.category) == null ? void 0 : _a.displayName,
              item_brand: (_b = product.brand) == null ? void 0 : _b.name
            }
          ]
        }
      });
    } catch (e) {
    }
  }
  onAddToCart(dispensaryName, product, selectedVariant, quantity, list) {
    var _a, _b, _c;
    const offersObj = {
      id: "",
      name: ""
    };
    if (product.offers) {
      product.offers.forEach((offer) => {
        if (offersObj.id.length && offersObj.name.length && offer.id && offer.title) {
          offersObj.id = offersObj.id.concat(":", offer.id);
          offersObj.name = offersObj.name.concat(":", offer.title);
        } else {
          offersObj.id = offer.id;
          offersObj.name = offer.title;
        }
      });
    }
    try {
      this.pushEvent({
        event: "add_to_cart",
        m: "v1",
        ecommerce: {
          currency: "USD",
          items: [
            {
              affiliation: dispensaryName,
              item_id: selectedVariant.id,
              item_name: product.name,
              price: selectedVariant.isSpecial ? `${selectedVariant.specialPrice}` : `${selectedVariant.price}`,
              quantity,
              item_category: (_a = product.category) == null ? void 0 : _a.displayName,
              item_category_2: (_b = product.subcategory) == null ? void 0 : _b.displayName,
              item_list_name: (list == null ? void 0 : list.name) || "",
              item_list_id: (list == null ? void 0 : list.id) || "",
              item_brand: ((_c = product.brand) == null ? void 0 : _c.name) || "",
              item_variant: selectedVariant.option,
              full_price: `${selectedVariant.price}`,
              discount: this.calculateDiscount(selectedVariant),
              promotion_name: offersObj.name,
              promotion_id: offersObj.id
            }
          ],
          rawData: {
            ...product,
            ...selectedVariant
          }
        }
      });
    } catch (e) {
    }
  }
  pushPageView(event) {
    const EVENT_KEY = "page_view";
    if (typeof window === "undefined")
      return;
    const windowRef = window;
    windowRef.dataLayer = window.dataLayer || [];
    const itemIndex = windowRef.dataLayer.findIndex((item) => {
      return item.event === EVENT_KEY;
    });
    if (itemIndex) {
      windowRef.dataLayer.splice(itemIndex, itemIndex + 1);
      const blankEvent = {};
      for (const key of Object.keys(event)) {
        blankEvent[key] = void 0;
      }
      windowRef.dataLayer.push(blankEvent);
      windowRef.dataLayer.push(event);
    } else {
      windowRef.dataLayer.push(event);
    }
  }
  pushDutchieEvent(event, payload) {
    if (typeof window === "undefined")
      return;
    const windowRef = window;
    windowRef.dataLayer = window.dataLayer || [];
    windowRef.dataLayer.push(payload);
  }
  pushOrderEvent(stateTitle, friendlyName, order) {
    try {
      this.pushOrderToUA(stateTitle, friendlyName, order);
    } catch (e) {
    }
  }
  pushClickEvent(event) {
    try {
      this.pushEvent(event);
    } catch (e) {
    }
  }
  pushViewEvent(event) {
    try {
      this.pushEvent({
        event: "in_view",
        ...event
      });
    } catch (e) {
    }
  }
  pushAccountEvent(event) {
    try {
      this.pushEvent(event);
    } catch (e) {
    }
  }
  pushOrderToUA(stateTitle, friendlyName, order) {
    if (typeof window === "undefined")
      return;
    const windowRef = window;
    windowRef.dataLayer = window.dataLayer || [];
    const mappedItemsUA = order.items.map((item) => {
      var _a, _b, _c, _d, _e, _f;
      const selectedVariant = (_a = item.product) == null ? void 0 : _a.variants.filter(
        (variant) => variant.option === item.option
      );
      return {
        name: (_b = item.product) == null ? void 0 : _b.name,
        id: selectedVariant == null ? void 0 : selectedVariant[0].id,
        price: (selectedVariant == null ? void 0 : selectedVariant[0].isSpecial) ? selectedVariant == null ? void 0 : selectedVariant[0].specialPrice : selectedVariant == null ? void 0 : selectedVariant[0].price,
        brand: (_d = (_c = item.product) == null ? void 0 : _c.brand) == null ? void 0 : _d.name,
        category: (_f = (_e = item.product) == null ? void 0 : _e.category) == null ? void 0 : _f.displayName,
        variant: item.option,
        quantity: item.quantity,
        discount: this.calculateDiscount(selectedVariant == null ? void 0 : selectedVariant[0])
      };
    });
    const mappedUA = {
      event: "purchase",
      m: "v1",
      actionField: { step: 3, option: "checkout_complete" },
      ecommerce: {
        purchase: {
          actionField: {
            id: order.orderNumber,
            affiliation: friendlyName,
            revenue: order.total,
            tax: order.tax,
            dimension19: stateTitle
          },
          products: mappedItemsUA
        },
        rawData: order
      }
    };
    windowRef.dataLayer.push(mappedUA);
  }
  pushLocationEvent(eventName, data, submitEvent = true) {
    try {
      if (typeof window === "undefined")
        return;
      const windowRef = window;
      windowRef.dataLayer = window.dataLayer || [];
      if (eventName === "location_finder_search") {
        this.metaData.locationSearch = {
          search_category: data.search_category,
          search_term: data.search_term
        };
      }
      if (eventName === "location_finder_filter") {
        this.metaData.locationFilter = {
          filter_type: data.filter_type
        };
      }
      if (submitEvent) {
        windowRef.dataLayer.push({
          event: eventName,
          ...data,
          ...this.metaData.locationSearch,
          ...this.metaData.locationFilter
        });
        if (eventName === "location_finder_cta") {
          this.metaData.locationSearch = {};
          this.metaData.locationFilter = {};
        }
      }
    } catch (err) {
    }
  }
};

// src/hook/analytics/useAnalytics.tsx
import { useMemo } from "react";
var useAnalytics = () => {
  const analytics = useMemo(() => new Analytics(), []);
  return analytics;
};

// src/hook/useWindowDimensions.ts
import { useEffect, useState } from "react";
var useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: void 0,
    height: void 0
  });
  useEffect(() => {
    function handleResize() {
      window && setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};

// src/hook/analytics/monetate.ts
var monetate_exports = {};
__export(monetate_exports, {
  addCartRows: () => addCartRows,
  addProductDetailsAndIndex: () => addProductDetailsAndIndex,
  addPurchaseRows: () => addPurchaseRows,
  gethistory: () => gethistory,
  setPageType: () => setPageType,
  setProductIndex: () => setProductIndex
});
function beforeEach() {
  if (!typeof window)
    return false;
  window.monetateQ = window.monetateQ || [];
  window.monetateHistory = window.monetateHistory || {};
  return true;
}
function setHistory() {
  window.monetateHistory = {};
}
function removeDuplicateStrings(strings) {
  return [...new Set(strings)];
}
function gethistory() {
  return window.monetateHistory;
}
function applyHistory() {
  for (const key of Object.keys(window.monetateHistory)) {
    window.monetateQ.push([key, window.monetateHistory[key]]);
  }
}
function pushHistoryItem(key, value) {
  const existingKeys = Object.keys(window.monetateHistory);
  const hasDuplicate = existingKeys.find((ek) => ek == key);
  switch (key) {
    case "addProducts": {
      if (hasDuplicate) {
        const combinedResults = [...window.monetateHistory[key], ...value];
        window.monetateHistory[key] = removeDuplicateStrings(combinedResults);
      } else {
        window.monetateHistory[key] = removeDuplicateStrings(value);
      }
      return;
    }
    default: {
      window.monetateHistory[key] = value;
    }
  }
}
function setPageType(type) {
  const ready = beforeEach();
  try {
    if (ready) {
      setHistory();
      pushHistoryItem("setPageType", type);
      applyHistory();
      window.monetateQ.push(["trackData"]);
    }
  } catch (err) {
  }
}
function setProductIndex(productsIds) {
  const ready = beforeEach();
  try {
    if (ready) {
      pushHistoryItem("addProducts", removeDuplicateStrings([...productsIds]));
      applyHistory();
      window.monetateQ.push(["trackData"]);
    }
  } catch (err) {
  }
}
function addCartRows(cartRow) {
  const ready = beforeEach();
  try {
    if (ready) {
      pushHistoryItem("addCartRows", [...cartRow]);
      applyHistory();
      window.monetateQ.push(["trackData"]);
    }
  } catch (err) {
  }
}
function addPurchaseRows(purchaseRow) {
  const ready = beforeEach();
  try {
    if (ready) {
      pushHistoryItem("addPurchaseRows", [...purchaseRow]);
      applyHistory();
      window.monetateQ.push(["trackData"]);
    }
  } catch (err) {
  }
}
function addProductDetailsAndIndex(product, productsIds) {
  const ready = beforeEach();
  try {
    if (ready) {
      pushHistoryItem("addProductDetails", [product]);
      pushHistoryItem("addProducts", removeDuplicateStrings([...productsIds]));
      applyHistory();
      window.monetateQ.push(["trackData"]);
    }
  } catch (err) {
  }
}

// src/model/query/loyaltyReferral.ts
import { gql as gql5 } from "@apollo/client";
var SUBMIT_REFERRAL = gql5`
  mutation Mutation($emailToRefer: String!, $path: String!) {
    loyaltyReferral(emailToRefer: $emailToRefer, path: $path) {
      message
      success
    }
  }
`;
var ACTIVE_REFERRALS = gql5`
  query ActiveLoyaltyReferrals {
    activeLoyaltyReferrals {
      email
      created_at
      status
    }
  }
`;
var REFERRAL_URL = gql5`
  query REFERRAL_URL {
    loyaltyReferralUrl {
      token
    }
  }
`;

// src/util/openTimes.ts
import moment4 from "moment";
var dotwLookup = [
  "sundayTimes",
  "mondayTimes",
  "tuesdayTimes",
  "wednesdayTimes",
  "thursdayTimes",
  "fridayTimes",
  "saturdayTimes",
  "sundayTimes"
];
var dotwNameLookup = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
function militaryToNormal(hr) {
  const hrNumber = parseInt(hr);
  if (hrNumber === 0) {
    return {
      ampm: "pm",
      hr: "12"
    };
  }
  if (hrNumber < 12) {
    return {
      ampm: "am",
      hr: `${parseInt(hr)}`
    };
  }
  if (hrNumber === 24) {
    return {
      ampm: "am",
      hr: `${hrNumber - 12}`
    };
  }
  return {
    ampm: "pm",
    hr: `${hrNumber - 12}`
  };
}
function getStoreTimes(store) {
  const currTime = moment4();
  const date = new Date();
  const localOffset = date.getTimezoneOffset() / 60;
  let totalOffset = localOffset + store.timezone;
  if (currTime.isDST()) {
    totalOffset += store.dst;
  }
  currTime.add(totalOffset, "hour");
  const dotwCode = moment4().day();
  const dayVar = dotwLookup[dotwCode];
  const tomorrowDayVar = dotwLookup[dotwCode + 1];
  const storeTimes = store.day_times[dayVar];
  let isOpen = false;
  let nextTimeDesc = "";
  if (!(storeTimes == null ? void 0 : storeTimes.length)) {
    if (store.day_times[tomorrowDayVar].length) {
      return {
        isOpen,
        nextTimeDesc: moment4().day(dotwCode + 1).format("dddd")
      };
    }
    return {
      isOpen,
      nextTimeDesc
    };
  }
  let tomorrowStoreTimes = store.day_times[tomorrowDayVar];
  tomorrowStoreTimes = tomorrowStoreTimes.map((time) => {
    time.map((item) => item === "24:00" ? "00:00" : item);
    return time;
  });
  storeTimes.map((strTime) => {
    try {
      strTime = strTime.map((time) => time === "24:00" ? "00:00" : time);
      const currHr = currTime.hour();
      const currMin = currTime.minute();
      const startHr = parseInt(strTime[0].split(":")[0]);
      const startMin = parseInt(strTime[0].split(":")[1]);
      let endHr = parseInt(strTime[1].split(":")[0]);
      endHr = endHr === 0 ? 24 : endHr;
      const endMin = parseInt(strTime[1].split(":")[1]);
      const isBetween = currHr >= startHr && currHr <= endHr && (currHr !== startHr || currMin >= startMin) && (currHr !== endHr || currMin <= endMin);
      if (startHr > endHr ? !isBetween : isBetween) {
        isOpen = true;
        if (strTime[1] === "00:00" && tomorrowStoreTimes && tomorrowStoreTimes[0] && tomorrowStoreTimes[0][0] === "00:00") {
          const newTime = tomorrowStoreTimes[0][1];
          const hrObj = militaryToNormal(newTime.split(":")[0]);
          nextTimeDesc = `${hrObj.hr}:${newTime.split(":")[1]}${hrObj.ampm}`;
        } else if (!strTime[1] || !tomorrowStoreTimes || !tomorrowStoreTimes[0] || !tomorrowStoreTimes[0][0]) {
          nextTimeDesc = "";
        } else {
          const hrObj = militaryToNormal(strTime[1].split(":")[0]);
          nextTimeDesc = `${hrObj.hr}:${strTime[1].split(":")[1]}${hrObj.ampm}`;
        }
      }
    } catch {
      isOpen = false;
      nextTimeDesc = "";
    }
  });
  if (!isOpen) {
    nextTimeDesc = "";
    storeTimes.map((strTime) => {
      const currHr = currTime.hour();
      const currMin = currTime.minute();
      const startHr = parseInt(strTime[0].split(":")[0]);
      const startMin = parseInt(strTime[0].split(":")[1]);
      if (currHr < startHr || currHr === startHr && currMin < startMin) {
        const hrObj = militaryToNormal(strTime[0].split(":")[0]);
        nextTimeDesc = `${hrObj.hr}:${strTime[0].split(":")[1]}${hrObj.ampm}`;
      }
    });
    if (nextTimeDesc !== "") {
      return {
        isOpen,
        nextTimeDesc
      };
    }
    for (let i = 1; i < 10; i++) {
      const nextDayStr = nextOpenDay(dotwCode, store.day_times, i, i === 1);
      if (nextDayStr) {
        return {
          isOpen,
          nextTimeDesc: nextDayStr
        };
      }
    }
  }
  return {
    isOpen,
    nextTimeDesc
  };
}
function nextOpenDay(dotwCode, day_times, dayOffset, substituteEnding) {
  const dayVar = dotwLookup[(dotwCode + dayOffset) % 7];
  const dayStoreTimes = day_times[dayVar];
  if (dayStoreTimes.length) {
    const startHr = parseInt(dayStoreTimes[0][0].split(":")[0]);
    if (startHr) {
      const hrObj = militaryToNormal(dayStoreTimes[0][0].split(":")[0]);
      return `${hrObj.hr}:${dayStoreTimes[0][0].split(":")[1]}${hrObj.ampm} ${substituteEnding ? "" : `on ${dotwNameLookup[(dotwCode + dayOffset) % 7]}`}`;
    }
  }
}

// src/util/dispensaryMapping.ts
var mapStoresTagsFor = (store) => {
  const tags = [];
  if (store.dispensary_type === "MedicalOnly" || store.dispensary_type === "Both") {
    tags.push("MEDICAL");
  }
  if (store.dispensary_type === "AdultUseOnly" || store.dispensary_type === "Both") {
    tags.push("RECREATIONAL");
  }
  if (store.store_offerings && store.store_offerings.includes("Delivery")) {
    tags.push("DELIVERY");
  }
  return tags;
};
var mapDispensaryToStoreType = (dispensary, isFullPath = false, cb) => {
  var _a, _b;
  const storeTimes = getStoreTimes(dispensary);
  const isPreRelease = !dispensary.storefront || dispensary.storefront.length === 0 || dispensary.storefront[0].__typename === "DispensaryConfigStorefrontPreRelease";
  const externalLink = dispensary.storefront[0].__typename === "DispensaryConfigStorefrontExternal" && ((_b = (_a = dispensary.storefront[0].external) == null ? void 0 : _a.url) == null ? void 0 : _b.length) ? dispensary.storefront[0].external.url : "";
  const dispo = {
    address: `${dispensary.address}`,
    city: dispensary.city,
    closesAt: storeTimes.nextTimeDesc,
    comingSoon: isPreRelease,
    distance: dispensary.distance,
    distanceInMiles: 0,
    externalLink,
    isOpened: isPreRelease ? false : storeTimes.isOpen,
    name: dispensary.friendly_name,
    opensAt: storeTimes.nextTimeDesc,
    setStoreType: cb ? cb : null,
    state: {
      abbreviation: dispensary.state.abbreviation,
      stateName: dispensary.state.title
    },
    storeLinkUrl: isFullPath ? `/shop/${dispensary.state.slug}/${dispensary.slug}` : `/shop/${dispensary.slug}`,
    tags: mapStoresTagsFor({
      dispensary_type: dispensary.dispensary_type,
      store_offerings: dispensary.store_offerings
    }),
    uid: dispensary.unique_id,
    zipCode: dispensary.zipcode
  };
  return dispo;
};

// src/util/formStateDispensaryMap.ts
async function formStateDispensaryMap(form) {
  try {
    const dropdowntype = form.dropdowntype;
    const data = form;
    const contentstack5 = new ContentStackService();
    const allStates = await contentstack5.getStatesData(false, true);
    const allDispensaries = await contentstack5.getDispensaryData(true, false);
    const selectedDispensaries = data.dispensariesConnection.edges;
    const selectedStates = data.statesConnection.edges;
    switch (dropdowntype) {
      case "All States / All Dispensaries": {
        if (allStates.success) {
          allStates.data.map((state) => {
            if (!data.statesConnection.edges.includes({ node: state })) {
              data.statesConnection.edges.push({ node: state });
            }
          });
        }
        if (allDispensaries.success) {
          allDispensaries.data.map((dispensary) => {
            if (!data.dispensariesConnection.edges.includes({ node: dispensary })) {
              data.dispensariesConnection.edges.push({ node: dispensary });
            }
          });
        }
        return form;
      }
      case "All States / No Dispensaries": {
        if (allStates.success) {
          allStates.data.map((state) => {
            data.statesConnection.edges.push({ node: state });
          });
        }
        data.dispensariesConnection.edges = [];
        return form;
      }
      case "Selected Dispensaries": {
        if (allStates.success && allDispensaries.success && selectedDispensaries && selectedDispensaries.length) {
          const matchingStates = [];
          const matchingDispensaries = [];
          allDispensaries.data.filter((dispensary) => {
            const match = selectedDispensaries.find(
              (dispo) => dispo.node.friendly_name === dispensary.friendly_name
            );
            if (match) {
              matchingDispensaries.push(dispensary);
            }
          });
          if (matchingDispensaries && matchingDispensaries.length) {
            data.dispensariesConnection.edges = matchingDispensaries.map(
              (dispo) => {
                return {
                  node: dispo
                };
              }
            );
          }
          matchingDispensaries.map((dispensary) => {
            const match = allStates.data.filter((state) => {
              if (state && state.abbreviation && dispensary && dispensary.state && dispensary.state.abbreviation) {
                return state.abbreviation === dispensary.state.abbreviation;
              }
            });
            if (matchingStates && !matchingStates.includes(match[0])) {
              matchingStates.push(match[0]);
            }
            if (matchingStates && matchingStates.length) {
              data.statesConnection.edges = matchingStates.map((state) => ({
                node: state
              }));
            }
          });
        }
        return form;
      }
      case "Selected States / All Relevant Dispensaries": {
        if (selectedStates && selectedStates.length && allDispensaries.success) {
          selectedStates.map((state) => {
            allDispensaries.data.filter((dispensary) => {
              if (dispensary.state.abbreviation === state.node.abbreviation && !data.dispensariesConnection.edges.includes({
                node: dispensary
              })) {
                data.dispensariesConnection.edges.push({ node: dispensary });
              }
            });
          });
        }
        return form;
      }
      case "Selected States / Selected Dispensaries": {
        if (allStates.success && allDispensaries.success && selectedDispensaries && selectedDispensaries.length && selectedStates && selectedStates.length) {
          const matchingStates = [];
          const matchingDispensaries = [];
          allDispensaries.data.filter((dispensary) => {
            const match = selectedDispensaries.find(
              (dispo) => dispo.node.friendly_name === dispensary.friendly_name
            );
            if (match) {
              matchingDispensaries.push(dispensary);
            }
          });
          data.dispensariesConnection.edges = matchingDispensaries.map(
            (dispo) => {
              return {
                node: dispo
              };
            }
          );
          allStates.data.filter((states) => {
            const match = selectedStates.find(
              (state) => state.node.abbreviation === states.abbreviation
            );
            if (match) {
              matchingStates.push(states);
            }
            data.statesConnection.edges = matchingStates.map((state) => {
              return {
                node: state
              };
            });
          });
        }
        return form;
      }
      case "No States / No Dispensaries": {
        data.statesConnection.edges = [];
        data.dispensariesConnection.edges = [];
        return form;
      }
      default: {
        console.warn("Error: Missing component", dropdowntype);
        return null;
      }
    }
  } catch (err) {
    console.error(err);
  }
  return form;
}
var formStatesNDispensariesMap = async (dropdowntype, selected) => {
  const contentstack5 = new ContentStackService();
  const allStates = await contentstack5.getStatesData(false, true);
  const allDispensaries = await contentstack5.getDispensaryData(true, false);
  switch (dropdowntype) {
    case "All States / All Dispensaries": {
      if (allStates.success && allDispensaries.success) {
        const statesNdispensaries = allStates.data.map((state) => ({
          state: state.title,
          dispensaries: allDispensaries.data.reduce((arr, disp) => {
            if (disp.state.title === state.title) {
              arr.push({
                name: disp.friendly_name
              });
            }
            return arr;
          }, [])
        }));
        return {
          success: true,
          data: statesNdispensaries
        };
      }
      console.warn("Error: States or Dispensaries Req failed");
      return {
        success: false,
        errorMessage: allStates.errorMessage
      };
    }
    case "All States / No Dispensaries": {
      if (allStates.success) {
        return {
          success: true,
          data: allStates.data.map((state) => ({
            state: state.title,
            dispensaries: []
          }))
        };
      }
      console.warn("Error: States req failed");
      return {
        success: false,
        errorMessage: allStates.errorMessage
      };
    }
    case "Selected States / Selected Dispensaries": {
      if (allDispensaries.success && (selected == null ? void 0 : selected.dispensaries.length) && (selected == null ? void 0 : selected.states.length)) {
        const dispensaries = allDispensaries.data.filter((dispensary) => {
          const match = selected.dispensaries.find(
            (dispo) => dispo.node.friendly_name === dispensary.friendly_name
          );
          if (match) {
            return dispensary;
          }
        });
        const statesNdispensaries = selected.states.map((state) => {
          return {
            state: state.node.title,
            dispensaries: dispensaries.filter((disp) => disp.state.title === state.node.title).map((d) => ({
              name: d.friendly_name
            }))
          };
        });
        return {
          success: true,
          data: statesNdispensaries
        };
      }
      console.warn(
        "Error: No selected dispensaries & states for dropdowntype: Selected Dispensaries OR dispensaries req failed"
      );
      return {
        success: false,
        errorMessage: allStates.errorMessage
      };
    }
    case "Selected Dispensaries": {
      if ((selected == null ? void 0 : selected.dispensaries.length) && allStates.success && allDispensaries.success) {
        const dispensaries = allDispensaries.data.filter((dispensary) => {
          const match = selected.dispensaries.find(
            (dispo) => dispo.node.friendly_name === dispensary.friendly_name
          );
          if (match) {
            return dispensary;
          }
        });
        const states = [];
        dispensaries.forEach((disp) => {
          const matchingState = allStates.data.find(
            (state) => disp.state.title === state.title
          );
          if (matchingState) {
            if (!states.includes(matchingState.title))
              states.push(matchingState.title);
          }
        });
        const statesNdispensaries = states.map((state) => {
          return {
            state,
            dispensaries: dispensaries.filter((disp) => disp.state.title === state).map((d) => ({
              name: d.friendly_name
            }))
          };
        });
        return {
          success: true,
          data: statesNdispensaries
        };
      }
      console.warn(
        "Error: No selected dispensaries for dropdowntype: Selected Dispensaries"
      );
      return {
        success: false,
        errorMessage: allStates.errorMessage
      };
    }
    default: {
      console.warn(
        "Error: Missing dropdowntype case for Embeddable Form Map: ",
        dropdowntype
      );
      return {
        success: false,
        errorMessage: `Missing dropdown type for Embeddable Form Map: ${dropdowntype}`
      };
    }
  }
};

// src/util/useStorage.tsx
import { useState as useState2 } from "react";
function useStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState2(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (!item) {
        window.localStorage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      } else {
        return JSON.parse(item);
      }
    } catch (error) {
      return initialValue;
    }
  });
  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
    }
  };
  return [storedValue, setValue];
}

// src/util/storeOfferMap.ts
var CSOfferingOptions = {
  "Curbside Pickup": "PICKUP",
  Delivery: "DELIVERY",
  "Point of Sale ATM": "DEBIT",
  "In Store": "INSTORE",
  "Express pick-up": "PICKUP",
  Cash: "CASH",
  "Parking Available": "PARKING",
  "Handicap Accessible": "HC",
  "ATM on-site": "DEBIT",
  "Drive Through": "PICKUP",
  CanPay: "CASH",
  "Pin Debit": "DEBIT"
};
var catWeights = {
  HC: 3,
  PARKING: 4,
  CASH: 1,
  DEBIT: 2,
  PICKUP: 5,
  INSTORE: 6
};
var storeOfferMap = (offerings, debitFee) => offerings && offerings.length ? [...new Set(offerings)].filter((o) => {
  return o.toLowerCase() !== "debit" && o.toLowerCase() !== "atm on site";
}).map((o, i) => {
  let text = o;
  if (o === "Point of Sale ATM" || o === "Pin Debit") {
    text = `${o} ${debitFee || ""}`;
  }
  return {
    id: i.toString(),
    text,
    type: CSOfferingOptions[o] ? CSOfferingOptions[o] : ""
  };
}).sort((a, b) => {
  const aRank = catWeights[a.type];
  const bRank = catWeights[b.type];
  if (aRank > bRank) {
    return 1;
  } else if (aRank < bRank) {
    return -1;
  } else
    return 0;
}) : [];

// src/data/commonlyUsedPhoneNumbers.ts
var commonlyUsedPhoneNumbers = ["1111111111", "1234567890"];

// src/util/fakeNumber.ts
var contentstack4 = new ContentStackService();
var isFakeNumber = async (number) => {
  const rules = await contentstack4.getSecurityRules();
  if (rules.success && rules.data && Array.isArray(rules.data)) {
    const { blocked_numbers, blocked_prefixes, blocked_suffixes } = rules.data[0];
    const prefix = number.substring(0, 3);
    const suffix = number.substring(3);
    if (blocked_numbers.length) {
      const isBlocked = blocked_numbers.some((blockedNum) => {
        return blockedNum == number;
      });
      if (isBlocked)
        return isBlocked;
    }
    if (blocked_prefixes.length) {
      const isBlocked = blocked_prefixes.some((blockedPrefix) => {
        return blockedPrefix == prefix;
      });
      if (isBlocked)
        return isBlocked;
    }
    if (blocked_suffixes.length) {
      const isBlocked = blocked_suffixes.some((blockedSuffix) => {
        return blockedSuffix == suffix;
      });
      if (isBlocked)
        return isBlocked;
    }
  }
  return commonlyUsedPhoneNumbers.indexOf(number) > -1;
};

// src/util/analyticsMap.ts
var categoryFilterMap = (filter_type, filter_value) => {
  return {
    event: "category_filter",
    filter_type,
    filter_value
  };
};
var sortMap = (sort_type) => {
  return {
    event: "category_sort",
    sort_type
  };
};

// src/util/seoMap.ts
var sanitizeString = (str) => {
  if (typeof str != "string")
    return str;
  const trimmed = str.trim();
  if (trimmed.length)
    return trimmed;
  return "";
};
var seoMap = (seo) => {
  const openGraph = seo.imageConnection ? {
    type: "basic",
    locale: "en_us",
    title: sanitizeString(seo.page_title),
    description: sanitizeString(seo.page_description),
    url: seo.url ? seo.url : "",
    images: seo.imageConnection && seo.imageConnection.edges && seo.imageConnection.edges.length ? [
      {
        url: seo.imageConnection.edges[0].node.url,
        width: seo.imageConnection.edges[0].node.dimension.width,
        height: seo.imageConnection.edges[0].node.dimension.height,
        alt: seo.imageConnection.edges[0].node.description
      }
    ] : null,
    site_name: process.env.WESBITE || ""
  } : {};
  return {
    canonical: sanitizeString(seo.canonical_url || ""),
    description: sanitizeString(seo.page_description || ""),
    noFollow: seo.no_follow || !!process.env.NEXT_PUBLIC_HIDE_ANALYTICS,
    noIndex: seo.no_index || !!process.env.NEXT_PUBLIC_HIDE_ANALYTICS,
    openGraph: { ...{ ...openGraph } },
    title: sanitizeString(seo.page_title || "")
  };
};

// src/api/graph/curaql.ts
var curaql_exports = {};
__export(curaql_exports, {
  ADD_ITEM_TO_CART: () => ADD_ITEM_TO_CART,
  CART_META_KEY: () => CART_META_KEY,
  CLEAR_USER_CART: () => CLEAR_USER_CART,
  GET_CART_DATA: () => GET_CART_DATA,
  GET_CART_RECOMMENDATIONS: () => GET_CART_RECOMMENDATIONS,
  GET_CATEGORIES_DATA: () => GET_CATEGORIES_DATA,
  GET_GRID_DATA: () => GET_GRID_DATA,
  GET_LOYALTY_DATA: () => GET_LOYALTY_DATA,
  GET_LOYALTY_POINT_DATA: () => GET_LOYALTY_POINT_DATA,
  GET_ORDER_INFO: () => GET_ORDER_INFO,
  GET_PRODUCT_DATA: () => GET_PRODUCT_DATA,
  GET_PRODUCT_LISTINGS: () => GET_PRODUCT_LISTINGS,
  GET_SHOP_HOMEPAGE_DATA: () => GET_SHOP_HOMEPAGE_DATA,
  GET_USER: () => GET_USER,
  GET_USER_CART: () => GET_USER_CART,
  REMOVE_ITEM_FROM_USER_CART: () => REMOVE_ITEM_FROM_USER_CART,
  UPDATE_USER: () => UPDATE_USER,
  UPDATE_USER_CART: () => UPDATE_USER_CART,
  getDispensaries: () => getDispensaries
});
import { request as request3 } from "graphql-request";
import { gql as gql7 } from "@apollo/client";

// src/api/graph/queries.ts
import { gql as gql6 } from "@apollo/client";
var GET_USER_CART = gql6`
  ${CART_PRODUCT}
  query Query($dispensaryUniqueId: String, $checkoutId: String) {
    cart(dispensaryUniqueId: $dispensaryUniqueId, checkoutId: $checkoutId) {
      dispensaryUniqueId
      address {
        city
        deliverable
        formatted
        geometry {
          coordinates
          type
        }
        state
        street1
        street2
        valid
        zip
      }
      id
      items {
        id
        option
        product {
          ...cart
        }
        productId
        quantity
        valid
        isDiscounted
        basePrice
        discounts {
          total
        }
        taxes {
          total
          cannabis
          sales
        }
      }
      orderType
      priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
      }
      pricingType
      redirectUrl
      updatedAt
    }
  }
`;
var REMOVE_ITEM_FROM_USER_CART = gql6`
  ${CART_PRODUCT}
  mutation RemoveItemFromCart(
    $dispensaryUniqueId: ID!
    $checkoutId: ID!
    $itemId: ID!
  ) {
    removeItemFromCart(
      dispensaryUniqueId: $dispensaryUniqueId
      checkoutId: $checkoutId
      itemId: $itemId
    ) {
      dispensaryUniqueId
      address {
        city
        deliverable
        formatted
        geometry {
          coordinates
          type
        }
        state
        street1
        street2
        valid
        zip
      }
      id
      items {
        id
        option
        product {
          ...cart
        }
        productId
        quantity
        valid
        isDiscounted
        basePrice
        discounts {
          total
        }
        taxes {
          total
          cannabis
          sales
        }
      }
      orderType
      priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
      }
      pricingType
      redirectUrl
      updatedAt
    }
  }
`;
var ADD_ITEM_TO_CART = gql6`
  ${CART_PRODUCT}
  mutation Mutation(
    $dispensaryUniqueId: ID!
    $orderType: OrderType!
    $pricingType: PricingType!
    $productId: ID!
    $quantity: Int!
    $option: String!
    $checkoutId: ID
    $baseCartUrl: String
  ) {
    addItemToCart(
      dispensaryUniqueId: $dispensaryUniqueId
      orderType: $orderType
      pricingType: $pricingType
      productId: $productId
      quantity: $quantity
      option: $option
      checkoutId: $checkoutId
      baseCartUrl: $baseCartUrl
    ) {
      id
      priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
      }
      pricingType
      redirectUrl
      orderType
      items {
        id
        option
        product {
          ...cart
        }
        quantity
        valid
        isDiscounted
        basePrice
        discounts {
          total
        }
        taxes {
          total
          cannabis
          sales
        }
        productId
      }
      updatedAt
    }
  }
`;
var UPDATE_USER_CART = gql6`
  ${CART_PRODUCT}
  mutation Mutation(
    $dispensaryUniqueId: ID!
    $orderType: OrderType!
    $pricingType: PricingType!
    $checkoutId: ID!
  ) {
    mergeCart(
      dispensaryUniqueId: $dispensaryUniqueId
      orderType: $orderType
      pricingType: $pricingType
      checkoutId: $checkoutId
    ) {
      id
      priceSummary {
        discounts
        fees
        mixAndMatch
        rewards
        subtotal
        taxes
        total
      }
      pricingType
      redirectUrl
      orderType
      items {
        id
        option
        product {
          ...cart
        }
        quantity
        valid
        isDiscounted
        basePrice
        discounts {
          total
        }
        taxes {
          total
          cannabis
          sales
        }
        productId
      }
      updatedAt
    }
  }
`;
var CLEAR_USER_CART = gql6`
  mutation Mutation {
    clearCart {
      success
    }
  }
`;
var GET_CART_RECOMMENDATIONS = gql6`
  ${TILE_PRODUCT}
  query CartRecommendation($dispensaryUniqueId: ID!, $checkoutId: ID) {
    recommendations(
      dispensaryUniqueId: $dispensaryUniqueId
      checkoutId: $checkoutId
    ) {
      ...tile
    }
  }
`;
var GET_PRODUCT_LISTINGS = gql6`
  ${TILE_PRODUCT}
  query ProductListings(
    $dispensaryUniqueId: String!
    $menuType: MenuType!
    $categoryType: Category!
  ) {
    productListings(
      dispensaryUniqueId: $dispensaryUniqueId
      menuType: $menuType
      categoryType: $categoryType
    ) {
      products {
        ...tile
      }
    }
  }
`;
var GET_USER = gql6`
  query User {
    user {
      birthday
      brandPreference
      categoryPreference
      dispensaryPreferences
      email
      firstName
      lastName
      mainSubscriptionStatus
      phone
      state
    }
  }
`;
var UPDATE_USER = gql6`
  mutation UpdateUser(
    $firstName: String
    $lastName: String
    $phone: String
    $birthYear: String
    $birthMonth: String
    $birthDay: String
    $state: String
    $brandPreference: [String]
    $categoryPreference: [String]
    $dispensaryPreferences: [String]
    $mainSubscriptionStatus: SubscriptionStatus
  ) {
    user(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      birthYear: $birthYear
      birthMonth: $birthMonth
      birthDay: $birthDay
      state: $state
      brandPreference: $brandPreference
      categoryPreference: $categoryPreference
      dispensaryPreferences: $dispensaryPreferences
      mainSubscriptionStatus: $mainSubscriptionStatus
    ) {
      birthday
      brandPreference
      categoryPreference
      dispensaryPreferences
      email
      firstName
      lastName
      mainSubscriptionStatus
      phone
      state
    }
  }
`;
var GET_LOYALTY_DATA = gql6`
  query LoyaltyMemberInfo {
    loyaltyMemberInfo {
      currentTier {
        name
        formattedName
        prestige
        perks {
          title
          description
          iconName
        }
      }
      nextTier {
        name
        formattedName
        prestige
        perks {
          title
          description
          iconName
        }
      }
      incentiveText
      isMaxRank
      nextTierSpend
      tierProgress
      totalPointsAvailable
      totalPointsDeducted
      totalPointsExpired
      totalPointsUsed
      totalSpend
    }
  }
`;
var GET_LOYALTY_POINT_DATA = gql6`
  query LoyaltyPointHistory {
    loyaltyPointHistory {
      date
      locationName
      pointsEarned
      pointsEarnedFormatted
      retailValue
      retailValueFormatted
    }
  }
`;
var GET_ORDER_INFO = gql6`
  ${ORDER_PRODUCT}
  query Query($orderNumber: ID!, $dispensaryUniqueId: ID!) {
    order(orderNumber: $orderNumber, dispensaryUniqueId: $dispensaryUniqueId) {
      items {
        option
        product {
          ...order
        }
        quantity
      }
      orderNumber
      discounts
      total
      subtotal
      tax
      confirmationText
      total
      dispensaryName
      viewed
    }
  }
`;
var GET_SHOP_HOMEPAGE_DATA = gql6`
  ${TILE_PRODUCT}
  query ShopHome($dispensaryUniqueId: ID!, $menuType: MenuType!) {
    dispensaryContent(dispensaryUniqueId: $dispensaryUniqueId) {
      alerts {
        id
        title
        detail
        buttonText
      }
      marketingBanners2 {
        ... on StorefrontBanner2BannersTextImageBanner {
          id
          cta_action {
            ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToAccount {
              type
              go_to_account {
                location
              }
            }
            ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToBrand {
              type
              go_to_brand {
                brand_name
              }
            }
            ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToBrandCategory {
              type
              go_to_brand_category {
                brand_name
                category_name
              }
            }
            ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToCategory {
              type
              go_to_category {
                category_name
              }
            }
            ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToSearch {
              type
              go_to_search {
                search_text
              }
            }
            ... on StorefrontBanner2BannersTextImageBannerBlockCtaActionGoToUrl {
              type
              go_to_url {
                url
              }
            }
          }
          cta_url
          desktop {
            alt
            body
            ctaButton {
              text
            }
            dimension {
              height
              width
            }
            heading
            url
          }
          layout
          mobile {
            alt
            body
            dimension {
              height
              width
            }
            heading
            url
          }
          mode
          type
          where_to_show
        }
        ... on StorefrontBanner2BannersImageBlock {
          id
          cta_url
          desktop {
            alt
            dimension {
              height
              width
            }
            url
          }
          mobile {
            alt
            dimension {
              height
              width
            }
            url
          }
          type
          cta_action {
            ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToAccount {
              type
              go_to_account {
                location
              }
            }
            ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToBrand {
              type
              go_to_brand {
                brand_name
              }
            }
            ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToBrandCategory {
              type
              go_to_brand_category {
                brand_name
                category_name
              }
            }
            ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToCategory {
              type
              go_to_category {
                category_name
              }
            }
            ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToSearch {
              type
              go_to_search {
                search_text
              }
            }
            ... on StorefrontBanner2BannersImageBlockBlockCtaActionGoToUrl {
              type
              go_to_url {
                url
              }
            }
          }
          where_to_show
        }
      }
      storefrontBanners {
        heading
        text
        image {
          url
        }
        subtitle
        cta {
          text
          url
        }
      }
    }
    dispensaryMenu(
      dispensaryUniqueId: $dispensaryUniqueId
      menuType: $menuType
    ) {
      allFilters {
        categories {
          displayName
          key
        }
      }
      banner
      offers {
        id
        title
      }
      productLists {
        flavorText
        key
        products {
          ...tile
        }
        title
        type
      }
    }
  }
`;
var GET_PRODUCT_DATA = gql6`
  ${FULL_PRODUCT}
  ${TILE_PRODUCT}
  query PDP($dispensaryUniqueId: ID!, $menuType: MenuType!, $productId: ID!) {
    product(
      dispensaryUniqueId: $dispensaryUniqueId
      menuType: $menuType
      productId: $productId
    ) {
      product {
        ...full
      }
    }
    recommendationsByProduct(
      dispensaryUniqueId: $dispensaryUniqueId
      menuType: $menuType
      productId: $productId
    ) {
      allRecommendations {
        ...tile
      }
      categoryRecommendations {
        ...tile
      }
    }
  }
`;
var GET_GRID_DATA = gql6`
  ${GRID_PRODUCT}
  query PGP($dispensaryUniqueId: ID!, $menuType: MenuType!) {
    dispensaryMenu(
      dispensaryUniqueId: $dispensaryUniqueId
      menuType: $menuType
    ) {
      offers {
        id
        title
      }
      products {
        ...grid
      }
    }
  }
`;
var GET_CATEGORIES_DATA = gql6`
  query CategoriesPage($dispensaryUniqueId: ID!, $menuType: MenuType!) {
    dispensaryMenu(
      dispensaryUniqueId: $dispensaryUniqueId
      menuType: $menuType
    ) {
      allFilters {
        categories {
          displayName
          key
        }
      }
    }
  }
`;
var GET_CART_DATA = gql6`
  query RedirectParamUrl {
    user {
      redirectParamUrl
    }
  }
`;

// src/api/graph/curaql.ts
var CART_META_KEY = "cart_meta";
async function getDispensaries(isPreview = false) {
  const query = gql7`
    query Dispensary {
      dispensaries {
        menuTypes
        name
        orderTypes
        slug
        uniqueId
      }
    }
  `;
  const req = await request3(
    isPreview ? process.env.CURAQL_PREVIEW_BASEURL : process.env.CURAQL_BASEURL,
    query
  );
  return req.dispensaries;
}

// src/api/contentstack/types/graphql.ts
var AbandonedCartConfigsOrderBy = /* @__PURE__ */ ((AbandonedCartConfigsOrderBy2) => {
  AbandonedCartConfigsOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  AbandonedCartConfigsOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  AbandonedCartConfigsOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  AbandonedCartConfigsOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return AbandonedCartConfigsOrderBy2;
})(AbandonedCartConfigsOrderBy || {});
var AccountLoyaltyPreferencesOrderBy = /* @__PURE__ */ ((AccountLoyaltyPreferencesOrderBy2) => {
  AccountLoyaltyPreferencesOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  AccountLoyaltyPreferencesOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  AccountLoyaltyPreferencesOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  AccountLoyaltyPreferencesOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return AccountLoyaltyPreferencesOrderBy2;
})(AccountLoyaltyPreferencesOrderBy || {});
var AppContentOrderBy = /* @__PURE__ */ ((AppContentOrderBy2) => {
  AppContentOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  AppContentOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  AppContentOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  AppContentOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return AppContentOrderBy2;
})(AppContentOrderBy || {});
var ArticleGridOrderBy = /* @__PURE__ */ ((ArticleGridOrderBy2) => {
  ArticleGridOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ArticleGridOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ArticleGridOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ArticleGridOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ArticleGridOrderBy2;
})(ArticleGridOrderBy || {});
var ArticleOrderBy = /* @__PURE__ */ ((ArticleOrderBy2) => {
  ArticleOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ArticleOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ArticleOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ArticleOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ArticleOrderBy2;
})(ArticleOrderBy || {});
var CommunityNewsItemOrderBy = /* @__PURE__ */ ((CommunityNewsItemOrderBy2) => {
  CommunityNewsItemOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  CommunityNewsItemOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  CommunityNewsItemOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  CommunityNewsItemOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return CommunityNewsItemOrderBy2;
})(CommunityNewsItemOrderBy || {});
var ContactFormsOrderBy = /* @__PURE__ */ ((ContactFormsOrderBy2) => {
  ContactFormsOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ContactFormsOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ContactFormsOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ContactFormsOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ContactFormsOrderBy2;
})(ContactFormsOrderBy || {});
var CrmCdpOrderBy = /* @__PURE__ */ ((CrmCdpOrderBy2) => {
  CrmCdpOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  CrmCdpOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  CrmCdpOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  CrmCdpOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return CrmCdpOrderBy2;
})(CrmCdpOrderBy || {});
var DecisionTreeAnswerOrderBy = /* @__PURE__ */ ((DecisionTreeAnswerOrderBy2) => {
  DecisionTreeAnswerOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  DecisionTreeAnswerOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  DecisionTreeAnswerOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  DecisionTreeAnswerOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return DecisionTreeAnswerOrderBy2;
})(DecisionTreeAnswerOrderBy || {});
var DecisionTreeQuestionsOrderBy = /* @__PURE__ */ ((DecisionTreeQuestionsOrderBy2) => {
  DecisionTreeQuestionsOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  DecisionTreeQuestionsOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  DecisionTreeQuestionsOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  DecisionTreeQuestionsOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return DecisionTreeQuestionsOrderBy2;
})(DecisionTreeQuestionsOrderBy || {});
var DispensaryConfigOrderBy = /* @__PURE__ */ ((DispensaryConfigOrderBy2) => {
  DispensaryConfigOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  DispensaryConfigOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  DispensaryConfigOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  DispensaryConfigOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return DispensaryConfigOrderBy2;
})(DispensaryConfigOrderBy || {});
var DispensarysyncOrderBy = /* @__PURE__ */ ((DispensarysyncOrderBy2) => {
  DispensarysyncOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  DispensarysyncOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  DispensarysyncOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  DispensarysyncOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return DispensarysyncOrderBy2;
})(DispensarysyncOrderBy || {});
var DutchiePlusPreferencesOrderBy = /* @__PURE__ */ ((DutchiePlusPreferencesOrderBy2) => {
  DutchiePlusPreferencesOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  DutchiePlusPreferencesOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  DutchiePlusPreferencesOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  DutchiePlusPreferencesOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return DutchiePlusPreferencesOrderBy2;
})(DutchiePlusPreferencesOrderBy || {});
var EcommerceConfigOrderBy = /* @__PURE__ */ ((EcommerceConfigOrderBy2) => {
  EcommerceConfigOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  EcommerceConfigOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  EcommerceConfigOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  EcommerceConfigOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return EcommerceConfigOrderBy2;
})(EcommerceConfigOrderBy || {});
var EcommerceMenuOrderBy = /* @__PURE__ */ ((EcommerceMenuOrderBy2) => {
  EcommerceMenuOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  EcommerceMenuOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  EcommerceMenuOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  EcommerceMenuOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return EcommerceMenuOrderBy2;
})(EcommerceMenuOrderBy || {});
var EcommerceTextBannerOrderBy = /* @__PURE__ */ ((EcommerceTextBannerOrderBy2) => {
  EcommerceTextBannerOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  EcommerceTextBannerOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  EcommerceTextBannerOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  EcommerceTextBannerOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return EcommerceTextBannerOrderBy2;
})(EcommerceTextBannerOrderBy || {});
var EmbeddableFormOrderBy = /* @__PURE__ */ ((EmbeddableFormOrderBy2) => {
  EmbeddableFormOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  EmbeddableFormOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  EmbeddableFormOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  EmbeddableFormOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return EmbeddableFormOrderBy2;
})(EmbeddableFormOrderBy || {});
var EvalReferenceEnum = /* @__PURE__ */ ((EvalReferenceEnum2) => {
  EvalReferenceEnum2["All"] = "ALL";
  EvalReferenceEnum2["Any"] = "ANY";
  return EvalReferenceEnum2;
})(EvalReferenceEnum || {});
var FaqBlockOrderBy = /* @__PURE__ */ ((FaqBlockOrderBy2) => {
  FaqBlockOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  FaqBlockOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  FaqBlockOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  FaqBlockOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return FaqBlockOrderBy2;
})(FaqBlockOrderBy || {});
var FormConfigurationOrderBy = /* @__PURE__ */ ((FormConfigurationOrderBy2) => {
  FormConfigurationOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  FormConfigurationOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  FormConfigurationOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  FormConfigurationOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return FormConfigurationOrderBy2;
})(FormConfigurationOrderBy || {});
var FormsOrderBy = /* @__PURE__ */ ((FormsOrderBy2) => {
  FormsOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  FormsOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  FormsOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  FormsOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return FormsOrderBy2;
})(FormsOrderBy || {});
var Grid2OrderBy = /* @__PURE__ */ ((Grid2OrderBy2) => {
  Grid2OrderBy2["CreatedAtAsc"] = "created_at_ASC";
  Grid2OrderBy2["CreatedAtDesc"] = "created_at_DESC";
  Grid2OrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  Grid2OrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return Grid2OrderBy2;
})(Grid2OrderBy || {});
var HeroBannerOrderBy = /* @__PURE__ */ ((HeroBannerOrderBy2) => {
  HeroBannerOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  HeroBannerOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  HeroBannerOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  HeroBannerOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return HeroBannerOrderBy2;
})(HeroBannerOrderBy || {});
var HeroStackedBannerOrderBy = /* @__PURE__ */ ((HeroStackedBannerOrderBy2) => {
  HeroStackedBannerOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  HeroStackedBannerOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  HeroStackedBannerOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  HeroStackedBannerOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return HeroStackedBannerOrderBy2;
})(HeroStackedBannerOrderBy || {});
var HeroStackedCarouselOrderBy = /* @__PURE__ */ ((HeroStackedCarouselOrderBy2) => {
  HeroStackedCarouselOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  HeroStackedCarouselOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  HeroStackedCarouselOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  HeroStackedCarouselOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return HeroStackedCarouselOrderBy2;
})(HeroStackedCarouselOrderBy || {});
var ImageBlockOrderBy = /* @__PURE__ */ ((ImageBlockOrderBy2) => {
  ImageBlockOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ImageBlockOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ImageBlockOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ImageBlockOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ImageBlockOrderBy2;
})(ImageBlockOrderBy || {});
var ImageDividerOrderBy = /* @__PURE__ */ ((ImageDividerOrderBy2) => {
  ImageDividerOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ImageDividerOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ImageDividerOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ImageDividerOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ImageDividerOrderBy2;
})(ImageDividerOrderBy || {});
var ImageGridOrderBy = /* @__PURE__ */ ((ImageGridOrderBy2) => {
  ImageGridOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ImageGridOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ImageGridOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ImageGridOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ImageGridOrderBy2;
})(ImageGridOrderBy || {});
var ImageTransitionGridOrderBy = /* @__PURE__ */ ((ImageTransitionGridOrderBy2) => {
  ImageTransitionGridOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ImageTransitionGridOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ImageTransitionGridOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ImageTransitionGridOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ImageTransitionGridOrderBy2;
})(ImageTransitionGridOrderBy || {});
var LandingPageOrderBy = /* @__PURE__ */ ((LandingPageOrderBy2) => {
  LandingPageOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  LandingPageOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  LandingPageOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  LandingPageOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return LandingPageOrderBy2;
})(LandingPageOrderBy || {});
var MultiColumnOrderBy = /* @__PURE__ */ ((MultiColumnOrderBy2) => {
  MultiColumnOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  MultiColumnOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  MultiColumnOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  MultiColumnOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return MultiColumnOrderBy2;
})(MultiColumnOrderBy || {});
var NewsItemOrderBy = /* @__PURE__ */ ((NewsItemOrderBy2) => {
  NewsItemOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  NewsItemOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  NewsItemOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  NewsItemOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return NewsItemOrderBy2;
})(NewsItemOrderBy || {});
var PdfOrderBy = /* @__PURE__ */ ((PdfOrderBy2) => {
  PdfOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  PdfOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  PdfOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  PdfOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return PdfOrderBy2;
})(PdfOrderBy || {});
var ProductDetailsGridOrderBy = /* @__PURE__ */ ((ProductDetailsGridOrderBy2) => {
  ProductDetailsGridOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ProductDetailsGridOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ProductDetailsGridOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ProductDetailsGridOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ProductDetailsGridOrderBy2;
})(ProductDetailsGridOrderBy || {});
var ProductcardOrderBy = /* @__PURE__ */ ((ProductcardOrderBy2) => {
  ProductcardOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ProductcardOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ProductcardOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ProductcardOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ProductcardOrderBy2;
})(ProductcardOrderBy || {});
var ProductgridOrderBy = /* @__PURE__ */ ((ProductgridOrderBy2) => {
  ProductgridOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ProductgridOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ProductgridOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ProductgridOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ProductgridOrderBy2;
})(ProductgridOrderBy || {});
var PromobannerOrderBy = /* @__PURE__ */ ((PromobannerOrderBy2) => {
  PromobannerOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  PromobannerOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  PromobannerOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  PromobannerOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return PromobannerOrderBy2;
})(PromobannerOrderBy || {});
var QuestionBlockOrderBy = /* @__PURE__ */ ((QuestionBlockOrderBy2) => {
  QuestionBlockOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  QuestionBlockOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  QuestionBlockOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  QuestionBlockOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return QuestionBlockOrderBy2;
})(QuestionBlockOrderBy || {});
var RedirectSearchOrderBy = /* @__PURE__ */ ((RedirectSearchOrderBy2) => {
  RedirectSearchOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  RedirectSearchOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  RedirectSearchOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  RedirectSearchOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return RedirectSearchOrderBy2;
})(RedirectSearchOrderBy || {});
var ReefPageOrderBy = /* @__PURE__ */ ((ReefPageOrderBy2) => {
  ReefPageOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ReefPageOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ReefPageOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ReefPageOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ReefPageOrderBy2;
})(ReefPageOrderBy || {});
var SecurityRulesOrderBy = /* @__PURE__ */ ((SecurityRulesOrderBy2) => {
  SecurityRulesOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  SecurityRulesOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  SecurityRulesOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  SecurityRulesOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return SecurityRulesOrderBy2;
})(SecurityRulesOrderBy || {});
var SelectPageOrderBy = /* @__PURE__ */ ((SelectPageOrderBy2) => {
  SelectPageOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  SelectPageOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  SelectPageOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  SelectPageOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return SelectPageOrderBy2;
})(SelectPageOrderBy || {});
var ShopMenuOrderBy = /* @__PURE__ */ ((ShopMenuOrderBy2) => {
  ShopMenuOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  ShopMenuOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  ShopMenuOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  ShopMenuOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return ShopMenuOrderBy2;
})(ShopMenuOrderBy || {});
var SmallpromobannerOrderBy = /* @__PURE__ */ ((SmallpromobannerOrderBy2) => {
  SmallpromobannerOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  SmallpromobannerOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  SmallpromobannerOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  SmallpromobannerOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return SmallpromobannerOrderBy2;
})(SmallpromobannerOrderBy || {});
var StateAgeVerificationOrderBy = /* @__PURE__ */ ((StateAgeVerificationOrderBy2) => {
  StateAgeVerificationOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  StateAgeVerificationOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  StateAgeVerificationOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  StateAgeVerificationOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return StateAgeVerificationOrderBy2;
})(StateAgeVerificationOrderBy || {});
var StatesOrderBy = /* @__PURE__ */ ((StatesOrderBy2) => {
  StatesOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  StatesOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  StatesOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  StatesOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return StatesOrderBy2;
})(StatesOrderBy || {});
var StateswapOrderBy = /* @__PURE__ */ ((StateswapOrderBy2) => {
  StateswapOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  StateswapOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  StateswapOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  StateswapOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return StateswapOrderBy2;
})(StateswapOrderBy || {});
var StoreListOrderBy = /* @__PURE__ */ ((StoreListOrderBy2) => {
  StoreListOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  StoreListOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  StoreListOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  StoreListOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return StoreListOrderBy2;
})(StoreListOrderBy || {});
var StorefrontBanner2OrderBy = /* @__PURE__ */ ((StorefrontBanner2OrderBy2) => {
  StorefrontBanner2OrderBy2["CreatedAtAsc"] = "created_at_ASC";
  StorefrontBanner2OrderBy2["CreatedAtDesc"] = "created_at_DESC";
  StorefrontBanner2OrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  StorefrontBanner2OrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return StorefrontBanner2OrderBy2;
})(StorefrontBanner2OrderBy || {});
var StorefrontBannerOrderBy = /* @__PURE__ */ ((StorefrontBannerOrderBy2) => {
  StorefrontBannerOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  StorefrontBannerOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  StorefrontBannerOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  StorefrontBannerOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return StorefrontBannerOrderBy2;
})(StorefrontBannerOrderBy || {});
var StorefrontOrderBy = /* @__PURE__ */ ((StorefrontOrderBy2) => {
  StorefrontOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  StorefrontOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  StorefrontOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  StorefrontOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return StorefrontOrderBy2;
})(StorefrontOrderBy || {});
var SysAssetAutoValues = /* @__PURE__ */ ((SysAssetAutoValues2) => {
  SysAssetAutoValues2["Webp"] = "WEBP";
  return SysAssetAutoValues2;
})(SysAssetAutoValues || {});
var SysAssetDisableValues = /* @__PURE__ */ ((SysAssetDisableValues2) => {
  SysAssetDisableValues2["Upscale"] = "UPSCALE";
  return SysAssetDisableValues2;
})(SysAssetDisableValues || {});
var SysAssetDispositionValues = /* @__PURE__ */ ((SysAssetDispositionValues2) => {
  SysAssetDispositionValues2["Attachment"] = "ATTACHMENT";
  SysAssetDispositionValues2["Inline"] = "INLINE";
  return SysAssetDispositionValues2;
})(SysAssetDispositionValues || {});
var SysAssetFitValues = /* @__PURE__ */ ((SysAssetFitValues2) => {
  SysAssetFitValues2["Bounds"] = "BOUNDS";
  SysAssetFitValues2["Crop"] = "CROP";
  return SysAssetFitValues2;
})(SysAssetFitValues || {});
var SysAssetImageFormats = /* @__PURE__ */ ((SysAssetImageFormats2) => {
  SysAssetImageFormats2["Gif"] = "GIF";
  SysAssetImageFormats2["Jpg"] = "JPG";
  SysAssetImageFormats2["Pjpg"] = "PJPG";
  SysAssetImageFormats2["Png"] = "PNG";
  SysAssetImageFormats2["Webp"] = "WEBP";
  SysAssetImageFormats2["Webpll"] = "WEBPLL";
  SysAssetImageFormats2["Webply"] = "WEBPLY";
  return SysAssetImageFormats2;
})(SysAssetImageFormats || {});
var SysAssetOrderBy = /* @__PURE__ */ ((SysAssetOrderBy2) => {
  SysAssetOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  SysAssetOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  SysAssetOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  SysAssetOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return SysAssetOrderBy2;
})(SysAssetOrderBy || {});
var SysAssetOrientValues = /* @__PURE__ */ ((SysAssetOrientValues2) => {
  SysAssetOrientValues2["Both"] = "BOTH";
  SysAssetOrientValues2["Default"] = "DEFAULT";
  SysAssetOrientValues2["Horizontally"] = "HORIZONTALLY";
  SysAssetOrientValues2["Rotate90Left"] = "ROTATE90LEFT";
  SysAssetOrientValues2["Rotate90Right"] = "ROTATE90RIGHT";
  SysAssetOrientValues2["Vertically"] = "VERTICALLY";
  return SysAssetOrientValues2;
})(SysAssetOrientValues || {});
var SysAssetOverlayAlignValues = /* @__PURE__ */ ((SysAssetOverlayAlignValues2) => {
  SysAssetOverlayAlignValues2["Bottom"] = "BOTTOM";
  SysAssetOverlayAlignValues2["Center"] = "CENTER";
  SysAssetOverlayAlignValues2["Left"] = "LEFT";
  SysAssetOverlayAlignValues2["Middle"] = "MIDDLE";
  SysAssetOverlayAlignValues2["Right"] = "RIGHT";
  SysAssetOverlayAlignValues2["Top"] = "TOP";
  return SysAssetOverlayAlignValues2;
})(SysAssetOverlayAlignValues || {});
var SysAssetOverlayRepeatValues = /* @__PURE__ */ ((SysAssetOverlayRepeatValues2) => {
  SysAssetOverlayRepeatValues2["Both"] = "BOTH";
  SysAssetOverlayRepeatValues2["X"] = "X";
  SysAssetOverlayRepeatValues2["Y"] = "Y";
  return SysAssetOverlayRepeatValues2;
})(SysAssetOverlayRepeatValues || {});
var TestGroupingOrderBy = /* @__PURE__ */ ((TestGroupingOrderBy2) => {
  TestGroupingOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  TestGroupingOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  TestGroupingOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  TestGroupingOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return TestGroupingOrderBy2;
})(TestGroupingOrderBy || {});
var TextblockOrderBy = /* @__PURE__ */ ((TextblockOrderBy2) => {
  TextblockOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  TextblockOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  TextblockOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  TextblockOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return TextblockOrderBy2;
})(TextblockOrderBy || {});
var TextimageblockOrderBy = /* @__PURE__ */ ((TextimageblockOrderBy2) => {
  TextimageblockOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  TextimageblockOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  TextimageblockOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  TextimageblockOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return TextimageblockOrderBy2;
})(TextimageblockOrderBy || {});
var TriTextImageBlockOrderBy = /* @__PURE__ */ ((TriTextImageBlockOrderBy2) => {
  TriTextImageBlockOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  TriTextImageBlockOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  TriTextImageBlockOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  TriTextImageBlockOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return TriTextImageBlockOrderBy2;
})(TriTextImageBlockOrderBy || {});
var UberheroOrderBy = /* @__PURE__ */ ((UberheroOrderBy2) => {
  UberheroOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  UberheroOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  UberheroOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  UberheroOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return UberheroOrderBy2;
})(UberheroOrderBy || {});
var VideoBlockOrderBy = /* @__PURE__ */ ((VideoBlockOrderBy2) => {
  VideoBlockOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  VideoBlockOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  VideoBlockOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  VideoBlockOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return VideoBlockOrderBy2;
})(VideoBlockOrderBy || {});
var WebsiteOrderBy = /* @__PURE__ */ ((WebsiteOrderBy2) => {
  WebsiteOrderBy2["CreatedAtAsc"] = "created_at_ASC";
  WebsiteOrderBy2["CreatedAtDesc"] = "created_at_DESC";
  WebsiteOrderBy2["UpdatedAtAsc"] = "updated_at_ASC";
  WebsiteOrderBy2["UpdatedAtDesc"] = "updated_at_DESC";
  return WebsiteOrderBy2;
})(WebsiteOrderBy || {});

// src/auth/srp/srp.ts
import crypto from "crypto";
import { BigInteger } from "jsbn";
import CryptoJS from "crypto-js";
var default_params = {
  N_length_bits: 4096,
  N_str: `
FFFFFFFF FFFFFFFF C90FDAA2 2168C234 C4C6628B 80DC1CD1 29024E08
8A67CC74 020BBEA6 3B139B22 514A0879 8E3404DD EF9519B3 CD3A431B
302B0A6D F25F1437 4FE1356D 6D51C245 E485B576 625E7EC6 F44C42E9
A637ED6B 0BFF5CB6 F406B7ED EE386BFB 5A899FA5 AE9F2411 7C4B1FE6
49286651 ECE45B3D C2007CB8 A163BF05 98DA4836 1C55D39A 69163FA8
FD24CF5F 83655D23 DCA3AD96 1C62F356 208552BB 9ED52907 7096966D
670C354E 4ABC9804 F1746C08 CA18217C 32905E46 2E36CE3B E39E772C
180E8603 9B2783A2 EC07A28F B5C55DF0 6F4C52C9 DE2BCBF6 95581718
3995497C EA956AE5 15D22618 98FA0510 15728E5A 8AAAC42D AD33170D
04507A33 A85521AB DF1CBA64 ECFB8504 58DBEF0A 8AEA7157 5D060C7D
B3970F85 A6E1E4C7 ABF5AE8C DB0933D7 1E8C94E0 4A25619D CEE3D226
1AD2EE6B F12FFA06 D98A0864 D8760273 3EC86A64 521F2B18 177B200C
BBE11757 7A615D6C 770988C0 BAD946E2 08E24FA0 74E5AB31 43DB5BFC
E0FD108E 4B82D120 A9210801 1A723C12 A787E6D7 88719A10 BDBA5B26
99C32718 6AF4E23C 1A946834 B6150BDA 2583E9CA 2AD44CE8 DBBBC2DB
04DE8EF9 2E8EFC14 1FBECAA6 287C5947 4E6BC05D 99B2964F A090C3A2
233BA186 515BE7ED 1F612970 CEE2D7AF B81BDD76 2170481C D0069127
D5B05AA9 93B4EA98 8D8FDDC1 86FFB7DC 90A6C08F 4DF435C9 34063199
FFFFFFFF FFFFFFFF
`,
  g_str: "05",
  hash_alg: "sha256"
};
var SRP = class {
  constructor(n = default_params.N_str, n_length = default_params.N_length_bits, g = default_params.g_str, alg = default_params.hash_alg) {
    this.hexToBigInt = (s) => {
      const buf = Buffer.from(s.split(/\s|\n/).join(""), "hex");
      return new BigInteger(buf.toString("hex"), 16);
    };
    this.gen_key = async (length = 32) => {
      return await crypto.randomBytes(length);
    };
    this.browser_key = async (length = 32) => {
      const key = await this.gen_key(length);
      return key.toString("hex");
    };
    this.calc_A = (a) => {
      const A_bn = this.g.modPow(a, this.n);
      return this.bnToBuffer(A_bn, true);
    };
    this.calc_x = (salt, I, P) => {
      const hashIP = crypto.createHash(this.alg).update(Buffer.concat([I, Buffer.from(":"), P])).digest();
      const hashX = crypto.createHash(this.alg).update(salt).update(hashIP).digest();
      return new BigInteger(hashX.toString("hex"), 16);
    };
    this.browser_x = (s, I, P) => {
      const SHA = CryptoJS[this.alg.toUpperCase()];
      const IP = `${I}:${P}`;
      const IPHash = SHA(IP);
      const salt = CryptoJS.enc.Hex.parse(s);
      const M = salt.concat(IPHash);
      const x = SHA(M);
      return new BigInteger(x.toString(CryptoJS.enc.Hex), 16);
    };
    this.calc_v = (salt, I, P) => {
      const x = this.calc_x(salt, I, P);
      const v_num = this.g.modPow(x, this.n);
      return v_num.toString(16);
    };
    this.browser_v = (salt, I, P) => {
      const x = this.browser_x(salt, I.toLowerCase(), P);
      const v_num = this.g.modPow(x, this.n);
      return v_num.toString(16);
    };
    this.calc_B = (k, v, b) => {
      const r = k.multiply(v).add(this.g.modPow(b, this.n)).mod(this.n);
      return this.bnToBuffer(r, true);
    };
    this.calc_u = (A, B) => {
      const u_buf = crypto.createHash(this.alg).update(this.padTo(A)).update(this.padTo(B)).digest();
      return new BigInteger(u_buf.toString("hex"), 16);
    };
    this.browser_u = (A, B) => {
      const SHA = CryptoJS[this.alg.toUpperCase()];
      return SHA(CryptoJS.enc.Hex.parse(A + B)).toString(CryptoJS.enc.Hex);
    };
    this.calc_k = () => {
      const k_buf = crypto.createHash(this.alg).update(this.padToN(this.n)).update(this.padToN(this.g)).digest();
      return new BigInteger(k_buf.toString("hex"), 16);
    };
    this.browser_k = () => {
      const SHA = CryptoJS[this.alg.toUpperCase()];
      const n = this.padToN(this.n).toString("hex");
      const g = this.padToN(this.g).toString("hex");
      const hashNG = SHA(CryptoJS.enc.Hex.parse(n + g)).toString(
        CryptoJS.enc.Hex
      );
      return new BigInteger(hashNG, 16);
    };
    this.calc_server_S = (v, A, b, u) => {
      const S_num = A.multiply(v.modPow(u, this.n)).modPow(b, this.n).mod(this.n);
      return this.bnToBuffer(S_num, true);
    };
    this.calc_client_S = (k, x, a, B, u) => {
      const S_num = B.subtract(k.multiply(this.g.modPow(x, this.n))).modPow(a.add(u.multiply(x)), this.n).mod(this.n);
      return this.bnToBuffer(S_num, true);
    };
    this.hashBuffer = (buf) => {
      const SHA = CryptoJS[this.alg.toUpperCase()];
      const hash1 = SHA(buf).concat(CryptoJS.lib.WordArray.create([0, 0, 0, 0])).toString(CryptoJS.enc.Hex);
      const hash2 = SHA(buf).concat(CryptoJS.lib.WordArray.create([0, 0, 0, 1])).toString(CryptoJS.enc.Hex);
      return Buffer.concat([
        Buffer.from(hash1, "hex"),
        Buffer.from(hash2, "hex")
      ]);
    };
    this.calc_M1 = (A, B, S) => {
      return crypto.createHash(this.alg).update(A).update(B).update(S).digest();
    };
    this.browser_M1 = (A, B, S) => {
      const SHA = CryptoJS[this.alg.toUpperCase()];
      const hash = SHA(
        CryptoJS.enc.Hex.parse(A).concat(CryptoJS.enc.Hex.parse(B)).concat(CryptoJS.enc.Hex.parse(S))
      );
      return hash.toString(CryptoJS.enc.Hex);
    };
    this.calc_server_K = (v, A, b, u) => {
      const S = this.calc_server_S(v, A, b, u);
      return {
        S,
        K: this.hashBuffer(S)
      };
    };
    this.calc_client_K = (k, x, a, B, u) => {
      const S = this.calc_client_S(k, x, a, B, u);
      return {
        S,
        K: this.hashBuffer(S)
      };
    };
    this.calc_browser_K = (k, x, a, B, u) => {
      const S = this.calc_client_S(k, x, a, B, u);
      return {
        S,
        K: this.hashBuffer(S).toString("hex")
      };
    };
    this.client_register = async (i, p) => {
      const salt = await this.browser_key();
      const verifier = await this.browser_v(salt, i, p);
      return {
        salt,
        verifier
      };
    };
    this.client_login_A = async () => {
      const a = await this.browser_key();
      return {
        a,
        A: this.calc_A(this.hexToBigInt(a))
      };
    };
    this.client_login_session = async (A, B, s, I, P, a) => {
      const u = this.browser_u(A, B);
      const x = this.browser_x(s, I, P);
      const k = this.browser_k();
      const K = this.calc_browser_K(
        k,
        x,
        this.hexToBigInt(a),
        this.hexToBigInt(B),
        this.hexToBigInt(u)
      );
      const M1 = this.browser_M1(A, B, K.S.toString("hex"));
      return {
        K: K.K,
        S: K.S,
        M1
      };
    };
    this.n = this.hexToBigInt(n);
    this.g = this.hexToBigInt(g);
    this.n_length = n_length;
    this.alg = alg;
  }
  bnToBuffer(bigNumber, trimOrSize) {
    let bnByteArray = Buffer.from(bigNumber.toByteArray());
    if (trimOrSize === true && bnByteArray[0] === 0) {
      bnByteArray = bnByteArray.slice(1);
    } else if (typeof trimOrSize == "number") {
      if (bnByteArray.length > trimOrSize) {
        for (let i = 0; i < bnByteArray.length - trimOrSize; i++) {
          if (bnByteArray[i] !== 0) {
            throw "idk";
          }
        }
        return bnByteArray.slice(bnByteArray.length - trimOrSize);
      } else if (bnByteArray.length < trimOrSize) {
        const padded = Buffer.alloc(trimOrSize);
        padded.fill(0, 0, trimOrSize - bnByteArray.length);
        bnByteArray.copy(padded, trimOrSize - bnByteArray.length);
        return padded;
      }
    }
    return bnByteArray;
  }
  calc_s(length = 32) {
    return this.gen_key(length);
  }
  calc_a(length = 32) {
    return this.gen_key(length);
  }
  calc_b(length = 32) {
    return this.gen_key(length);
  }
  padTo(n) {
    const padding = this.n_length / 8 - n.length;
    const result = Buffer.alloc(this.n_length / 8);
    result.fill(0, 0, padding);
    n.copy(result, padding);
    return result;
  }
  padToN(number) {
    const n = number.toString(16).length % 2 !== 0 ? "0" + number.toString(16) : number.toString(16);
    return this.padTo(Buffer.from(n, "hex"));
  }
};

// src/auth/srp/websiteFunctions.ts
import axios16 from "axios";
import Cookies from "js-cookie";
var srp = new SRP();
var forgotPassword = async ({
  email,
  setError
}) => {
  let success = false;
  const forgotInit = await axios16({
    method: "POST",
    data: {
      email: email.toLowerCase(),
      path: window.location.origin + window.location.pathname,
      source: "web"
    },
    url: `${process.env.AUTH_URL}/api/auth/v1/forgotPassword`,
    validateStatus: () => true
  });
  switch (forgotInit.status) {
    case 200: {
      success = true;
      break;
    }
    case 400: {
      setError({
        text: "We're sorry. We weren't able to identify you given the email provided."
      });
      break;
    }
    case 405: {
      setError({
        text: forgotInit.data,
        link: {
          text: "Resend Verification Email",
          callback: async () => {
            return await axios16.post(
              `${process.env.AUTH_URL}/api/auth/v1/resendVerifyEmail`,
              {
                email: email.toLowerCase(),
                path: window.location.origin + window.location.pathname,
                source: "web"
              }
            );
          }
        }
      });
      break;
    }
    case 500: {
      setError({
        text: "Failed to send reset password link."
      });
      break;
    }
  }
  return success;
};
var loginUser = async ({
  email,
  password,
  contextSetter,
  setError,
  setVisibility,
  setMode
}) => {
  var _a, _b, _c;
  let success = false;
  const login = await srp.client_login_A();
  const loginInit = await axios16.post(
    `${process.env.AUTH_URL}/api/auth/v1/login-init`,
    {
      A: login.A.toString("hex"),
      email: email.toLowerCase()
    },
    {
      validateStatus: () => true
    }
  );
  switch (loginInit.status) {
    case 401: {
      if (loginInit.data.message) {
        if (loginInit.data.message.includes("locked")) {
          setError({
            text: loginInit.data.message,
            link: {
              text: "Reset Password",
              callback: () => setMode("forgot")
            }
          });
        } else {
          setError({
            text: loginInit.data.message,
            link: {
              text: "Resend Verification Email",
              callback: async () => {
                return await axios16.post(
                  `${process.env.AUTH_URL}/api/auth/v1/resendVerifyEmail`,
                  {
                    email: email.toLowerCase(),
                    path: window.location.origin + window.location.pathname,
                    source: "web"
                  }
                );
              }
            }
          });
        }
      }
      break;
    }
    case 400: {
      if (loginInit.data.message) {
        setError({
          text: loginInit.data.message
        });
      }
      break;
    }
  }
  if (loginInit.status !== 200) {
    return false;
  }
  const session = await srp.client_login_session(
    login.A.toString("hex"),
    loginInit.data.B,
    loginInit.data.s,
    email.toLowerCase(),
    password,
    login.a
  );
  const sessionInit = await axios16.post(
    `${process.env.AUTH_URL}/api/auth/v1/login`,
    {
      M1: session.M1,
      email: email.toLowerCase()
    },
    {
      withCredentials: true,
      validateStatus: () => true
    }
  );
  switch (sessionInit.status) {
    case 200: {
      if ((_a = sessionInit == null ? void 0 : sessionInit.data) == null ? void 0 : _a.message) {
        success = true;
        Cookies.set(
          "token",
          sessionInit.data.message,
          process.env.COOKIE_DOMAIN ? {
            domain: process.env.COOKIE_DOMAIN
          } : void 0
        );
        const user = await axios16.get(
          `${process.env.AUTH_URL}/api/auth/v1/user`,
          {
            withCredentials: true
          }
        );
        if (user) {
          success = `User ${user.data._id} logged in.`;
          const localCart = Cookies.get(CART_META_KEY);
          if (localCart && JSON.parse(localCart).items && ((_c = (_b = user.data.cart) == null ? void 0 : _b.items) == null ? void 0 : _c.length) && parseInt(JSON.parse(localCart).updatedAt) < parseInt(user.data.cart.updatedAt) || user.data.cart && (!localCart || !JSON.parse(localCart).items)) {
            Cookies.set(
              CART_META_KEY,
              JSON.stringify({
                checkoutId: user.data.cart.id,
                dispensaryUniqueId: user.data.cart.dispensaryUniqueId,
                menuType: user.data.cart.pricingType,
                redirectUrl: user.data.cart.redirectUrl,
                updatedAt: user.data.cart.updatedAt
              })
            );
          }
          const optInBoolean = (optInString) => {
            if (optInString == "subscribed" || optInString == "unsubscribed") {
              return false;
            } else if (optInString == "unsubscribed") {
              return true;
            }
          };
          contextSetter({
            _id: user.data._id,
            email: user.data.email,
            firstName: user.data.firstName,
            lastName: user.data.lastName,
            phone: user.data.phone,
            birthday: user.data.birthday,
            state: user.data.state,
            optIn: user.data.mainSubscriptionStatus ? optInBoolean(user.data.mainSubscriptionStatus) : user.data.marketingOptIn
          });
        }
        setVisibility(false);
        break;
      }
    }
    case 400: {
      if (sessionInit.data.message)
        setError({
          text: sessionInit.data.message
        });
      break;
    }
    case 401: {
      if (sessionInit.data.message) {
        setError({
          text: sessionInit.data.message
        });
      }
      setError({
        text: "You entered an invalid email or password."
      });
      break;
    }
  }
  return success;
};
var resetPassword = async ({
  email,
  password,
  token,
  setError
}) => {
  let success = false;
  const registration = await srp.client_register(email.toLowerCase(), password);
  const setPassReq = await axios16.post(
    `${process.env.AUTH_URL}/api/auth/v1/resetPassword`,
    {
      email: email.toLowerCase(),
      v: registration.verifier,
      s: registration.salt,
      token
    },
    {
      validateStatus: () => true
    }
  );
  switch (setPassReq.status) {
    case 200: {
      success = true;
      break;
    }
    case 500:
    case 400: {
      setError({ text: setPassReq.data });
      break;
    }
  }
  return success;
};
var registerUser = async ({
  email,
  password,
  firstName,
  lastName,
  phone,
  birthday,
  state,
  optIn,
  referralToken,
  setError
}) => {
  const birthdate = new Date(birthday);
  let success = false;
  const registration = await srp.client_register(email.toLowerCase(), password);
  const registerReq = await axios16.post(
    `${process.env.AUTH_URL}/api/auth/v1/register`,
    {
      email: email.toLowerCase(),
      firstName,
      lastName,
      birthday: birthdate,
      state,
      marketingOptIn: optIn,
      phone: phone.replace(/\D/g, ""),
      v: registration.verifier,
      s: registration.salt,
      source: "web",
      path: window.location.origin + window.location.pathname,
      referralToken
    },
    {
      validateStatus: () => true
    }
  );
  switch (registerReq.status) {
    case 201: {
      success = registerReq.data;
      break;
    }
    case 400: {
      setError({ text: registerReq.data });
      break;
    }
    default: {
      setError({
        text: "Oops! Sorry about that, looks like there was an error. Please try signing up later."
      });
    }
  }
  return success;
};
var logout = async (contextSetter) => {
  await axios16.post(
    `${process.env.AUTH_URL}/api/auth/v1/logout`,
    {},
    {
      withCredentials: true
    }
  ).then(() => {
    Cookies.remove(CART_META_KEY);
    contextSetter({
      _id: "",
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      birthday: "",
      state: "",
      optIn: false
    });
  });
};
export {
  ACTIVE_REFERRALS,
  AbandonedCartConfigsOrderBy,
  AccountLoyaltyPreferencesOrderBy,
  AllGrassrootsPageSlugsQuery,
  Analytics,
  AppContentOrderBy,
  ArticleGridOrderBy,
  ArticleOrderBy,
  B2cStrapiService,
  Braze,
  BrazeApi,
  CommunityNewsItemOrderBy,
  ContactFormsOrderBy,
  ContentStackService,
  CosmosDB,
  CrmCdpOrderBy,
  curaql_exports as Curaql,
  CustomerIntegration,
  DecisionTreeAnswerOrderBy,
  DecisionTreeQuestionsOrderBy,
  DispensaryConfigOrderBy,
  DispensarysyncOrderBy,
  DutchiePlusPreferencesOrderBy,
  DutchiePlusService,
  EcommerceConfigOrderBy,
  EcommerceMenuOrderBy,
  EcommerceTextBannerOrderBy,
  EmbeddableFormOrderBy,
  EvalReferenceEnum,
  FaqBlockOrderBy,
  FormConfigurationOrderBy,
  FormsOrderBy,
  GrassrootsPageComponentsTypeNameQuery,
  GrassrootsSeoQuery,
  Grid2OrderBy,
  HeroBannerOrderBy,
  HeroStackedBannerOrderBy,
  HeroStackedCarouselOrderBy,
  ImageBlockOrderBy,
  ImageDividerOrderBy,
  ImageGridOrderBy,
  ImageTransitionGridOrderBy,
  LandingPageOrderBy,
  LeaflogixApi,
  monetate_exports as Monetate,
  MultiColumnOrderBy,
  NewsItemOrderBy,
  PdfOrderBy,
  ProductDetailsGridOrderBy,
  ProductcardOrderBy,
  ProductgridOrderBy,
  PromobannerOrderBy,
  QuestionBlockOrderBy,
  REFERRAL_URL,
  RedirectSearchOrderBy,
  ReefPageOrderBy,
  SRP,
  SUBMIT_REFERRAL,
  SecurityRulesOrderBy,
  SelectPageOrderBy,
  ShopMenuOrderBy,
  SmallpromobannerOrderBy,
  SpringbigApi,
  StateAgeVerificationOrderBy,
  StatesOrderBy,
  StateswapOrderBy,
  StoreListOrderBy,
  StorefrontBanner2OrderBy,
  StorefrontBannerOrderBy,
  StorefrontOrderBy,
  SubmitFormToAPI,
  SubmitHipaaFormToAPI,
  SubmitQuestionToAPI,
  SysAssetAutoValues,
  SysAssetDisableValues,
  SysAssetDispositionValues,
  SysAssetFitValues,
  SysAssetImageFormats,
  SysAssetOrderBy,
  SysAssetOrientValues,
  SysAssetOverlayAlignValues,
  SysAssetOverlayRepeatValues,
  TestGroupingOrderBy,
  TextblockOrderBy,
  TextimageblockOrderBy,
  TriTextImageBlockOrderBy,
  UberheroOrderBy,
  VideoBlockOrderBy,
  WebsiteOrderBy,
  WebsiteQuery,
  alphanumericSlugify,
  buildDynamicGrassrootsPageQuery,
  calculateDecisionTree,
  capitalize,
  categoryFilterMap,
  deslugify,
  dutchieFriendlyName,
  forgotPassword,
  formStateDispensaryMap,
  formStatesNDispensariesMap,
  getCSEntries,
  getCSEntry,
  getFloridaPhysicians,
  getSlug,
  getStoreTimes,
  hipaaFormSubmit,
  hipaaPasswordCheck,
  hipaaStatusCheck,
  isFakeNumber,
  loginUser,
  logout,
  mapDispensaryToStoreType,
  mapStoresTagsFor,
  registerUser,
  resetPassword,
  searchCoa,
  seoMap,
  slugify,
  sortMap,
  storeOfferMap,
  submitForm,
  submitQuestion,
  useAnalytics,
  useStorage,
  useWindowDimensions
};
