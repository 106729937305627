import { CS_Website } from 'services';
import websiteData from './website.json';
import headerData from './header.json';
import footerData from './footer.json';
import wideBannersData from './wideBanners.json';
import {
  AgeVerificationProps,
  FooterProps,
  HeaderNavigationProps,
  WideBannerProps
} from 'ui';

const website = websiteData as unknown as CS_Website;
const header = headerData as unknown as HeaderNavigationProps;
const footer = footerData as unknown as FooterProps;
const wideBanners = wideBannersData as unknown as WideBannerProps;

import ageVerificationImport from './age-verification.json';
const ageVerification =
  ageVerificationImport as unknown as AgeVerificationProps;

export { ageVerification, footer, header, website, wideBanners };
