import { Spinner } from 'react-bootstrap';

import styles from './loader.module.scss';

const Loader = () => (
  <div className={styles.Loader}>
    <Spinner
      animation="border"
      variant="dark"
      size="sm"
      className={styles.spinner}
    />
    <span>Loading...</span>
  </div>
);

export default Loader;
