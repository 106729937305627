import { StrictMode, useEffect, useState } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import TagManager from 'react-gtm-module';
import { SSRProvider } from 'react-bootstrap';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import { AuthContext, loadCuraleafUIConfiguration } from 'ui';

import Layout from '../components/layout/layout';
import Loader from '../components/loader/loader';

import(`ui/dist/${process.env.BRAND}.css`);
import '../styles/global.scss';

import { datadogRum } from '@datadog/browser-rum';

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.NEXT_PUBLIC_DD_SAMPLE_RATE &&
  process.env.NEXT_PUBLIC_DD_APPLICATION_ID &&
  process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN &&
  process.env.NEXT_PUBLIC_DD_SERVICE_NAME
) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: process.env.NEXT_PUBLIC_DD_SERVICE_NAME,
    env: process.env.NEXT_PUBLIC_DD_ENV || 'development',
    version: process.env.VERCEL_GIT_COMMIT_SHA || undefined,
    sampleRate: +process.env.NEXT_PUBLIC_DD_SAMPLE_RATE || 0,
    sessionReplaySampleRate:
      +process.env.NEXT_PUBLIC_DD_REPLAY_SAMPLE_RATE || 0,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
  datadogRum.startSessionReplayRecording();
}

export const client = new ApolloClient({
  uri: process.env.CURAQL_BASEURL,
  cache: new InMemoryCache()
});

export const preview_client = new ApolloClient({
  uri: process.env.CURAQL_PREVIEW_BASEURL,
  cache: new InMemoryCache()
});

loadCuraleafUIConfiguration({
  imageRenderer: Image
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const [initialRenderComplete, setInitialRenderComplete] =
    useState<boolean>(false);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  useEffect(() => {
    if (
      !process.env.NEXT_PUBLIC_HIDE_ANALYTICS &&
      process.env.NEXT_PUBLIC_GTM_ID
    ) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID
      });
    }
  }, [router.events]);

  if (router.isFallback) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (!initialRenderComplete) return <></>;

  return (
    <SSRProvider>
      <ApolloProvider client={router.isPreview ? preview_client : client}>
        <Head>
          {/* Blueconic Tracking Script */}
          {!process.env.NEXT_PUBLIC_HIDE_ANALYTICS ? (
            <script
              async
              src="https://e976.curaleaf.com/script.js"
              type="text/javascript"
            />
          ) : null}
          {/* FAVICON */}
          <link
            rel="shortcut icon"
            href={
              process.env.NEXT_PUBLIC_FAVICON_URL
                ? process.env.NEXT_PUBLIC_FAVICON_URL
                : 'https://images.contentstack.io/v3/assets/blt1244f6272732d4ea/blt59ffbc2a4050cc54/curaleaf.ico'
            }
          />
          {process.env.NEXT_PUBLIC_MONETATE_TAG ? (
            <>
              {/* <!-- Begin Monetate ExpressTag Sync v8.1. Place at start of document head. DO NOT ALTER. --> */}
              <script type="text/javascript">
                var monetateT = new Date().getTime();
              </script>
              <script
                type="text/javascript"
                src={process.env.NEXT_PUBLIC_MONETATE_TAG}></script>
              {/* <!-- End Monetate tag. --> */}
            </>
          ) : null}
          {/* Lets us see the latest commit in the HEAD of the website, validating the version of the code */}
          <meta
            name="release"
            content={process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <StrictMode>
          <AuthContext>
            <Layout>
              {router.isPreview ? (
                <div className="PreviewButton">
                  <button
                    onClick={() => router.push('/api/disable-preview')}
                    style={{
                      color: 'white',
                      width: '100%',
                      padding: 10,
                      backgroundColor: '#C5003E'
                    }}>
                    Disable Preview
                  </button>
                </div>
              ) : null}
              <Component {...pageProps} />
            </Layout>
          </AuthContext>
        </StrictMode>
      </ApolloProvider>
    </SSRProvider>
  );
}

export default MyApp;
