import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { useRouter } from 'next/router';

import {
  ageVerification,
  header,
  footer,
  website,
  wideBanners
} from '../../staticData/staticData';
import { useStorage } from 'services';
import {
  AgeVerification,
  Alert,
  FooterNavigation,
  friendlyReminderMap,
  HeaderNavigation,
  WideBanner,
  useDispensary
} from 'ui';

import styles from './layout.module.scss';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

function Layout({ children }: Props) {
  const router = useRouter();
  // Need double booleans to prevent hydration issue
  const [ageVerificationOpen, toggleAgeVerification] = useState(false);
  const [ageConfirm, setAgeConfirm] = useStorage('confirmed21OrOlder', '0');
  const [friendlyReminderOpen, toggleFriendlyReminder] = useState(false);
  const [friendlyReminder, setFriendlyReminder] = useStorage(
    'friendlyReminder',
    '0'
  );
  const [wideBanner, setWideBanner] = useStorage('wideBanner', '');
  const [wideBannerOpen, setWideBannerOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const { selectedDispensary, shopLink } = useDispensary();

  const onConfirmAge = () => {
    setAgeConfirm('1');
    toggleAgeVerification(false);
  };

  const onConfirmReminder = () => {
    setFriendlyReminder('1');
    toggleFriendlyReminder(false);
  };

  const onCloseWideBanner = () => {
    setWideBanner('0');
    setWideBannerOpen(false);
  };

  useEffect(() => {
    if (
      ageConfirm !== '1' &&
      ageVerification?.titles &&
      ageVerification.titles[0]
    ) {
      toggleAgeVerification(true);
    } else {
      if (friendlyReminder !== '1' && website.friendly_reminder?.title) {
        toggleFriendlyReminder(true);
      }
    }
    if (wideBanner !== '0' && wideBanners?.widebanner?.length) {
      setWideBanner('1');
      setWideBannerOpen(true);
    }
  }, [ageConfirm, friendlyReminder]);

  let headerProps = {
    ...header,
    setNavOpen,
    isDutchiePlus: !!(
      selectedDispensary &&
      selectedDispensary.storefront.length &&
      selectedDispensary.storefront[0].__typename ===
        'DispensaryConfigStorefrontDutchiePlus'
    ),
    socialMediaLinks:
      process.env.BRAND === 'jams' ? footer.socialMediaLinks : undefined,
    legalLinks: process.env.BRAND === 'jams' ? footer.legalLinks : undefined
  };
  if (process.env.BRAND === 'curaleaf') {
    headerProps = {
      ...headerProps,
      selectStateSection: {
        onStateSelection: () => router.push('/#locations'),
        storeSelection: selectedDispensary?.friendly_name,
        title: 'Find A Dispensary',
        url: shopLink
      }
    };
  }

  return (
    <SnackbarProvider maxSnack={3}>
      <div
        className={
          ageVerificationOpen || navOpen ? styles.Modal : styles.Layout
        }>
        {ageVerificationOpen ? (
          <AgeVerification {...ageVerification} submit={() => onConfirmAge()} />
        ) : null}
        {friendlyReminderOpen ? (
          <Alert
            {...friendlyReminderMap(
              website.friendly_reminder,
              process.env.BRAND,
              onConfirmReminder
            )}
          />
        ) : null}
        {wideBannerOpen && wideBanners ? (
          <WideBanner {...wideBanners} onClose={onCloseWideBanner} />
        ) : null}
        <HeaderNavigation {...headerProps} />
        <main>
          <div className={`${styles.content} ${styles[process.env.BRAND]}`}>
            {children}
          </div>
        </main>
        <FooterNavigation {...footer} />
      </div>
    </SnackbarProvider>
  );
}

export default Layout;
